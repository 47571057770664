const VALUE_LENGTH = 8;

export function PriceInput({
  onChange,
  value,
  className,
  isPercentage,
  showPlus,
  placeholder = "$0",
  valueLength = VALUE_LENGTH,
  readOnly = false,
  disabled = false,
}: {
  onChange: (e: string) => void;
  value: string;
  className?: string;
  isPercentage?: boolean;
  showPlus?: boolean;
  placeholder?: string;
  valueLength?: number;
  readOnly?: boolean;
  disabled?: boolean;
}) {
  const delimiter = isPercentage ? (showPlus ? "+" : "") : "$";
  // attach $ to the value as first char
  // value = `$${value}`;
  // in on change, remove $ from the value and then do number validation
  // In short, input will be number & output will be number
  // internally it will attach $ in starting while showing input

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // in on change, remove $ from the value and then do number validation
    if (delimiter && value.startsWith(delimiter)) {
      value = value.slice(1);
    }
    if (value.endsWith("%")) {
      value = value.slice(0, -1);
    }
    if (!isNaN(Number(value))) {
      onChange(value);
    } else {
      e.preventDefault();
    }
  };
  const val =
    value && value?.length <= valueLength
      ? value === "0"
        ? "-"
        : `${delimiter}${value}`
      : value;
  return (
    <div
      className={`flex w-[90px] relative rounded px-3 ${
        disabled ? "!bg-neutral-400 !text-neutral-600" : ""
      } ${
        isPercentage ? "pr-4" : ""
      } py-1.5 text-2xs font-medium border-neutral-400 border  items-center ${className}`}
    >
      {value?.length > valueLength && !isPercentage && (
        <span className=" absolute left-1 font-medium text-2xs">$</span>
      )}
      {value?.length > valueLength && showPlus && (
        <span className=" absolute left-1 font-medium text-2xs">+</span>
      )}
      <input
        type="text"
        value={val || ""}
        onChange={handleChange}
        className={`w-full text-center outline-none `}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
      />
      {isPercentage && value && (
        <span
          style={{
            right: `${
              30 - ((value?.length || 0) * 3.5 < 21 ? value?.length * 3.5 : 21)
            }px`,
          }}
          className=" absolute font-medium text-2xs"
        >
          %
        </span>
      )}
    </div>
  );
}
