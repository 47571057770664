import { useApp } from "../helpers/app-context";
import { Sidebar } from "./Sidebar";
import { SideModal } from "./SideModal";

export function MobileSidebar() {
  const {
    isMobileSidebarOpen,
    setIsMobileSidebarOpen,
    sidebarExpanded,
    setSidebarExpanded,
  } = useApp() as {
    isMobileSidebarOpen: boolean;
    setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarExpanded: boolean;
    setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  };
  const handleClose = () => {
    setIsMobileSidebarOpen(false);
  };
  return (
    <SideModal
      isRight
      className="!z-[9999] !justify-start"
      overlayClassName="!z-[9999]"
      onClose={handleClose}
    >
      <div className="h-full w-[327px] relative bg-white flex flex-col">
        <Sidebar
          sidebarExpanded={true}
          setSidebarExpanded={() => {}}
          isMobile
          onClose={handleClose}
        />
      </div>
    </SideModal>
  );
}
