import Button from "../../../components/Button";
import { Card } from "../../../components/Card";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";

export function Welcome({
  onNext,
  step,
}: {
  onNext: () => void;
  step: number;
}) {
  return (
    <>
      <OnboardingSidebar title="Welcome">
        <p className="text-xs leading-[16.8px] text-black">
          You'll be live in no time, after a few quick and easy steps.
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Welcome"
        infoView={
          <p className="text-xs leading-[16.8px] text-black">
            You'll be live in no time, after a few quick and easy steps.
          </p>
        }
      >
        <Card className="lg:max-w-[670px]">
          <h2 className="text-2xl font-semibold">Welcome to autumn</h2>
          <div className="mt-5 text-sm text-neutral-800 flex flex-col gap-2.5">
            <p>
              We've built Autumn to simplify revenue management and marketing,
              so you can focus on what you love—serving guests or enjoying the
              changing seasons.
            </p>
            <p>
              Our quick onboarding process gathers key historical data and your
              insights to create a tailored revenue strategy for your hotel.
              Don't worry—you can adjust your answers anytime.
            </p>
            <p>
              Your responses will help us set up and optimize your hotel's
              performance, as our AI and experts continue to learn and improve
              over time."
            </p>
          </div>
          <div className="mt-7 flex justify-end">
            <Button className="w-fit" onClick={onNext}>
              Continue <RightArrow />
            </Button>
          </div>
        </Card>
      </OnboardingView>
    </>
  );
}
