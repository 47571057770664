import { ReactComponent as Shadow } from "../../../assets/images/onboarding-shadow.svg";
import { Stepper } from "./OnboardingSidebar";
import { ProgressBar } from "./ProgressBar";

export function OnboardingView({
  step,
  children,
  title,
  currentStep,
  totalSteps,
  infoView,
  contentContainerClassName,
}: {
  step: number;
  children: React.ReactNode;
  title?: string;
  currentStep?: number;
  totalSteps?: number;
  infoView?: React.ReactNode;
  contentContainerClassName?: string;
}) {
  return (
    <div className="flex-1 relative h-[100vh] bg-neutral-200">
      <div className="pt-20 lg:pt-7 px-5 lg:px-12 mb-1 lg:mb-0">
        <ProgressBar currentStep={step} />
      </div>
      <div
        className={`px-5 md:px-12 lg:px-0 h-[calc(100dvh-100px)] lg:h-[calc(100dvh-72px)] flex flex-col lg:justify-center lg:items-center overflow-y-auto ${contentContainerClassName}`}
      >
        {title && (
          <div className="lg:hidden mb-9">
            <div className="mt-4 text-base font-bold">{title}</div>
            <div className="mt-2">{infoView}</div>
            {totalSteps && (
              <div className="mt-4">
                <Stepper
                  currentStep={(currentStep || 0) + 1}
                  totalSteps={totalSteps}
                />
              </div>
            )}
          </div>
        )}
        <div className="flex justify-center md:items-center pb-2 md:pb-0">
          {children}
        </div>
      </div>
      {/* <Shadow className="absolute" /> */}
    </div>
  );
}
