import { useState } from "react";
import { getUser } from "../../helpers/utils";
import { useApp } from "../../helpers/app-context";
import { Page } from "../../components/Page";
import { Notification } from "../dashboard/components/Notification";
import { MarketingFunnel } from "./components/MarketingFunnel";
import { MarketingSummary } from "./components/MarketingSummary";
import { ChannelPerformance } from "./components/ChannelPerformance";
import { GuestReviewChart } from "./components/GuestReviewChart/GuestReviewChart";
import { FeederMarkets } from "./components/FeederMarkets/FeederMarkets";

export function Marketing() {
  const user = getUser();
  const [isPriceAdjustmentModalOpen, setIsPriceAdjustmentModalOpen] =
    useState(false);
  const { isChatBotOpen } = useApp() as { isChatBotOpen: boolean };
  return (
    <Page>
      <div
        className={`p-4 md:h-[calc(100dvh-62px)] overflow-auto lg:h-[100dvh] flex-1 ${
          isChatBotOpen ? "w-[calc(100vw-350px)]" : ""
        } 1xl:w-[unset]`}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold text-black">
            Marketing Dashboard
          </h1>
          <div className="hidden lg:block">
            <Notification />
          </div>
        </div>
        <div className="flex flex-col items-start  gap-5 mt-3.5 h-[calc(100dvh-100px)]">
          <div className="w-full grow-[1] flex-1 flex flex-col lg:flex-row gap-5">
            <div className="w-full md:w-auto flex-1">
              <MarketingSummary />
            </div>
            <div className="w-full md:w-auto flex-1">
              <MarketingFunnel />
            </div>
          </div>
          <div className="w-full grow-[1] flex-1 flex flex-col lg:flex-row gap-5 pb-4 tall3XL:pb-0">
            <div className="w-full md:w-auto flex-1 max-h-[510px]">
              <ChannelPerformance />
            </div>
            <div className="w-full md:w-auto flex-1 flex flex-col md:flex-row gap-5">
              <div className="w-full md:w-auto flex-1 max-h-[510px]">
                <GuestReviewChart />
              </div>
              <div className="w-full md:w-auto flex-1 max-h-[510px]">
                <FeederMarkets />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
