import { useMemo, useState } from "react";
import { Card } from "../../../../components/Card";
import { Rating } from "../../../../components/Rating";
import { ActionButtons } from "../ActionButtons";
import { useOnboardingSave } from "../../useOnboardingSave";
import { validateCompetitorSetForm3 } from "../../useValidation";
import { ImageView } from "../../../../components/Image";

function HotelRating({ value, onChange, name, image }) {
  return (
    <Card className="flex flex-col lg:flex-row items-center gap-2.5 !p-3">
      <div className="flex flex-row items-center gap-2.5 w-full">
        <div className="w-[30px] h-[30px] lg:w-[58px] lg:h-[58px] rounded">
          <ImageView
            className="w-full h-full rounded object-cover"
            src={`https://places.googleapis.com/v1/${image}/media?maxHeightPx=400&maxWidthPx=400&key=AIzaSyDE-xYVEPC8f9MVR11jl7CWO7bFRrNp2KA`}
          />
        </div>
        <div className="flex-1 flex flex-col gap-3">
          <span className="text-[13px] font-semibold leading-[9px]">
            {name}
          </span>

          <Rating
            labels={["Worse location", "Similar location", "Better location"]}
            value={value}
            onChange={(val) => onChange(val)}
            className="hidden lg:flex"
          />
        </div>
      </div>
      <Rating
        labels={["Worse location", "Similar location", "Better location"]}
        value={value}
        onChange={(val) => onChange(val)}
        className="flex lg:hidden"
      />
    </Card>
  );
}

export function Screen3Form({ setForm, form, isReview = false }) {
  const competitorLocationRating = useMemo(() => {
    return form?.competitorLocationRating || {};
  }, [form.competitorLocationRating]);

  return (
    <>
      <div className="flex flex-col [&_label]:!text-neutral-700">
        {!isReview && (
          <>
            <div className="text-xl lg:text-2xl font-semibold lg:px-8">
              Competitive set
            </div>
            <p className=" text-neutral-700 mt-2 mb-5 text-sm leading-[22px] lg:px-8">
              How does each hotel's{" "}
              <span className="text-primary">location</span> compare to your
              hotel?
            </p>
          </>
        )}
        <div
          className={`flex flex-col gap-3 h-[calc(100dvh-400px)] pb-2 ${
            !isReview ? "lg:px-8" : ""
          } overflow-auto`}
        >
          {form?.competitors?.map((hotel) => (
            <HotelRating
              key={hotel.place_id}
              name={hotel.name}
              value={competitorLocationRating[hotel.name] || 0}
              image={hotel.imageUrl}
              onChange={(val) =>
                setForm((prev) => {
                  const newCompetitorLocationRating = {
                    ...prev.competitorLocationRating,
                    [hotel.name]: val,
                  };
                  return {
                    ...prev,
                    competitorLocationRating: newCompetitorLocationRating,
                  };
                })
              }
            />
          ))}
        </div>
      </div>
    </>
  );
}

export function Screen3({ onBack, onNext, setForm, form }) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const handleNext = async () => {
    onSave(form);
  };
  const { isValid, errors } = validateCompetitorSetForm3(form);
  return (
    <Card className="lg:max-w-[670px] lg:w-[670px] !p-6 lg:!px-0">
      <Screen3Form setForm={setForm} form={form} />
      <div className="px-8">
        <ActionButtons
          onBack={onBack}
          onNext={handleNext}
          loading={saving}
          disabled={!isValid}
        />
      </div>
    </Card>
  );
}
