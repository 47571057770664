// validate the form fields in particular step
// i will pass form data, current step and substep
// Also One more function to validate the whole form

import { month } from "./components/AnnualBudget";

export const validateHotelForm = (form) => {
  const errors: any = {};
  if (!form?.hotelName) {
    errors.hotelName = "Hotel name is required";
  }
  if (!form?.hotelWebsite) {
    errors.hotelWebsite = "Hotel website is required";
  } else {
    const regex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#%&?._~+=-]*)*\/?$/;
    const split = form?.hotelWebsite.split(".");
    const isInValid = split[0].includes("www") && split.length < 3;
    if (!regex.test(form?.hotelWebsite) || isInValid) {
      errors.validHotelWebsite = "Invalid website URL";
    }
  }
  if (!form?.title) {
    errors.title = "Title is required";
  }
  if (!form?.pms) {
    errors.pms = "PMS is required";
  }
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateGeneralquestionForm = (form, roomTypes) => {
  const errors: any = {};
  /**
   * "otas": [
    "string"
  ],
  "roomTypes": {
    "roomTypes": [
      "string"
    ],
    "baseRoomType": "string",
    "prices": {}
  },
   */
  if (!form?.otas || form?.otas.length === 0) {
    errors.otas = "Please select at least one OTA";
  }
  if (!form?.roomTypes || !form?.roomTypes.baseRoomType) {
    errors.baseRoomType = "Please select a base room type";
  }
  if (form?.roomTypes?.baseRoomType) {
    const list = roomTypes.filter(
      (roomType) => roomType.value !== form?.roomTypes?.baseRoomType
    );

    // roomTypes should have prices for all room types
    list.forEach((roomType) => {
      if (!form?.roomTypes?.prices?.[roomType?.value]) {
        errors[`roomTypePrices-${roomType.value}`] = "Price is required";
      }
    });
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateCompetitorSetForm1 = (form) => {
  const errors: any = {};
  if (!form?.competitors || form?.competitors.length === 0) {
    errors.competitors = "Please select at least one competitor";
  }
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateCompetitorSetForm2 = (form) => {
  const errors: any = {};
  if (!form?.usp) {
    errors.usp = "USP is required";
  }
  if (!form?.amenities) {
    errors.amenities = "Amenities is required";
  }
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateCompetitorSetForm3 = (form) => {
  const errors: any = {};
  // competitorLocationRating should have data for all competitors
  const competitors = form?.competitors || [];
  const competitorLocationRating = form?.competitorLocationRating || {};
  competitors.forEach((competitor) => {
    if (!competitorLocationRating[competitor.name]) {
      errors[`competitorLocationRating-${competitor.name}`] =
        "Location rating is required";
    }
  });
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateCompetitorSetForm4 = (form) => {
  const errors: any = {};
  // competitorQualityRating should have data for all competitors
  const competitors = form?.competitors || [];
  const competitorQualityRating = form?.competitorQualityRating || {};
  competitors.forEach((competitor) => {
    if (!competitorQualityRating[competitor.name]) {
      errors[`competitorQualityRating-${competitor.name}`] =
        "Quality rating is required";
    }
  });
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateCompetitorSetForm5 = (form) => {
  const errors: any = {};
  // competitorPrices should have data for all competitors
  const competitors = form?.competitors || [];
  const competitorPrices = form?.competitorPrices || {};
  if (!form?.hotelCompetitivePriceLow) {
    errors.hotelCompetitivePriceLow = "Hotel competitive price low is required";
  }
  if (!form?.hotelCompetitivePriceHigh) {
    errors.hotelCompetitivePriceHigh =
      "Hotel competitive price high is required";
  }
  if (
    Number(form?.hotelCompetitivePriceLow) >=
    Number(form?.hotelCompetitivePriceHigh)
  ) {
    errors.hotelCompetitivePriceLow =
      "Hotel competitive price low should be less than high";
  }

  competitors.forEach((competitor) => {
    if (!competitorPrices[competitor.name]) {
      errors[`competitorPrices-${competitor.name}`] = "prices is required";
    }
    if (!competitorPrices[competitor.name]?.low) {
      errors[`competitorPrices-${competitor.name}-low`] =
        "Competitor price low is required";
    }
    if (!competitorPrices[competitor.name]?.high) {
      errors[`competitorPrices-${competitor.name}-high`] =
        "Competitor price high is required";
    }
    if (
      Number(competitorPrices[competitor.name]?.low) >=
      Number(competitorPrices[competitor.name]?.high)
    ) {
      errors[`competitorPrices-${competitor.name}-low`] =
        "Competitor price low should be less than high";
    }
  });
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateEventsForm = (form) => {
  const errors: any = {};
  if (!form?.seasons || form?.seasons.length === 0) {
    errors.seasons = "Please select at least one event";
  }
  if (form?.seasons?.length > 0) {
    form?.seasons?.forEach((event, index) => {
      if (!event) {
        return;
      }
      if (!event.name) {
        errors[`event-name-${index}`] = "Event name is required";
      }
      if (!event.startDate) {
        errors[`event-startDate-${index}`] = "Start date is required";
      }
      if (!event.endDate) {
        errors[`event-endDate-${index}`] = "End date is required";
      }
      if (event.startDate && event.endDate) {
        if (new Date(event.startDate) > new Date(event.endDate)) {
          errors[`event-endDate-${index}`] =
            "End date should be greater than start date";
        }
      }
    });
  }
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateAnnualBudgetForm = (form) => {
  const errors: any = {};
  //annualBudget is an array of objects
  //each object has month, availableRooms, adr, soldRooms
  //validate each object
  month.forEach((m, i) => {
    const budget = form?.annualBudget?.find((b) => b.month === i + 1);
    if (!budget) {
      errors[`month${i}`] = "Month is required";
    }
    if (!budget?.availableRooms) {
      errors[`availableRooms${i}`] = "Available rooms is required";
    }
    if (!budget?.adr) {
      errors[`adr${i}`] = "ADR is required";
    }
    if (!budget?.soldRooms) {
      errors[`soldRooms${i}`] = "Sold rooms is required";
    }
  });
  return { isValid: Object.keys(errors).length === 0, errors };
};

export const validateCompetitorSet = (form) => {
  const competitorSet1Validations = validateCompetitorSetForm1(form);
  const competitorSet2Validations = validateCompetitorSetForm2(form);
  const competitorSet3Validations = validateCompetitorSetForm3(form);
  const competitorSet4Validations = validateCompetitorSetForm4(form);
  const competitorSet5Validations = validateCompetitorSetForm5(form);

  return {
    isValid:
      competitorSet1Validations.isValid &&
      competitorSet2Validations.isValid &&
      competitorSet3Validations.isValid &&
      competitorSet4Validations.isValid &&
      competitorSet5Validations.isValid,
    errors: {
      ...competitorSet1Validations.errors,
      ...competitorSet2Validations.errors,
      ...competitorSet3Validations.errors,
      ...competitorSet4Validations.errors,
      ...competitorSet5Validations.errors,
    },
  };
};

export const validateReviewForm = (form, roomTypes) => {
  const generalValidations = validateGeneralquestionForm(form, roomTypes);
  const competitorSet1Validations = validateCompetitorSetForm1(form);
  const competitorSet2Validations = validateCompetitorSetForm2(form);
  const competitorSet3Validations = validateCompetitorSetForm3(form);
  const competitorSet4Validations = validateCompetitorSetForm4(form);
  const competitorSet5Validations = validateCompetitorSetForm5(form);
  const eventsValidations = validateEventsForm(form);
  const annualBudgetValidations = validateAnnualBudgetForm(form);

  // merge all errors & isValid from all validations
  const isValid =
    generalValidations.isValid &&
    competitorSet1Validations.isValid &&
    competitorSet2Validations.isValid &&
    competitorSet3Validations.isValid &&
    competitorSet4Validations.isValid &&
    competitorSet5Validations.isValid &&
    eventsValidations.isValid &&
    annualBudgetValidations.isValid;

  return {
    isValid,
    errors: {
      ...generalValidations.errors,
      ...competitorSet1Validations.errors,
      ...competitorSet2Validations.errors,
      ...competitorSet3Validations.errors,
      ...competitorSet4Validations.errors,
      ...competitorSet5Validations.errors,
      ...eventsValidations.errors,
      ...annualBudgetValidations.errors,
    },
  };
};
