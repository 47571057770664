import { useEffect, useMemo, useRef, useState } from "react";
import { Panel } from "../../../components/Panel";
import moment from "moment";
import { formatNumber, getPercentage, getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { YearFilter } from "../../dashboard/components/YearFilter";
import Spinner from "../../../components/Spinners";
import useResponsive from "../../../helpers/useResponsive";
const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

export function ChannelPerformance() {
  const { isMobile } = useResponsive();
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<any>({ ...getDates() });
  const [data, setData] = useState<any>({});

  const handleYearChange = (year: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(year), year }));
  };

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "ChannelPerformance",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const totals = useMemo(() => {
    const websiteTotal = Object.keys(data?.metricsByChannel || {}).reduce(
      (acc, key) => {
        return acc + Number(data?.metricsByChannel[key]?.websiteVisits || 0);
      },
      0
    );
    const totalBookings = Object.keys(data?.metricsByChannel || {}).reduce(
      (acc, key) => {
        return acc + Number(data?.metricsByChannel[key]?.totalBookings || 0);
      },
      0
    );
    const avgConversationRate = Object.keys(
      data?.metricsByChannel || {}
    ).reduce((acc, key) => {
      return acc + Number(data?.metricsByChannel[key]?.conversationRate || 0);
    }, 0);
    const totalRevenue = Object.keys(data?.metricsByChannel || {}).reduce(
      (acc, key) => {
        return acc + Number(data?.metricsByChannel[key]?.totalRevenue || 0);
      },
      0
    );
    const commissionRate = Object.keys(data?.metricsByChannel || {}).reduce(
      (acc, key) => {
        if (isNaN(Number(data?.metricsByChannel[key]?.commissionRate || 0))) {
          return acc;
        }
        return acc + Number(data?.metricsByChannel[key]?.commissionRate || 0);
      },
      0
    );
    const netRevenue = Object.keys(data?.metricsByChannel || {}).reduce(
      (acc, key) => {
        return acc + Number(data?.metricsByChannel[key]?.netRevenue || 0);
      },
      0
    );
    const totalPercentge = Object.keys(data?.metricsByChannel || {}).reduce(
      (acc, key) => {
        return acc + Number(data?.metricsByChannel[key]?.totalPercentge || 0);
      },
      0
    );
    return {
      websiteTotal,
      totalBookings,
      avgConversationRate:
        avgConversationRate / Object.keys(data?.metricsByChannel || {}).length,
      totalRevenue,
      commissionRate:
        commissionRate / (Object.keys(data?.metricsByChannel || {}).length + 1),
      netRevenue,
      totalPercentge,
    };
  }, [data?.metricsByChannel]);

  return (
    <Panel
      label="Channel Performance"
      // className="max-w-[700px]"
      actions={
        <div className="flex items-center gap-1.5">
          <YearFilter
            filter={filter}
            setFilter={setFilter}
            disabled={false}
            onChange={handleYearChange}
            value={filter.year}
          />
        </div>
      }
      className="max-w-[calc(100vw-40px)] relative overflow-x-auto md:max-w-[unset] 3xl:h-full"
      showNavigation={isMobile}
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[350px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <>
          <table className="w-full relative" cellSpacing={12}>
            <colgroup>
              <col className="w-[16%]" />
              <col className="w-[14%]" />
              <col className="w-[14%]" />
              <col className="w-[14%]" />
              <col className="w-[14%]" />
              <col className="w-[14%]" />
              <col className="w-[14%]" />
            </colgroup>
            <thead className="w-full">
              <tr className="sticky top-0 text-neutral-800 bg-neutral-300 [&_td]:text-2xs tall3XL:[&_td]:text-sm [&_td]:font-medium [&_td]:py-2.5 tall3XL:[&_td]:py-4">
                <td className="text-left pl-1.5 1xl:pl-4">Channel</td>
                <td className="text-center">Review Score</td>
                {/* <td className="text-center">Listing Score</td> */}
                {/* <td className="text-center">Website Visits</td> */}
                <td className="text-center"># of Bookings</td>
                {/* <td className="text-center">Conv. Rate</td> */}
                <td className="text-center">Revenue</td>
                <td className="text-center">Commission Rate</td>
                <td className="text-center">Net Revenue</td>
                <td className="text-center">%Total</td>
              </tr>
            </thead>
            <tbody>
              {/** check for percentage */}
              <tr className="text-2xs tall3XL:text-sm font-medium [&_td]:text-neutral-700 [&_td]:py-3.5 tall3XL:[&_td]:py-[18px]  [&_td]:border-y [&_td]:border-y-[#B7D3FF] [&_td]:bg-[#ECF3FF]">
                <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">Autumn</td>
                <td className="tracking-[-0.44px] text-center">4.5/5</td>
                {/* <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                  -
                </td> */}
                {/* <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">100k</div>
                </td> */}
                <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">100k</div>
                </td>
                {/* <td className="tracking-[-0.44px] text-center !text-[#635F66]">
                  2.5%
                </td> */}
                <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">$625k</div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">12%</div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">$550k</div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">65%</div>
                </td>
              </tr>
              {Object.keys(data?.metricsByChannel || {}).map((key) => {
                const metric = data?.metricsByChannel[key];
                return (
                  <tr
                    key={key}
                    className="text-2xs tall3XL:text-sm font-medium [&_td]:text-neutral-700 [&_td]:py-3.5 tall3XL:[&_td]:py-[18px]"
                  >
                    <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">
                      {key}
                    </td>
                    <td className="tracking-[-0.44px] text-center">
                      {metric?.reviewScore}/5
                    </td>
                    {/* <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                      {metric?.listingScore}/5
                    </td> */}
                    {/* <td className="tracking-[-0.44px] pl-1">
                      <div className="w-full flex justify-center">0</div>
                    </td> */}
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        {formatNumber(Number(metric?.totalBookings || 0))}
                      </div>
                    </td>
                    {/* <td className="tracking-[-0.44px] text-center !text-[#635F66]">
                      {getPercentage(Number(metric?.conversationRate || 0))}%
                    </td> */}
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        ${formatNumber(Number(metric?.totalRevenue || 0))}
                      </div>
                    </td>
                    <td className="tracking-[-0.44px] pr-1">
                      <div className="w-full flex justify-center">
                        {getPercentage(Number(metric?.commissionRate || 0))}%
                      </div>
                    </td>
                    <td className="tracking-[-0.44px] pr-1">
                      <div className="w-full flex justify-center">
                        {formatNumber(Number(metric?.netRevenue || 0))}
                      </div>
                    </td>
                    <td className="tracking-[-0.44px] pr-1">
                      <div className="w-full flex justify-center">
                        {getPercentage(Number(metric?.totalPercentge || 0))}%
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr className="text-2xs tall3XL:text-sm font-medium [&_td]:text-neutral-700 [&_td]:py-3.5 tall3XL:[&_td]:py-[18px]">
                <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">Others</td>
                <td className="tracking-[-0.44px] text-center">-</td>
                {/* <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                  -
                </td> */}
                {/* <td className="tracking-[-0.44px] pl-1">
                  <div className="w-full flex justify-center">0</div>
                </td> */}
                <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">-</div>
                </td>
                {/* <td className="tracking-[-0.44px] text-center !text-[#635F66]">
                  -
                </td> */}
                <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">-</div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">-</div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">-</div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">-</div>
                </td>
              </tr>
              <tr className="text-2xs tall3XL:text-sm font-medium [&_td]:text-[#525254] [&_td]:py-[13px] tall3XL:[&_td]:py-[18px] [&_td]:border-t [&_td]:border-t-[#646466] [&_td]:bg-[#F4F4F6]">
                <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4" colSpan={2}>
                  Total / W.A.
                </td>
                {/* <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">
                    {formatNumber(totals?.websiteTotal)}
                  </div>
                </td> */}
                <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">
                    {formatNumber(totals?.totalBookings, "")}
                  </div>
                </td>
                {/* <td className="tracking-[-0.44px] text-center !text-[#635F66]">
                  {getPercentage(totals?.avgConversationRate || 0)}%
                </td> */}
                <td className="tracking-[-0.44px]">
                  <div className="w-full flex justify-center">
                    {formatNumber(totals?.totalRevenue, "$")}
                  </div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">
                    {formatNumber(totals?.commissionRate, "%")}
                  </div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">
                    {formatNumber(totals?.netRevenue, "$")}
                  </div>
                </td>
                <td className="tracking-[-0.44px] pr-1">
                  <div className="w-full flex justify-center">
                    {formatNumber(totals?.totalPercentge, "%")}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="px-2.5 pl-1.5 1xl:pl-4 pb-12 pt-3.5 md:py-3.5 text-2xs tall3XL:text-sm text-[#333134] tracking-[-0.11px] italic rounded-b">
            Foot note: this is the example of a footnote lorem ipsum dolor sits
            amet consicute
          </div>
        </>
      )}
    </Panel>
  );
}
