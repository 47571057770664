const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type TransformedDataPoint = {
  name: string;
  count: number;
};

/**
 * Transforms the data for the revenue chart
 * @param data - The data for the current year
 * @param prevData - The data for the previous year
 * @param forecastData - The data for the forecasted year
 *
 * @returns
 * [
 *  {
 *      name: "Jan",
 *      onTheBooks: 1000,
 *      previous: 1000,
 *      forecast: 1000,
 *  }
 *  ...
 *  {
 *      name: "Dec",
 *      onTheBooks: 1000,
 *      previous: 1000,
 *      forecast: 1000,
 *  }
 * ]
 */
export const transformData = ({
  data = [],
  currentField,
}: {
  data: any[];
  currentField: string;
}): TransformedDataPoint[] => {
  return data.map((month) => {
    return {
      name: months[Number(month?.month || 0) - 1],
      count: Number.parseFloat(Number(month[currentField] || 0).toFixed(0)),
    };
  });
};

export const transformRevenueByChannelData = ({ data = {} }: { data: any }) => {
  const bookingDotCom = data?.bookingDotCom || [];
  const expedia = data?.expedia || [];
  const other = data?.other || [];
  const direct = data?.direct || [];
  const newData: any[] = [];
  bookingDotCom.forEach((month) => {
    const expediaMonth = expedia.find((e) => e?.month === month?.month);
    const otherMonth = other.find((e) => e?.month === month?.month);
    const directMonth = direct.find((e) => e?.month === month?.month);
    newData.push({
      name: months[Number(month?.month || 0) - 1],
      bookingDotCom: Number.parseFloat(
        Number(month?.percentage || 0).toFixed(0)
      ),
      expedia: Number.parseFloat(
        Number(expediaMonth?.percentage || 0).toFixed(0)
      ),
      other: Number.parseFloat(Number(otherMonth?.percentage || 0).toFixed(0)),
      direct: Number.parseFloat(
        Number(directMonth?.percentage || 0).toFixed(0)
      ),
    });
  });
  return newData;
};
