import React, { useEffect, useMemo, useState } from "react";
import { Panel } from "../../../../components/Panel";
import moment from "moment";
import { api } from "../../../../helpers/api";
import {
  formatNumber,
  getPercentage,
  getUser,
} from "../../../../helpers/utils";
import { YearFilter } from "../../../dashboard/components/YearFilter";
import Spinner from "../../../../components/Spinners";

const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

export function FeederMarkets() {
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [filter, setFilter] = useState<any>({
    ...getDates(),
    type: "adr_index",
  });

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "FeederMarket",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics?.feederMarkets || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleYearChange = (year: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(year) }));
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const markets = useMemo(() => {
    return data?.map((market: any) => ({
      market: `${market?.countryName}, ${market?.countryCode}`,
      bookings: formatNumber(Number(market?.totalBookings || 0), "$"),
      percentage: `${getPercentage(Number(market?.percentage || 0))}%`,
    }));
  }, [data]);

  return (
    <Panel
      label="Top Feeder Markets"
      className="h-full flex flex-col"
      contentClassName="h-[calc(100%-50px)] tall3:h-full"
      actions={
        <div className="flex items-center gap-1.5">
          <YearFilter
            filter={filter}
            setFilter={setFilter}
            disabled={false}
            onChange={handleYearChange}
            value={filter.year}
          />
        </div>
      }
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[350px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="max-h-[40vh] tall3XL:max-h-[unset] mb-2 tall3XL:pb-[unset] tall3XL:overflow-[unset]">
          <table className="w-full relative" cellSpacing={12}>
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead className="w-full">
              <tr className="sticky top-0 text-neutral-800 bg-neutral-300 [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 ">
                <td className="pl-1.5 1xl:pl-4 text-left">Market</td>
                <td className="text-center">Bookings</td>
                <td className="text-center">% total</td>
              </tr>
            </thead>
            <tbody>
              {markets.map((market) => (
                <tr
                  key={market.market}
                  className="text-2xs [&_td]:text-neutral-700 [&_td]:py-3.5  [&_td]:border-b [&_td]:border-b-[#F4F4F6]"
                >
                  <td className="tracking-[-0.44px] pl-1.5 1xl:pl-4">
                    {market.market}
                  </td>
                  <td className="tracking-[-0.44px] text-center">
                    {market?.bookings}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                    {market.percentage}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Panel>
  );
}
