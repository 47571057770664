import { useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { useApp } from "../../helpers/app-context";
import { getUser } from "../../helpers/utils";
import { Notification } from "../dashboard/components/Notification";
import { CalendarView } from "./components/CalendarView";
import { Summary } from "./components/Summary";
import { PriceAdjustmentModal } from "./components/PriceAdjustmentModal";
import { api } from "../../helpers/api";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";

export function Calendar() {
  const queryClient = useQueryClient();
  const user = getUser();
  const [isPriceAdjustmentModalOpen, setIsPriceAdjustmentModalOpen] =
    useState(false);
  const { isChatBotOpen } = useApp() as { isChatBotOpen: boolean };

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [data, setData] = useState<any>({});
  const [dayPrice, setDayPrice] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const userId = user?.id;

  const fetchData = async (day) => {
    try {
      setIsLoading(true);
      const dayMetrics = await api.getDayMetrics(userId, {
        type: "Summary",
        date: moment(day).format("YYYY-MM-DD"),
      });
      const dayPriceResponse = await api.getDayPrice(
        userId,
        moment(day).format("YYYY-MM-DD")
      );
      setDayPrice(dayPriceResponse);
      setData(dayMetrics);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSelect = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);

  return (
    <Page>
      <div
        className={`p-4 md:h-[calc(100dvh-62px)] overflow-auto lg:h-[100dvh] flex-1 ${
          isChatBotOpen ? "w-[calc(100vw-350px)]" : ""
        } 1xl:w-[unset]`}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold text-black">Calendar View</h1>
          <div className="hidden lg:block">
            <Notification />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start gap-4 mt-3.5">
          <div className="w-full md:flex-1">
            <CalendarView onSelect={handleSelect} selectedDate={selectedDate} />
          </div>
          <Summary
            selectedDate={selectedDate}
            onOpenPriceAdjustmentModal={() =>
              setIsPriceAdjustmentModalOpen(true)
            }
            data={data}
            dayPrice={dayPrice}
            isLoading={isLoading}
          />
        </div>
      </div>
      {isPriceAdjustmentModalOpen && (
        <PriceAdjustmentModal
          selectedDate={selectedDate}
          onClose={() => setIsPriceAdjustmentModalOpen(false)}
          data={data}
          dayPrice={dayPrice}
          onUpdate={() => {
            queryClient.invalidateQueries({
              queryKey: ["calendarData"],
            });
            fetchData(selectedDate);
          }}
        />
      )}
    </Page>
  );
}
