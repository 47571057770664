import clsx from "clsx";
import { useState } from "react";

export default function TextArea({
  label,
  name,
  id,
  value,
  placeholder,
  onChange,
  className,
  error,
  forgotLink,
  disabled,
  inputClassName = "",
  ...props
}: {
  label: string;
  name?: string;
  id?: string;
  value: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  error?: any;
  forgotLink?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  rows?: number;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={clsx("flex flex-col gap-1.5", className)}>
      <label htmlFor={id} className="text-[#161616] text-sm">
        {label}
      </label>
      <textarea
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        {...props}
        className={`bg-white outline-neutral-600 py-2.5 px-3 placeholder:text-neutral-600 border border-neutral-400 rounded text-sm leading-[18px] ${inputClassName}`}
      />

      {error && <p className="text-[#EF4444] text-[0.75rem]">{error}</p>}
    </div>
  );
}
