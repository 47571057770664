import Button from "../../../components/Button";
import { Card } from "../../../components/Card";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { Link } from "react-router-dom";

export function GoLive({ onNext, step }: { onNext: () => void; step: number }) {
  return (
    <>
      <OnboardingSidebar title="Go live!">
        <span />
      </OnboardingSidebar>
      <OnboardingView step={step}>
        <div className="lg:max-w-[400px]">
          <CheckIcon className="mb-4" />
          <h2 className="text-2xl font-semibold">Congratulations! </h2>
          <div className="mt-5 text-sm text-neutral-800 flex flex-col gap-2.5">
            <p>Thank you for completing the onboarding!</p>
            <p>
              We've received all of your details and are now creating a bespoke
              revenue strategy that is customized for your hotel. You'll receive
              an email from us shortly letting you know when things are ready.
            </p>
            <p>
              In the meantime, we recommend focusing on uplifting your guest
              reviews, as we’ll soon take care of the rest!
            </p>
          </div>
          <div className="mt-7 flex justify-end">
            <Link to="/dashboard">
              <Button className="w-fit">
                Continue <RightArrow />
              </Button>
            </Link>
          </div>
        </div>
      </OnboardingView>
    </>
  );
}
