import { useEffect, useState } from "react";
import { Logo } from "../../components/Logo";
import { Welcome } from "./components/Welcome";
import { PMSConnect } from "./components/PMSConnect";
import { GeneralSettings } from "./components/GeneralSettings";
import { CompetitorSet } from "./components/CompetitorSet/CompetitorSet";
import { SeasonsAndEvents } from "./components/SeasonsAndEvents";
import { AnnualBudget } from "./components/AnnualBudget";
import { Review } from "./components/Review";
import { Plan } from "./components/Plan";
import { GoLive } from "./components/GoLive";
import { api } from "../../helpers/api";
import { getUser } from "../../helpers/utils";
import { ConnectGoogleAnalytics } from "./components/ConnectGoogleAnalytics";
import useStripeSession from "../../helpers/hooks/useStripeSession";
import { OtaRates } from "./components/OtaRates/OtaRates";

const determineStep = (form: any, activeSubscription: any) => {
  if (
    activeSubscription?.length > 0 &&
    activeSubscription?.[0]?.status === "Paid"
  ) {
    return 8;
  }
  if (!form) {
    return 0;
  }
  if (!form.pms && !form.hotelName && !form.hotelWebsite && !form.title) {
    return 0;
  }
  if (!form.userPms) {
    return 1;
  }

  if (!form.otas || form.otas.length === 0) {
    return 2;
  }
  if (!form.roomTypes || !form.roomTypes.baseRoomType) {
    return 2;
  }
  if (
    !form.competitors ||
    form.competitors.length === 0 ||
    !form.usp ||
    !form.amenities ||
    !form.competitorLocationRating ||
    !form.competitorPrices ||
    !form.competitorQualityRating
  ) {
    return 3;
  }
  if (!form.seasons || form.seasons.length === 0) {
    return 4;
  }
  if (!form.annualBudget || form.annualBudget.length === 0) {
    return 5;
  }
  return 6;
};

const steps = {
  0: Welcome,
  1: PMSConnect,
  2: OtaRates,
  3: GeneralSettings,
  4: CompetitorSet,
  5: SeasonsAndEvents,
  6: AnnualBudget,
  7: Review,
  8: ConnectGoogleAnalytics,
  9: Plan,
  10: GoLive,
};

export function Onboarding() {
  const user = getUser();
  const { activeSubscription, isLoading } = useStripeSession();
  const [isDetermined, setIsDetermined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const Step = steps[step];
  const [form, setForm] = useState<any>({
    // events: [],
    // annualBudget: [
    //   ...Array(12)
    //     .fill(null)
    //     .map((_, i) => ({ month: i + 1 })),
    // ],
  });

  const handleNext = () => {
    if (step < Object.keys(steps).length - 1) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const fetchOnboardingData = async (userId: string) => {
    setLoading(true);
    const onboardingResponse = await api.getOnboarding(userId);
    const { userMetadata, userPms } = onboardingResponse;
    const newStep = determineStep(
      { ...userMetadata, userPms },
      activeSubscription
    );
    const otas = userMetadata?.otas?.filter((ota: any) => ota !== "[]");
    setForm({ ...userMetadata, userPms, otas } || {});
    setStep(newStep || 0);
    setLoading(false);
  };

  useEffect(() => {
    if (user?.id) {
      fetchOnboardingData(user.id);
    }
  }, [user?.id, activeSubscription]);

  if (loading || isLoading) {
    return <div />;
  }

  return (
    <div className="flex-1 flex items-center [&_.label]:!text-neutral-700">
      <div className="lg:hidden z-[1] h-[58px] fixed top-0 left-0 w-full flex items-center px-5 bg-white border border-neutral-300">
        <Logo className="!text-[25px] !leading-0 !tracking-[-1.033px]" />
      </div>
      <Step
        onNext={handleNext}
        onBack={handleBack}
        step={step}
        form={form}
        setForm={setForm}
        isDetermined={isDetermined}
        setIsDetermined={setIsDetermined}
      />
    </div>
  );
}
