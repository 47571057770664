import {
  DropdownIndicatorProps,
  default as ReactSelect,
  components,
  Props,
} from "react-select";
import { ReactComponent as CaretDownIcon } from "../assets/icons/caret-down.svg";
import { ReactComponent as CheckMarkIcon } from "../assets/icons/check-mark.svg";

const SingleOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-2.5 h-[25px]">
        <label className="text-[#161938] text-xs font-medium">
          {props.label}
        </label>
        {props.isSelected ? (
          <CheckMarkIcon className="[&_path]:stroke-primary w-3 h-3" />
        ) : (
          <div className="w-3 h-3" />
        )}
      </div>
    </components.Option>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M3.08301 3.0835L8.91664 8.91713"
          stroke="#8B8C91"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.91699 3.0835L3.08336 8.91713"
          stroke="#8B8C91"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </components.MultiValueRemove>
  );
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon
        className={`${
          props.isFocused ? "rotate-180 [&_path]:stroke-neutral-500" : ""
        }`}
      />
    </components.DropdownIndicator>
  );
};

export function Select({
  value,
  onChange,
  data,
  className,
  ...filterProps
}: {
  value: unknown;
  onChange: (value: unknown) => void;
  data: { value: string; label: string }[];
} & Props) {
  return (
    <div>
      <ReactSelect
        classNames={{
          control: (state) =>
            `${
              className || ""
            } !px-1 [&>div]:!px-0 [&>div]:!h-6 [&>div:first-child]:!items-start [&>div:first-child>div:last-child]:!my-0 [&>div:first-child>div:last-child]:!py-0 ${
              state.isFocused
                ? "!border !shadow-none !border-neutral-500"
                : "border-neutral-400"
            }`,
          option: (state) =>
            `cursor-pointer !bg-transparent hover:!bg-[#F2F1FF] !mx-1 !p-0 !flex justify-center items-center !w-[82px] ${
              state.isSelected ? "!bg-transparent" : ""
            }`,
          multiValue: () =>
            "!rounded !bg-neutral-200 border border-neutral-400 !h-6 !items-center !text-neutral-700 !text-2xs",
          singleValue: () =>
            "!text-2xs !p-0 flex justify-center mt-[1px] !text-[#8B8C91]",
          indicatorsContainer: () => "[&>div]:!p-0 [&_svg]:!w-3 [&_svg]:!h-3",
          indicatorSeparator: () => "hidden",
          menu: () =>
            "!w-[92px] !right-[-4px] [&>div]:max-h-[190px] !mt-[2px] !shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#E9E9EB] rounded",
        }}
        options={data}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        isClearable={false}
        components={{
          Option: SingleOption,
          MultiValueRemove,
          DropdownIndicator,
        }}
        onChange={(value) => onChange(value)}
        value={value}
        // Hide dropdown list  when select any item
        // closeMenuOnSelect={true}

        //Selected Item Remove in dropdown list
        // hideSelectedOptions={true}
        {...filterProps}
      />
    </div>
  );
}
