import { PopupModal } from "../../../components/Modal";
import Button from "../../../components/Button";

export function ConfirmDeleteSeason({
  onClose,
  onConfirm,
  season,
}: {
  onClose: () => void;
  onConfirm: () => void;
  season: any;
}) {
  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to delete{" "}
        <span className="font-medium">{season?.name}</span> season? Once you
        confirm, this action can not be undone.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          No, go back
        </Button>
        <Button className="px-8 text-[1rem]" onClick={onConfirm}>
          Yes, delete
        </Button>
      </div>
    </PopupModal>
  );
}
