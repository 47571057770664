import React, { PureComponent, useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Panel } from "../../../../components/Panel";
import moment, { min } from "moment";
import { FilterSelect, types } from "./components/FilterSelect";
import { api } from "../../../../helpers/api";
import {
  commaSeparatedNumber,
  formatNumber,
  getPercentage,
  getUser,
} from "../../../../helpers/utils";
import { transformData, type TransformedDataPoint } from "./utils";
import useResponsive from "../../../../helpers/useResponsive";
import { YearFilter } from "../YearFilter";
import Spinner from "../../../../components/Spinners";

const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

const CustomBarWithTarget = (props) => {
  const {
    fill,
    x,
    y,
    width,
    height,
    budget,
    current,
    startDate,
    payload,
    name,
  } = props;

  // check start date for year and check name for month ["Jan", ... "Dec"]
  // If it's future or current month then use onTheBooks value else use current value for bar
  const currentMonth = moment().format("MMM");
  const currentYear = moment().format("YYYY");
  const month = moment(name, "MMM").isSameOrAfter(moment(currentMonth, "MMM"));
  const year = moment(moment(startDate).format("YYYY"), "YYYY").isSameOrAfter(
    moment(currentYear)
  );
  const isFuture = month && year;

  let totalHeight = y + height;
  let targetY = totalHeight - (height / current) * budget;

  let mainHeight = height;
  let mainY = y;
  if (isFuture) {
    const onTheBooks = payload?.onTheBooks;
    // use onTheBooks value
    mainHeight = (height / current) * onTheBooks;
    mainY = totalHeight - mainHeight;
  }
  return (
    <svg>
      <rect
        x={x}
        y={mainY}
        width={width}
        rx={2}
        ry={2}
        height={mainHeight}
        stroke="none"
        fill={isFuture ? "#B6B6BD" : fill}
      />
      <line
        x1={x} // 140
        x2={x + width} // 184
        y1={targetY || y}
        y2={targetY || y}
        stroke={"#655CFE"}
        strokeWidth={1.5}
      />
    </svg>
  );
};

const CustomTooltip = ({ active, payload, type, rooms_ooo }: any) => {
  const data = payload[0]?.payload || {};
  const label = rooms_ooo
    ? "Rooms OOO"
    : types.find((t) => t.value === type)?.label;
  return (
    <div className="p-2.5 bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#f4f4f6] justify-start items-start gap-5 flex">
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className="w-3 h-3 bg-neutral-400 rounded-full" />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
            Last year
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {type === "occ" && !rooms_ooo
              ? `${commaSeparatedNumber(data?.previous || 0, true)}`
              : `${commaSeparatedNumber(data?.previous || 0, false)}`}
          </div>
        </div>
      </div>
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div
            className={`w-3 h-3 ${
              data?.actualCurrent ? "bg-black" : "bg-[#B6B6BD]"
            } rounded-full`}
          />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
            {data?.actualCurrent ? "Total" : "On The Books"}
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {type === "occ" && !rooms_ooo
              ? `${commaSeparatedNumber(
                  data?.actualCurrent || data?.onTheBooks || 0,
                  true
                )}`
              : `${commaSeparatedNumber(
                  data?.actualCurrent || data?.onTheBooks || 0,
                  false
                )}`}
          </div>
        </div>
      </div>
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className={`w-3 h-3 bg-primary rounded-full`} />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
            Budget
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {type === "occ" && !rooms_ooo
              ? `${commaSeparatedNumber(data?.budget || 0, true)}`
              : `${commaSeparatedNumber(data?.budget || 0, false)}`}
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomCursor = (props) => {
  const { isQHD } = useResponsive();
  const { x, y, width, height, stroke } = props;
  // make width 25px
  const newWidth = isQHD ? 60 : 34;
  // set x in center
  const remaining = (width - newWidth) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

export function RevenueChart() {
  const user = getUser();
  const { isMobile, isTall2XLDesktop, isTall2, isQHD } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<{
    startDate: string;
    endDate: string;
    type: string;
    year: string;
    rooms_ooo?: boolean;
  }>({ ...getDates(), type: "revenue" });
  const [data, setData] = useState<any>([]);

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "RevenueSummary",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const graph = useMemo(() => {
    let transformedData: TransformedDataPoint[] = [];
    if (filter.type === "revenue") {
      transformedData = transformData({
        data: data || [],
        previousField: "revenuePrevYear",
        onTheBooksField: "onBooksRevenue",
        currentField: "actualRevenue",
        startDate: filter.startDate,
        endDate: filter.endDate,
        budgetField: "roomsRevenue",
      });
    } else if (filter.type === "revpar") {
      transformedData = transformData({
        data: data || [],
        previousField: filter.rooms_ooo
          ? "revparPrevYearWithOOO"
          : "revparPrevYear",
        onTheBooksField: filter.rooms_ooo
          ? "onBooksRevparWithOOO"
          : "onBooksRevpar",
        currentField: filter.rooms_ooo ? "actualRevparWithOOO" : "actualRevpar",
        startDate: filter.startDate,
        endDate: filter.endDate,
        budgetField: "revpar",
      });
    } else if (filter.type === "adr") {
      transformedData = transformData({
        data: data || [],
        previousField: "adrPrevYear",
        onTheBooksField: "onBooksAdr",
        currentField: "actualAdr",
        budgetField: "adr",
        startDate: filter.startDate,
        endDate: filter.endDate,
      });
    } else if (filter.type === "occ") {
      transformedData = transformData({
        data: data || [],
        previousField: filter.rooms_ooo
          ? "occupancyWithOOOPrevYear"
          : "occupancyPrevYear",
        onTheBooksField: filter.rooms_ooo
          ? "onBooksOccupancyWithOOO"
          : "onBooksOccupancy",
        currentField: filter.rooms_ooo
          ? "actualOccupancyWithOOO"
          : "actualOccupancy",
        startDate: filter.startDate,
        endDate: filter.endDate,
        budgetField: "occupancy",
      });
    }
    return transformedData;
  }, [data, filter]);

  return (
    <Panel
      label="Revenue Summary"
      actions={
        <div className="flex items-center gap-1.5">
          <FilterSelect filter={filter} setFilter={setFilter} />
          <YearFilter
            disabled={false}
            filter={filter}
            setFilter={setFilter}
            isSummary
          />
        </div>
      }
      className="h-[310px] relative tall3:h-[360px] tall3XL:h-[410px] qhd1:h-[600px] max-w-[calc(100vw-28px)] overflow-x-hidden tall3XL:[&_.header]:h-[50px]"
      contentClassName="h-[calc(100%-50px)]"
      showNavigation={isMobile}
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="h-full w-[700px] md:w-[unset] flex justify-center items-center p-4 pt-6 pb-0 !pl-[2px]">
          <ResponsiveContainer
            width={isMobile ? 700 : undefined}
            height={
              isQHD
                ? 500
                : isTall2 || isMobile
                ? 209
                : isTall2XLDesktop
                ? 275
                : 300
            }
          >
            <BarChart
              width={500}
              height={300}
              data={graph}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barGap={4}
            >
              <CartesianGrid
                strokeDasharray="0"
                vertical={false}
                horizontal={{
                  color: "red",
                }}
              />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                className="!text-2xs !text-neutral-600 leading-[11px]"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                width={30}
                className="!text-2xs !text-neutral-600 leading-[11px]"
                tickFormatter={(value) => {
                  // make value to k denotes thousands
                  const val = Number(value);
                  // If zero then show 0 without dollar sign
                  if (val === 0) {
                    return "0";
                  }
                  if (val > 1000) {
                    return filter.type === "occ"
                      ? `${commaSeparatedNumber(Number(val) / 1000, true)}`
                      : `${formatNumber(Number(val || 0), "$")}`;
                  }
                  return filter.type === "occ"
                    ? `${commaSeparatedNumber(Number(val), true)}`
                    : `$${val}`;
                }}
              />
              <Tooltip
                position={{ y: 0 }}
                cursorStyle={{ maxWidth: 25 }}
                content={
                  <CustomTooltip
                    type={filter.type}
                    rooms_ooo={filter.rooms_ooo}
                  />
                }
                cursor={graph?.length > 0 && <CustomCursor />}
              />
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stop-color="rgba(204, 196, 255, 1)" />
                  <stop offset="100%" stop-color="white" />
                </linearGradient>
              </defs>
              <Bar
                dataKey="onTheBooks"
                visibility={"hidden"}
                fill="#E9E9EB"
                barSize={0.01}
                radius={[2, 2, 0, 0]}
              />
              <Bar
                dataKey="previous"
                fill="#E9E9EB"
                barSize={isQHD ? 20 : 12}
                radius={[2, 2, 0, 0]}
              />
              <Bar
                dataKey="current"
                fill="#000"
                shape={<CustomBarWithTarget startDate={filter.startDate} />}
                isAnimationActive={false}
                barSize={isQHD ? 20 : 12}
                radius={[2, 2, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </Panel>
  );
}
