import { SideModal } from "../../../components/SideModal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import Button from "../../../components/Button";
import { PriceInput } from "../../../components/PriceInput";
import { useState } from "react";
import { commaSeparatedNumber, getUser } from "../../../helpers/utils";
import { Switch } from "../../../components/Switch";
import moment from "moment";
import { api } from "../../../helpers/api";
import { ConfirmResetModal } from "./ConfirmResetModal";

const calculateNewPrice = (
  newPriceOrPercentage: string,
  basePrice: string | number,
  relativePrice: boolean
) => {
  // check if newPriceOrPercentage is a number & base price is a number
  const newPriceOrPercentageNumber = Number(newPriceOrPercentage);
  const basePriceNumber = Number(basePrice);

  if (isNaN(newPriceOrPercentageNumber) || isNaN(basePriceNumber)) {
    return "";
  }
  if (relativePrice) {
    // it's a percentage
    const newPrice = (basePriceNumber * newPriceOrPercentageNumber) / 100;
    return (newPrice + basePriceNumber).toFixed(0);
  } else {
    // it's a new price
    return (newPriceOrPercentageNumber + basePriceNumber).toFixed(0);
  }
};

export function PriceAdjustmentModal({
  onClose,
  selectedDate,
  data,
  dayPrice,
  onUpdate,
}: {
  onClose: () => void;
  selectedDate?: string;
  data: any;
  dayPrice: any;
  onUpdate: () => void;
}) {
  const user = getUser();
  const [saving, setSaving] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [form, setForm] = useState({
    ...(data || {}),
    relativePrice: true,
    bulk: false,
    rateFloor: "",
    rateCeiling: "",
    baseRoomTypeNewPrice: `${dayPrice?.suggestedPrice || ""}`,
    roomTypes: data?.roomTypes?.map((room) => ({
      ...room,
      newPrice: `${(
        Number(dayPrice?.suggestedPrice || 0) +
        (Number(room?.percentage) / 100) * Number(dayPrice?.suggestedPrice || 0)
      ).toFixed(0)}`,
    })),
  });
  const handleClose = () => {
    onClose();
  };

  const handleResetAll = () => {
    setForm({
      ...data,
      relativePrice: true,
      bulk: false,
      baseRoomTypeNewPrice: `${dayPrice?.suggestedPrice || ""}`,
      roomTypes: data?.roomTypes?.map((room) => ({
        ...room,
        newPrice: `${(
          Number(dayPrice?.suggestedPrice || 0) +
          (Number(room?.percentage) / 100) *
            Number(dayPrice?.suggestedPrice || 0)
        ).toFixed(0)}`,
      })),
    });
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const payload: any = {
        startDate: selectedDate,
        roomTypePrices: form?.roomTypes.map((room) => ({
          roomTypeId: room.id,
          price: room.newPrice,
        })),
      };
      if (form?.bulk) {
        payload.startDate = moment(selectedDate)
          .startOf("month")
          .format("YYYY-MM-DD");
        payload.endDate = moment(selectedDate)
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      const response = await api.saveDayPrice(user?.id, payload);
      onUpdate();
      setSaving(false);
    } catch (e: any) {
      setSaving(false);
      console.log("error", e?.response?.data?.message);
    }
  };
  return (
    <SideModal className="!z-[9] lg:!z-[4]" onClose={handleClose}>
      <div className="h-full lg:w-[480px] w-full relative bg-white flex flex-col">
        <div className="h-14 px-4 py-5 border-b border-[#b5b6bd] justify-between items-center flex">
          <div className="text-center text-[#161938] text-base font-semibold">
            Daily Rate Settings
          </div>
          <div className="justify-end items-center gap-3 flex">
            <div className="text-center text-[#646466] text-[13px] font-medium leading-[13px]">
              {moment(selectedDate).format("MMM DD, YYYY")}
            </div>
            <button
              type="button"
              onClick={handleClose}
              className="w-4 h-4 p-[0.22px] justify-center items-center flex"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="h-[calc(100dvh-140px)] overflow-auto grow-[1]">
          <table className="w-full relative" cellSpacing={12}>
            <colgroup>
              <col style={{ width: "55%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead className="w-full">
              <tr className="text-black bg-neutral-300 [&_td]:text-2xs [&_td]:font-semibold [&_td]:py-2.5 ">
                <td className="pl-4">Constraints</td>
                <td className="text-center">Current</td>
                <td className="text-center whitespace-nowrap">New</td>
              </tr>
            </thead>
            <tbody>
              <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-2  [&_td]:border-b [&_td]:border-b-neutral-300">
                <td className="tracking-[-0.44px] pl-4">Rate Floor</td>
                <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                  ${data?.rateFloor}
                </td>
                <td className="tracking-[-0.44px]">
                  <div className="flex justify-center w-full">
                    <PriceInput
                      onChange={(value) => {
                        setForm((prev) => ({
                          ...prev,
                          rateFloor: value,
                        }));
                      }}
                      value={form?.rateFloor}
                      className="!w-[66px] !h-6"
                    />
                  </div>
                </td>
              </tr>
              <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-2  [&_td]:border-b [&_td]:border-b-neutral-300">
                <td className="tracking-[-0.44px] pl-4">Rate Ceiling</td>
                <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                  ${data?.rateCeiling}
                </td>
                <td className="tracking-[-0.44px]">
                  <div className="flex justify-center w-full">
                    <PriceInput
                      onChange={(value) => {
                        setForm((prev) => ({
                          ...prev,
                          rateCeiling: value,
                        }));
                      }}
                      value={form?.rateCeiling}
                      className="!w-[66px] !h-6"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-full mt-4 px-4 py-3 border-b border-[#b5b6bd] justify-between items-center flex">
            <div className="text-black text-[13px] font-semibold font-['Inter']">
              Price Override
            </div>
            <div className="text-right text-[#646466] text-[13px] font-normal">
              {moment(selectedDate).format("MMM DD, YYYY")}
            </div>
          </div>
          <div className="px-4 py-[7px] border-b border-[#f4f4f6] justify-between items-center flex">
            <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
              Bulk Price Edit?
            </div>
            <div className="justify-start items-center gap-3 flex">
              <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
                Single day
              </div>
              <Switch
                checked={form?.bulk}
                onChange={() =>
                  setForm((prev) => ({
                    ...prev,
                    bulk: !prev.bulk,
                  }))
                }
              />
              <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
                Bulk
              </div>
            </div>
            <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
              {moment(selectedDate).startOf("month").format("MMM DD")} -{" "}
              {moment(selectedDate).endOf("month").format("MMM DD")}
            </div>
          </div>
          <table className="w-full relative" cellSpacing={12}>
            <colgroup>
              <col style={{ width: "32%" }} />
              <col style={{ width: "24%" }} />
              <col style={{ width: "24%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead className="w-full">
              <tr className="sticky top-0 text-black bg-neutral-300 [&_td]:text-2xs [&_td]:font-semibold [&_td]:py-2.5 ">
                <td className="pl-4">Room Category</td>
                <td className="text-center">Relative Price</td>
                <td className="text-center">Current Price</td>
                <td className="text-center whitespace-nowrap">New Price</td>
              </tr>
            </thead>
            <tbody>
              {/** check for percentage */}
              <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-2  [&_td]:border-b [&_td]:border-b-neutral-500">
                <td className="tracking-[-0.44px] pl-4">
                  {data?.baseRoomTypeName} (Base Price)
                </td>
                <td className="tracking-[-0.44px] text-center">{""}</td>
                <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                  ${data?.baseRoomTypeRate}
                </td>
                <td className="tracking-[-0.44px]">
                  <div className="flex justify-center w-full">
                    <PriceInput
                      onChange={(value) => {
                        setForm((prev) => ({
                          ...prev,
                          baseRoomTypeNewPrice: value || "",
                          roomTypes: form?.roomTypes?.map((room) => ({
                            ...room,
                            newPrice: calculateNewPrice(
                              form?.relativePrice
                                ? room?.percentage
                                : room?.newRelativePrice,
                              value,
                              form?.relativePrice
                            ),
                          })),
                        }));
                      }}
                      value={
                        isNaN(Number(form?.baseRoomTypeNewPrice))
                          ? ""
                          : form?.baseRoomTypeNewPrice
                      }
                      className="!w-[66px] !h-6"
                    />
                  </div>
                </td>
              </tr>

              {form?.roomTypes?.map((room) => (
                <tr
                  key={room.name}
                  className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-2  [&_td]:border-b [&_td]:border-b-neutral-300"
                >
                  <td className="tracking-[-0.44px] pl-4">{room.name}</td>
                  <td className="tracking-[-0.44px] text-center">
                    {/* {room.percentage}% */}
                    {/** if relative price is true, show percentage, else show current price input */}
                    {form?.relativePrice ? (
                      <div className="flex justify-center w-full">
                        <PriceInput
                          isPercentage={true}
                          onChange={(value) => {
                            setForm((prev) => ({
                              ...prev,
                              roomTypes: prev.roomTypes.map((r) =>
                                r.name === room.name
                                  ? {
                                      ...r,
                                      percentage: value,
                                      newPrice: calculateNewPrice(
                                        value,
                                        form.baseRoomTypeNewPrice,
                                        form?.relativePrice
                                      ),
                                    }
                                  : r
                              ),
                            }));
                          }}
                          value={`${room.percentage}`}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center w-full">
                        <PriceInput
                          onChange={(value) => {
                            setForm((prev) => ({
                              ...prev,
                              roomTypes: prev.roomTypes.map((r) =>
                                r.name === room.name
                                  ? {
                                      ...r,
                                      newRelativePrice: value,
                                      newPrice:
                                        calculateNewPrice(
                                          value,
                                          form.baseRoomTypeNewPrice,
                                          form?.relativePrice
                                        ) || "",
                                    }
                                  : r
                              ),
                            }));
                          }}
                          value={room.newRelativePrice}
                        />
                      </div>
                    )}
                  </td>
                  <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                    {commaSeparatedNumber(room.rate || 0, false)}
                  </td>
                  <td className="tracking-[-0.44px]">
                    <div className="flex justify-center w-full">
                      <PriceInput
                        onChange={(value) => {
                          setForm((prev) => {
                            const roomTypes = prev.roomTypes.map((r) => {
                              if (r.name === room.name) {
                                return {
                                  ...r,
                                  newPrice: value || "",
                                };
                              }
                              return r;
                            });
                            return {
                              ...prev,
                              roomTypes,
                            };
                          });
                        }}
                        value={room.newPrice || ""}
                        className="!w-[66px] !h-6"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="w-full px-4 py-[7px] border-y border-[#b5b6bd] justify-between items-center flex">
            <div className="text-right text-black text-[13px] font-medium leading-[13px]">
              Change by?
            </div>
            <div className="px-4 justify-start items-center gap-3 flex">
              <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
                Relative price
              </div>
              <Switch
                checked={!form?.relativePrice}
                onChange={() => {
                  if (!form?.relativePrice) {
                    const price =
                      form?.baseRoomTypeNewPrice ||
                      `${dayPrice?.suggestedPrice}`;
                    setForm((prev) => ({
                      ...prev,
                      baseRoomTypeNewPrice: price,
                      relativePrice: !prev.relativePrice,
                      roomTypes: form?.roomTypes?.map((room) => ({
                        ...room,
                        newRelativePrice: "",
                        newPrice: `${(
                          Number(price || 0) +
                          (Number(room?.percentage) / 100) * Number(price || 0)
                        ).toFixed(0)}`,
                      })),
                    }));
                  } else {
                    setForm((prev) => ({
                      ...prev,
                      relativePrice: !prev.relativePrice,
                      roomTypes: form?.roomTypes?.map((room) => {
                        const originalData = data?.roomTypes?.find(
                          (r) => r.name === room.name
                        );
                        return {
                          ...room,
                          percentage: originalData?.percentage || 0,
                          newPrice: Number(prev.baseRoomTypeNewPrice) || 0,
                        };
                      }),
                    }));
                  }
                }}
              />
              <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
                New Price
              </div>
            </div>
          </div>
          <div className="p-4 text-[#161938] text-2xs font-normal leading-[11px]">
            Note: Over-riding recommended price will disable dynamic pricing.
          </div>
        </div>
        <div className="p-4 flex items-center gap-3 border-t border-t-neutral-400">
          <Button
            variant="outline"
            className="w-full"
            disabled={saving}
            onClick={() => setShowConfirmation(true)}
          >
            Reset All
          </Button>
          <Button className="w-full" onClick={handleSave} loading={saving}>
            Save
          </Button>
        </div>
      </div>
      {showConfirmation && (
        <ConfirmResetModal
          onClose={() => setShowConfirmation(false)}
          userId={user?.id}
          refetch={onUpdate}
          dates={
            form?.bulk
              ? {
                  startDate: moment(selectedDate)
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                  endDate: moment(selectedDate)
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                }
              : {
                  startDate: selectedDate || "",
                }
          }
        />
      )}
    </SideModal>
  );
}
