import moment from "moment";
import { getUser } from "../../../helpers/utils";
import { useState } from "react";
import { api } from "../../../helpers/api";
import UIPopover from "../../../components/Popover";
import { DailySummary } from "../../dashboard/components/DailySummaryView";
import useResponsive from "../../../helpers/useResponsive";

const typeStyles = {
  specialEvent: "border !border-[#B2EAFE] !bg-[#F2FBFF] !text-[#0FBEFA]",
  priceOverride: "border !border-[#FFC0DF] !bg-[#FFEFF7] !text-[#FF4FA6]",
};

const getStatusType = (data, day) => {
  const cellDate = moment(day.date);
  const hasEvent = data?.find((event) => {
    const eventDate = moment(new Date(event.date));
    return eventDate.isSame(cellDate, "day");
  });
  const type = hasEvent?.isPriceOverride
    ? "priceOverride"
    : hasEvent?.isSpecialEvent
    ? "specialEvent"
    : "";
  return type;
};

const DayView = ({ day, onClick, selectedDate, type = "" }) => {
  const { isMobile, isMobileLandScape } = useResponsive();
  // onClick of day it will open popover with relative position to the day
  // create a popover component and pass the buttonProps to it
  const user = getUser();
  const userId = user?.id;
  const [selected, setSelected] = useState<Date | undefined>();
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const isMobileView = isMobile || isMobileLandScape;

  const handleDayClick = async (day: Date) => {
    try {
      setIsLoading(true);
      setSelected(day);
      const dayMetrics = await api.getDayMetrics(userId, {
        type: "Summary",
        date: moment(day).format("YYYY-MM-DD"),
      });
      setData(dayMetrics);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (isMobileView || onClick) {
    const isSelected = moment(selectedDate).isSame(moment(day.date));
    return (
      <button
        onClick={() => (onClick ? onClick(day.date) : handleDayClick(day.date))}
        className="w-[14.28%] h-16 md:h-[50px] flex items-center justify-center relative"
      >
        <div
          key={day.date}
          // className={`w-[14.28%] h-[50px] flex items-center justify-center`}
          className={`text-2xs text-neutral-700 font-medium leading-[19px] w-7 h-7 rounded flex items-center justify-center ${
            isSelected ? "bg-black text-white" : `${typeStyles?.[type] || ""}`
          }`}
        >
          {day.isCurrentMonth ? moment(day.date).format("D") : ""}
        </div>
        {selected && (
          <DailySummary
            date={day.date}
            data={data}
            isLoading={isLoading}
            onClose={() => setSelected(undefined)}
          />
        )}
      </button>
    );
  }
  const isSelected = moment(selectedDate).isSame(moment(day.date));

  return (
    <UIPopover
      onClose={() => setSelected(undefined)}
      popoverButtonClassName="w-[14.28%] h-[50px] flex items-center justify-center"
      trigger={
        <div
          key={day.date}
          // className={`w-[14.28%] h-[50px] flex items-center justify-center`}
          className={`text-2xs text-neutral-700 font-medium leading-[19px] w-7 h-7 rounded flex items-center justify-center ${
            isSelected ? "bg-black text-white" : `${typeStyles?.[type] || ""}`
          }`}
        >
          {day.isCurrentMonth ? moment(day.date).format("D") : ""}
        </div>
      }
      onClick={() => handleDayClick(day.date)}
      shouldCloseOnScroll={false}
      align="start"
      transform={{ left: -20 }}
      transformMode="relative"
    >
      {(close) => (
        <DailySummary date={day.date} data={data} isLoading={isLoading} />
      )}
    </UIPopover>
  );
};

export function Calendar({
  year,
  month,
  onClick,
  selectedDate,
  calendarData,
}: {
  year: number;
  month: number;
  onClick?: (date: string) => void;
  selectedDate?: string | null;
  calendarData?: any[];
}) {
  const calendarDayList: any[] = [];
  const firstDayOfMonth = moment(`${year}-${month}`, "YYYY-MM").startOf(
    "month"
  );
  const lastDayOfMonth = moment(`${year}-${month}`, "YYYY-MM").endOf("month");
  const startDayOfMonth = firstDayOfMonth.startOf("week");
  const endDayOfMonth = lastDayOfMonth.endOf("week");
  const currentDay = startDayOfMonth;
  while (currentDay <= endDayOfMonth) {
    calendarDayList.push({
      date: currentDay.format("YYYY-MM-DD"),
      isCurrentMonth: currentDay.month() === month - 1,
      dayIndex: currentDay.day(),
    });
    currentDay.add(1, "day");
  }

  // create calendar view with
  // header: Su Mo Tu We Th Fr Sa
  // dates:  1  2  3  4  5  6  7
  return (
    <div className="h-full">
      <div className="flex flex-wrap h-full">
        <div className="flex w-full justify-between [&>div]:w-[14.28%] [&>div]:h-16 [&>div]:md:h-[50px] [&>div]:flex [&>div]:items-center [&>div]:justify-center [&>div]:text-2xs [&>div]:text-black [&>div]:font-medium [&>div]:leading-[19px]">
          <div>Su</div>
          <div>Mo</div>
          <div>Tu</div>
          <div>We</div>
          <div>Th</div>
          <div>Fr</div>
          <div>Sa</div>
        </div>
        {calendarDayList.map((day) => (
          <DayView
            key={day.date}
            day={day}
            onClick={onClick}
            selectedDate={selectedDate}
            type={getStatusType(calendarData, day)}
          />
        ))}
      </div>
    </div>
  );
}
