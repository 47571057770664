export const gradientColors = [
  {
    id: 1,
    stroke: "#B7D3FF",
    stop1: "#D9E7FF",
    stop2: "rgba(236, 243, 255, 0.00)",
    bg: "rgba(236, 243, 255, 1)",
    textStyle: "text-[#3984FF]",
  },
  {
    id: 2,
    stroke: "#9DEEBE",
    stop1: "#D0F5E2",
    stop2: "rgba(237,249,242,0.00)",
    bg: "rgba(237, 249, 242, 1)",
    textStyle: "text-[#05C168]",
  },
  {
    id: 3,
    stroke: "rgba(182, 182, 189, 1)",
    stop1: "#EBEBED",
    stop2: "rgba(244, 244, 246, 0.00)",
    bg: "rgba(244, 244, 246, 1)",
    textStyle: "text-[#525254]",
  },
  {
    id: 4,
    stroke: "#FFC0DF",
    stop1: "#FFE0F0",
    stop2: "rgba(255, 239, 247, 0.00)",
    bg: "rgba(255, 239, 247, 1)",
    textStyle: "text-pink-300",
  },
];
