import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GOOGLE_OAUTH_CLIENT_ID } from "./constant";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Routes from "./pages/routes";
import { AppProvider } from "./helpers/app-context";

// Create a client
const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    return () => {
      const staySignedIn = localStorage.getItem("staySignedIn");
      if (staySignedIn !== "true") {
        localStorage.clear();
      }
    };
  }, []);
  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <div className="flex flex-col h-[100vh] bg-[#F9FAFC]">
            <Routes />
          </div>
        </AppProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
