import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";
import { Panel } from "../../../components/Panel";
import moment from "moment";
import useResponsive from "../../../helpers/useResponsive";
import { YearFilter } from "../../dashboard/components/YearFilter";
import { api } from "../../../helpers/api";
import {
  commaSeparatedNumber,
  formatNumber,
  getUser,
} from "../../../helpers/utils";
import { FilterSelect } from "./GuestReviewChart/components/FilterSelect";
import { months } from "../../dashboard/components/RevenueSummary/utils";
import { ConnectGoogleAnalytics } from "./ConnectGoogleAnalytics";
import Spinner from "../../../components/Spinners";

const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

const CustomCursor = (props) => {
  const { x, y, width, height } = props;
  // make width 25px
  const newWidth = 25;
  // set x in center
  const remaining = (width - (newWidth + 1)) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomBarTooltip = ({ active, payload, label, field }: any) => {
  const data = payload?.[0]?.payload;
  return (
    <div className="p-2.5 bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#f4f4f6] justify-start items-start gap-3 flex-col flex">
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className="w-3 h-3 bg-black rounded-full" />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch capitalize text-[#8b8b91] text-2xs font-normal font-['Inter']">
            {data?.name}
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {commaSeparatedNumber(data?.count, false)}
          </div>
        </div>
      </div>
    </div>
  );
};

const transformData = (data, lastYearData, type) => {
  const getLastYearData = (month, lastYearData) => {
    const data = lastYearData.find((item) => item.month === month);
    return data?.value;
  };
  switch (type) {
    case "WebsiteTraffic":
      return data?.map((item) => {
        return {
          name: months[item.month],
          count: item.value,
          lastYearCount: getLastYearData(item.month, lastYearData),
          year: item.year,
        };
      });
    case "VisitsByDevice":
      return data?.map((item) => {
        return {
          name: item.device,
          count: item.count,
        };
      });
    case "RevenueByChannel":
      return Object.keys(data).map((key) => {
        return {
          name: key,
          count: data[key],
        };
      });
    case "ReferralSources":
      return Array.isArray(data)
        ? data?.map((item) => {
            return {
              name: item.device,
              count: item.count,
            };
          })
        : [];
    default:
      return data;
  }
};

const CustomTooltip = ({ active, payload, label, field }: any) => {
  const data = payload?.[0]?.payload;
  return (
    <div className="p-2.5 min-w-[100px] bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#f4f4f6] justify-start items-start gap-3 flex-col flex">
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className="w-3 h-3 bg-black rounded-full" />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
            {data?.year}
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {commaSeparatedNumber(data?.count, false)}
          </div>
        </div>
      </div>
      <div className="justify-start items-start gap-1.5 flex">
        <div className="py-px justify-start items-center gap-2.5 flex">
          <div className="w-3 h-3 bg-[#E9E9EB] rounded-full" />
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch text-[#8b8b91] text-xs font-normal font-['Inter']">
            {Number(data?.year) - 1}
          </div>
          <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
            {commaSeparatedNumber(data?.lastYearCount, false)}
          </div>
        </div>
      </div>
    </div>
  );
};

export function MarketingSummary() {
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const isGoogleAnalyticsEnabled = !!user?.googleAnalyticsPropertyId;
  const { isTall2XLDesktop } = useResponsive();
  const [data, setData] = useState<any>([]);
  const [lastYearData, setLastYearData] = useState<any>({});
  const [filter, setFilter] = useState<any>({
    ...getDates(),
    type: "WebsiteTraffic",
  });

  const fetchMetrics = async () => {
    setIsLoading(true);
    try {
      const userId = user?.id;
      const filters = {
        type: filter.type,
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      const lastYearMetrics = await api.getMetrics(userId, {
        ...filters,
        startDate: moment(filter.startDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
        endDate: moment(filter.endDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
      });
      setLastYearData(lastYearMetrics || []);
      setData(metrics || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const showLineChart = filter.type === "WebsiteTraffic";
  const transformedData = transformData(data, lastYearData, filter.type);

  return (
    <Panel
      label="Marketing Summary"
      actions={
        <div
          className="flex items-center gap-1.5"
          style={!isGoogleAnalyticsEnabled ? { filter: "blur(16px)" } : {}}
        >
          <div className="flex items-center gap-3 mr-2">
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded-full bg-black" />
              <div className="text-xs font-medium text-neutral-600">
                {filter.year}
              </div>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded-full bg-[#E9E9EB]" />
              <div className="text-xs font-medium text-neutral-600">
                {filter.year - 1}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <FilterSelect filter={filter} setFilter={setFilter} />
            <YearFilter
              filter={filter}
              setFilter={setFilter}
              disabled={false}
            />
          </div>
        </div>
      }
      className="h-full flex flex-col [&_.header]:gap-3 [&_.header]:md:gap-0 [&_.header]:items-start [&_.header]:md:items-center [&_.header]:flex-col [&_.header]:md:flex-row"
      contentClassName="h-full"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[300px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="min-w-[218px] min-h-[300px] relative py-3 pl-2 grow-[1] flex justify-center items-center h-full">
          {transformedData?.length === 0 && (
            <div className="absolute top-0 text-neutral-700 text-sm font-medium left-0 flex justify-center text-center flex-1 items-center w-full h-full">
              No data available
            </div>
          )}
          <ResponsiveContainer
            style={!isGoogleAnalyticsEnabled ? { filter: "blur(16px)" } : {}}
          >
            {showLineChart ? (
              <LineChart
                width={500}
                height={300}
                data={transformedData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  interval="preserveStartEnd"
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  width={20}
                  tickFormatter={(value) => {
                    // make value to k denotes thousands
                    const val = Number(value);
                    // If zero then show 0 without dollar sign
                    if (val === 0) {
                      return "0";
                    }
                    if (val > 1000) {
                      return `${formatNumber(Number(val || 0), "$")}`;
                    }
                    return `$${val}`;
                  }}
                />
                <Tooltip content={<CustomTooltip field={filter.type} />} />
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="rgba(204, 196, 255, 1)" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>
                <Line
                  type="monotone"
                  dot={false}
                  strokeWidth={2}
                  dataKey="count"
                  stroke="#000"
                />
                <Line
                  type="monotone"
                  dot={false}
                  strokeWidth={2}
                  dataKey="lastYearCount"
                  stroke="#E9E9EB"
                />
              </LineChart>
            ) : (
              <BarChart
                // width={500}
                // height={300}
                data={transformedData || []}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  className="!text-3xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    return value;
                  }}
                  interval={0}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={30}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    // make value to k denotes thousands
                    const val = Number(value);
                    // If zero then show 0 without dollar sign
                    if (val === 0) {
                      return "0";
                    }
                    if (val > 1000) {
                      return `${formatNumber(Number(val || 0), "$")}`;
                    } else {
                      return `$${val}`;
                    }
                  }}
                />
                <Tooltip
                  position={{ y: 0 }}
                  content={<CustomBarTooltip />}
                  cursor={transformedData?.length > 0 && <CustomCursor />}
                />
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="rgba(204, 196, 255, 1)" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>
                <Bar
                  dataKey="count"
                  fill="#000"
                  barSize={18}
                  radius={[2, 2, 0, 0]}
                />
              </BarChart>
            )}
          </ResponsiveContainer>
          {!isGoogleAnalyticsEnabled && <ConnectGoogleAnalytics />}
        </div>
      )}
    </Panel>
  );
}
