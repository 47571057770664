import { ReactComponent as Impression } from "../../../assets/icons/funnel/impression.svg";
import { ReactComponent as HomePageVisit } from "../../../assets/icons/funnel/home-page-visit.svg";
import { ReactComponent as SearchResultPage } from "../../../assets/icons/funnel/search-result-page.svg";
import { ReactComponent as CheckoutPage } from "../../../assets/icons/funnel/checkout-page.svg";
import { ReactComponent as PaymentPage } from "../../../assets/icons/funnel/payment.svg";
import { Panel } from "../../../components/Panel";
import { YearFilter } from "../../dashboard/components/YearFilter";
import { useEffect, useState } from "react";
import moment from "moment";
import { ColorChip } from "../../../components/ColorChip";
import {
  commaSeparatedNumber,
  getPercentage,
  getUser,
} from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import Trapezoid from "./Trapezoid";
import useResponsive from "../../../helpers/useResponsive";
import { ConnectGoogleAnalytics } from "./ConnectGoogleAnalytics";
import Spinner from "../../../components/Spinners";

const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

function Funnel({ height }: { height: number }) {
  // 1st trapezoid width is 224px and height is 48px
  // 2nd trapezoid width is 200px and height is 48px
  // also make height by screen size

  const increase = height / 48;
  const firstWidth = 224 * (height / 48);
  const secondWidth = 200 * (height / 48);
  const thirdWidth = 176 * (height / 48);
  const fourthWidth = 160 * (height / 48);
  const fifthWidth = 144 * (height / 48);

  return (
    <div className="flex flex-col items-center mt-10">
      <Trapezoid
        border="bg-[#E9E9EB]"
        background="bg-[#F9FAFC]"
        text="text-[#646466]"
        title="Impressions"
        width={firstWidth}
        height={height}
        clipPath="[clip-path:polygon(0_0,_100%_0,_94.5%_100%,_5.5%_100%)]"
      />
      <Trapezoid
        border="bg-[#B2EAFE]"
        background="bg-[#F2FBFF]"
        text="text-[#0FBEFA]"
        title="Home Page Visits"
        width={secondWidth}
        height={height}
        clipPath="[clip-path:polygon(0_0,_100%_0,_94%_100%,_6%_100%)]"
      />
      <Trapezoid
        border="bg-[#B7D3FF]"
        background="bg-[#ECF3FF]"
        text="text-[#3984FF]"
        title="Search Results Page"
        width={thirdWidth}
        height={height}
        clipPath="[clip-path:polygon(0_0,_100%_0,_93.5%_100%,_6.5%_100%)]"
      />
      <Trapezoid
        border="bg-[#CBC8FF]"
        background="bg-[#F6F5FF]"
        text="text-[#563AFF]"
        title="Check-Out Page"
        width={fourthWidth - 7 * increase}
        height={height}
        clipPath="[clip-path:polygon(0_0,_100%_0,_92%_100%,_8%_100%)]"
      />
      <Trapezoid
        border="bg-[#E3C8FF]"
        background="bg-[#FAF5FF]"
        text="text-[#9C3AFF]"
        title="Payment"
        width={fifthWidth - 15 * increase}
        height={height}
        clipPath="[clip-path:polygon(0_0,_100%_0,_91%_100%,_9%_100%)]"
      />
    </div>
  );
}

function Table({ data, height }: { data: any; height: number }) {
  return (
    <table className="w-full relative" cellSpacing={12}>
      <colgroup>
        <col style={{ width: "30%" }} />
        <col style={{ width: "35%" }} />
        <col style={{ width: "35%" }} />
      </colgroup>
      <thead className="w-full">
        <tr
          style={{ height: (height / 4) * 3 }}
          className="sticky top-0 text-neutral-800 bg-neutral-300 [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 "
        >
          <td className="text-center"># of visits</td>
          <td className="text-center">Conversion Rate</td>
          <td className="text-center whitespace-nowrap">Vs. STLY</td>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr
            key={i}
            className="[&_td]:border-b text-[11px] [&_td]:border-neutral-300 [&_td]:py-3"
            style={{ height: height }}
          >
            <td className="text-center">
              {commaSeparatedNumber(item.visits, false, false)}
            </td>
            <td className="text-center">
              {commaSeparatedNumber(item.conversionRate, true)}
            </td>
            <td className="text-right">
              <div className="flex justify-center">
                <ColorChip
                  text={"+25% vs 2023"}
                  type="green"
                  className="whitespace-nowrap w-fit"
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function MarketingFunnel() {
  const { isTall2XLDesktop, isQHD, isTall3XLDesktop } = useResponsive();
  const height = isTall3XLDesktop
    ? 60
    : isTall2XLDesktop
    ? 48
    : isQHD
    ? 60
    : 48;
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = getUser();
  const isGoogleAnalyticsEnabled = !!user?.googleAnalyticsPropertyId;

  const [filter, setFilter] = useState<{
    startDate: string;
    endDate: string;
  }>({ ...getDates() });

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "Funnel",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const first5Rates = data?.slice(0, 5);
  return (
    <Panel
      label="Marketing Funnel"
      actions={
        <div
          className="flex items-center gap-1.5 [&_.dropdown]:z-[3]"
          style={!isGoogleAnalyticsEnabled ? { filter: "blur(16px)" } : {}}
        >
          <YearFilter disabled={false} filter={filter} setFilter={setFilter} />
        </div>
      }
      className="h-full"
      contentClassName="flex flex-col relative justify-center h-[calc(100%-50px)]"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <>
          <div
            className="p-4 flex md:flex-row flex-col items-center gap-4"
            style={!isGoogleAnalyticsEnabled ? { filter: "blur(16px)" } : {}}
          >
            <Table data={first5Rates} height={height} />
            <Funnel height={height} />
          </div>
          {!isGoogleAnalyticsEnabled && <ConnectGoogleAnalytics />}
        </>
      )}
    </Panel>
  );
}
