import moment from "moment";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type TransformedDataPoint = {
  name: string;
  onTheBooks: number;
  previous: number;
};

/**
 * Transforms the data for the revenue chart
 * @param data - The data for the current year
 * @param prevData - The data for the previous year
 * @param forecastData - The data for the forecasted year
 *
 * @returns
 * [
 *  {
 *      name: "Jan",
 *      onTheBooks: 1000,
 *      previous: 1000,
 *      forecast: 1000,
 *  }
 *  ...
 *  {
 *      name: "Dec",
 *      onTheBooks: 1000,
 *      previous: 1000,
 *      forecast: 1000,
 *  }
 * ]
 */
export const transformData = ({
  data = [],
  previousField,
  onTheBooksField,
  currentField,
  startDate,
  endDate,
  budgetField,
}: {
  data: any[];
  previousField: string;
  onTheBooksField: string;
  currentField: string;
  startDate: string;
  endDate: string;
  budgetField: string;
}): TransformedDataPoint[] => {
  return data.map((month) => {
    const currentMonth = Number(moment().format("M"));
    const currentYear = moment().format("YYYY");
    const year = moment(moment(startDate).format("YYYY"), "YYYY").isSameOrAfter(
      moment(currentYear)
    );
    const isFuture = Number(month?.month) >= currentMonth && year;

    let previous = data[previousField] || 0;
    if (!previous) {
      // 13% of current month
      const value = !isFuture
        ? Number(month[currentField] || 0)
        : Number(month[onTheBooksField] || 0);
      const newValue = value * 0.13;
      previous = !isFuture
        ? Number(month[currentField] || 0) - newValue
        : Number(month[onTheBooksField] || 0) - newValue;
    }

    return {
      name: months[Number(month?.month || 0) - 1],
      current: Number(month[currentField] || 1),
      actualCurrent: Number(month[currentField] || 0),
      onTheBooks: Number(month[onTheBooksField] || 0),
      previous,
      budget: Number(month?.budget?.[budgetField] || 0),
    };
  });
};
