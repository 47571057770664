import { useState } from "react";
import CustomInput from "../../../components/Input";
import { PriceInput } from "../../../components/PriceInput";
import { ActionButtons } from "./ActionButtons";
import { Card } from "../../../components/Card";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { PlanCard } from "./PlanCard";
import useStripeSession, {
  PlanType,
} from "../../../helpers/hooks/useStripeSession";

const plans = [
  {
    features: [
      "Dynamic pricing",
      "Paid Search",
      "Metasearch Marketing",
      "Standard Reporting",
    ],
    planName: "Lite",
    price: "999",
    type: "ST",
    planStyle:
      "text-pink-300 bg-pink-100 border-pink-200 shadow-[1px_1px_2px_0px_rgba(209,136,221,0.28)",
    description:
      "The basics of digital marketing and dynamic pricing to increase awareness and drive more direct bookings.",
  },
  {
    features: [
      "Everything on Lite plan",
      "OTA Optimization",
      "Website",
      "Booking Engine",
    ],
    price: "1,299",
    planName: "Professional",
    isRecommended: true,
    type: "PR",
    planStyle:
      "text-[#0FBEFA] bg-[#F2FBFF] border-[#B2EAFE] shadow-[1px_1px_2px_0px_rgba(173,168,255,0.28)",
    description:
      "A comprehensive all-in-one solution that automates everything, with unlimited access to our Revenue CoPilot.",
  },
  {
    price: "2,499",
    planName: "Full-Service",
    type: "MX",
    planStyle:
      "text-[#3984FF] bg-[#ECF3FF] border-[#B7D3FF] shadow-[1px_1px_2px_0px_rgba(173,168,255,0.28)",
    description:
      "All of our software plus one of our certified revenue specialists will set-up & carefully oversee everything for you.",
    features: [
      "Everything on Professional plan",
      "Custom Website",
      "Dedicated Revenue Manager",
      "Monthly Review Meetings",
    ],
  },
];

export function PlanForm({ onBack, onNext, onChange, form }) {
  const {
    handleGetStripeSession,
    stripeLoading,
    isLoading,
    activeSubscription,
  } = useStripeSession();

  const subscription =
    activeSubscription?.[0]?.status === "Paid" ? activeSubscription?.[0] : null;
  return (
    <>
      <div className="flex flex-col [&_label]:!text-neutral-700">
        <div className="text-2xl font-semibold px-8">Select your plan</div>
        <p className=" text-neutral-700 mt-2 mb-3 tall2:mb-5 text-sm leading-[22px] px-8">
          Choose the plan that’s right for your hotel. First month is free!
        </p>
        <div className="flex flex-col lg:flex-row gap-5 items-end px-8">
          {plans.map((plan, i) => (
            <PlanCard
              {...plan}
              key={i}
              onSelect={() =>
                handleGetStripeSession({ type: plan.type as PlanType })
              }
              subscription={subscription}
            />
          ))}
        </div>
      </div>
      <div className="px-8">
        <ActionButtons
          onBack={onBack}
          onNext={onNext}
          disabled={!subscription}
        />
      </div>
    </>
  );
}

export function Plan({
  onNext,
  onBack,
  step,
}: {
  onNext: () => void;
  onBack: () => void;
  step: number;
}) {
  const [form, setForm] = useState({});

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const handleChange = (data) => {
    setForm((prev) => ({ ...prev, ...data }));
  };

  return (
    <>
      <OnboardingSidebar title="Plans & pricing">
        <p className="text-xs leading-[16.8px] text-black">
          The perfect plan for every hotel.
        </p>
      </OnboardingSidebar>
      <OnboardingView step={step}>
        <Card className="lg:max-w-[985px] lg:w-[985px] px-0 !py-3 tall2:!py-5  mt-5 lg:mt-0">
          <PlanForm
            onBack={handleBack}
            onNext={handleNext}
            onChange={handleChange}
            form={form}
          />
        </Card>
      </OnboardingView>
    </>
  );
}
