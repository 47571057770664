import { calendarTypeColors, calendarTypeTitles } from "../consts";

export function TypeTag({ type }) {
  const title = calendarTypeTitles[type];
  const color = calendarTypeColors[type];
  return (
    <div
      className={`h-[29px] px-3 py-1.5 ${color} border-l-2 flex-col justify-center items-start gap-2.5 flex`}
    >
      <div className="self-stretch text-black text-2xs font-medium leading-[11px]">
        {title}
      </div>
    </div>
  );
}
