import { ReactComponent as FilledCheckboxIcon } from "../assets/icons/checkbox-filled.svg";

export function Checkbox({ onChange, checked, name, id, className = "" }) {
  return (
    <div className="flex">
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        className={`w-5 h-5 border border-[#8D8E92] hover:bg-black/10 rounded cursor-pointer ${
          checked ? "opacity-[0] !h-0 !w-0" : ""
        } ${className}`}
      />
      {checked ? (
        <button
          onClick={() => {
            onChange({ target: { name: name, value: false } });
          }}
        >
          <FilledCheckboxIcon className="checked min-w-5 min-h-5 w-5 h-5" />
        </button>
      ) : null}
    </div>
  );
}
