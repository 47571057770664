import GoogleIcon from "../../../assets/images/180aeb61ed21098ac716bca94a207f63.png";
import { ReactComponent as CloudBedsIcon } from "../../../assets/icons/cloud-beds.svg";
import { ReactComponent as ConnectArrow } from "../../../assets/icons/connect-arrow.svg";
import { ReactComponent as Success } from "../../../assets/icons/check.svg";

import { Card } from "../../../components/Card";
import { ActionButtons } from "./ActionButtons";
import { useState } from "react";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { ShowToast } from "../../../components/toast";
import CustomInput from "../../../components/Input";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";

const pmsConfig = {
  MEWS: {
    title: "MEWS",
    icon: <CloudBedsIcon />,
    signInLink: "https://app.mews.com/Commander/Home/SignIn",
    link: "https://help.mews.com/s/article/find-an-integration-s-access-token?language=en_US#find_the_access_token",
  },
  CLOUDBEDS: {
    title: "Cloudbeds",
    icon: <CloudBedsIcon className="!w-[119px] !h-6" />,
    signInLink: "https://signin.cloudbeds.com",
    link: "https://myfrontdesk.cloudbeds.com/hc/en-us/articles/360003183494-Marketplace-Apps-integrated-with-Cloudbeds-PMS",
  },
};

function Step({ children, number }) {
  return (
    <div className="justify-start items-start gap-2 flex">
      <div className="p-px justify-start items-center gap-2.5 flex">
        <div className="w-[22px] h-[22px] p-2.5 bg-[#ecf3ff] rounded-full flex-col justify-center items-center gap-2.5 flex">
          <div className="text-[#563aff] text-xs font-semibold leading-normal">
            {number}
          </div>
        </div>
      </div>
      <div className="grow shrink basis-0 text-[#525253] text-sm leading-normal">
        {children}
      </div>
    </div>
  );
}

function SuccessMews() {
  return (
    <>
      <p className="mt-3 text-neutral-700 text-sm leading-[22px]">
        Congratulations! Your PMS is now connected.
      </p>
      <div className="flex flex-col mt-7 items-center gap-3 justify-center">
        <div className="relative text-center flex justify-center items-center">
          <Success className="w-[145px] h-[145px]" />
        </div>
        <div className="text-neutral-700 text-sm leading-[19.2px]">
          Gathering data from PMS
        </div>
      </div>
      <p className="mt-7 text-neutral-700 text-sm leading-[22px]">
        We'll gather the data in the background and let you know if anything
        looks off.
      </p>
    </>
  );
}

function MewsForm({ token, setToken }) {
  return (
    <>
      <div className="flex-col justify-start items-start gap-5 flex">
        <CustomInput
          label=""
          placeholder="Enter key here"
          onChange={(e) => setToken(e.target.value)}
          value={token}
          // error={errors.validHotelWebsite}
          className="w-full mt-5"
        />
      </div>
    </>
  );
}

function LoadingMews() {
  return (
    <>
      <p className="mt-3 text-neutral-700 text-sm leading-[22px]">
        Please wait, while we stablish a direct connection with your PMS...
      </p>
      <div className="flex flex-col mt-7 items-center gap-3 justify-center">
        <div className="relative text-center flex justify-center items-center">
          <ConnectArrow className="absolute " />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
            width="146"
            height="145"
            viewBox="0 0 146 145"
            fill="none"
          >
            <path
              d="M73 -3.19093e-06C113.317 -4.95323e-06 146 32.4594 146 72.5C146 112.541 113.317 145 73 145C32.6832 145 -1.41884e-06 112.541 -3.16908e-06 72.5C-4.91931e-06 32.4594 32.6832 -1.42863e-06 73 -3.19093e-06ZM73 142.1C111.704 142.1 143.08 110.939 143.08 72.5C143.08 34.061 111.704 2.89999 73 2.89999C34.2959 2.89999 2.91999 34.061 2.91999 72.5C2.92 110.939 34.2959 142.1 73 142.1Z"
              fill="#F2F1FF"
            />
            <path
              d="M73 -3.19093e-06C87.8263 -2.54286e-06 102.301 4.48356 114.499 12.8542C126.696 21.2248 136.038 33.0856 141.282 46.8586L138.551 47.8843C133.517 34.6622 124.548 23.2758 112.839 15.24C101.129 7.20422 87.2332 2.89999 73 2.89999L73 -3.19093e-06Z"
              fill="#563AFF"
            />
          </svg>
        </div>
        <div className="text-neutral-700 text-sm leading-[19.2px]">
          Connecting...
        </div>
      </div>
      <p className="mt-7 text-neutral-700 text-sm leading-[22px]">
        This may take a few minutes; we will email you when it’s done. You can
        continue with the onboarding in the meantime.
      </p>
    </>
  );
}

export function ConnectGoogleAnalytics({
  onBack,
  onNext,
  form,
  setForm,
  step,
}) {
  const [propertyId, setPropertyId] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const user = getUser();

  const handleBack = () => {
    onBack();
  };

  const handleSkip = () => {
    onNext();
  };

  const handleNext = async () => {
    try {
      if (propertyId) {
        setSaving(true);
        const response = await api.putUserProfile(user.id, {
          googleAnalyticsPropertyId: propertyId,
        });
        setSaving(false);
        if ([200, 201].includes(response.status)) {
          onNext();
        } else {
          ShowToast({
            type: "error",
            message: "Something went wrong",
          });
        }
      }
    } catch (error: any) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };
  const pmsInfo = pmsConfig[form.pms] || {};
  return (
    <>
      <OnboardingSidebar title="Connect Google Analytics">
        <p className="text-xs leading-[16.8px] text-black mt-2">
          We need to connect to your PMS to seamlessly gather & sync real time
          information
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Connect Google Analytics"
        infoView={
          <>
            <p className="text-xs leading-[16.8px] text-black mt-2">
              We need to connect to your PMS to seamlessly gather & sync real
              time information
            </p>
          </>
        }
      >
        <Card className="lg:max-w-[670px] lg:w-[650px]">
          <div className="flex items-center">
            <span className="text-2xl font-semibold text-black">
              Connect Google Analytics
            </span>
            <img
              src={GoogleIcon}
              alt="Google Icon"
              className="w-[50px] h-[30px]"
            />
          </div>
          <>
            <p className="mt-3 text-neutral-700 text-sm leading-[22px]">
              To connect Google Analytics to Autumn, you'll need to provide the
              property ID. Follow the steps below to locate it:
            </p>
            <div className="my-7 flex flex-col gap-4">
              <Step number={1}>
                <div className="flex items-center gap-1">
                  <a
                    href={"https://analytics.google.com/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className=" text-primary whitespace-nowrap hover:underline font-medium">
                      Login into{" "}
                    </span>
                    <span>Google analytics console</span>
                  </a>
                </div>
              </Step>
              <Step number={2}>Click on Admin menu at the bottom</Step>
              <Step number={3}>
                Click on Property Details under Property menu
              </Step>
              <Step number={4}>Copy the Property Id from top right side</Step>
            </div>
            <div className="[&_.label]:!text-[#646466]">
              <CustomInput
                label="Enter Property ID"
                placeholder="Enter here"
                value={propertyId}
                onChange={(e) => setPropertyId(e.target.value)}
                className="text-sm"
              />
            </div>
          </>
          <ActionButtons
            onBack={handleBack}
            onNext={handleNext}
            disabled={saving || !propertyId}
            onSkip={handleSkip}
          />
        </Card>
      </OnboardingView>
    </>
  );
}
