import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./auth/login/Login";
import Register from "./auth/register/Register";
import { Main } from "./main";
import ResetPassword from "./auth/reset-password/ResetPassword";
import ForgotPassword from "./auth/forgot-password/ForgotPassword";
import { Onboarding } from "./onboarding/Onboarding";
import { Dashboard } from "./dashboard/Dashboard";
import { Settings } from "./settings/Settings";
import { Calendar } from "./calendar/Calendar";
import { Marketing } from "./marketing/Marketing";

const RoutesList = () => {
  return (
    <Routes>
      {/** Public routes */}
      <Route path="/">
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="register" element={<Register />} />
      </Route>
      {/** Protected routes */}
      <Route element={<Main />}>
        <Route path="onboarding" element={<Onboarding />} />
        <Route path="settings" element={<Settings />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="calendar" element={<Calendar />} />
        <Route path="marketing" element={<Marketing />} />
        <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        />
      </Route>
    </Routes>
  );
};
export default RoutesList;
