import { PieChart, Pie, Sector, Cell, Tooltip } from "recharts";
import { Panel } from "../../../components/Panel";
import { MonthSelect } from "./MonthSelect";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { YearFilter } from "./YearFilter";
import { commaSeparatedNumber, getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import useResponsive from "../../../helpers/useResponsive";
import Spinner from "../../../components/Spinners";

const getDates = (month?: string | number, year?: string | number) => {
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    month: `${currentMonth + 1}`,
    year: `${currentYear}`,
  };
};

const COLORS = [
  "#000",
  "#655CFE",
  "#B6B6BD",
  "#E9E9EB",
  "rgba(5, 193, 104, 1)",
  "rgba(15, 190, 250, 1)",
  "#FF4FA6",
];

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 2}
        outerRadius={outerRadius + 2}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, data }: any) => {
  const summaryData = payload?.[0]?.payload;
  const totalBookings = data?.bookingsCountByChannel?.reduce(
    (acc, curr) => acc + Number(curr.count || 0),
    0
  );
  const percentage = ((summaryData?.count / totalBookings) * 100).toFixed(0);
  return (
    <div className="z-[9999] p-2.5 bg-white rounded-lg shadow border border-[#f4f4f6] justify-start items-center gap-1.5 inline-flex">
      <div
        className="w-2 h-2 bg-[#563aff] rounded-lg"
        style={{
          backgroundColor: summaryData?.fill,
        }}
      />
      <div className="text-[#525253] text-xs font-medium font-['Inter']">
        {summaryData?.payload?.channel} ({percentage}%)
      </div>
    </div>
  );
};

export function SummaryChart() {
  const { is2XLDesktop, isQHD } = useResponsive();

  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [filter, setFilter] = useState<any>({ ...getDates() });
  const containerRef = useRef(null);
  const [data, setData] = useState<any>({});

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "SourceSummary",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthChange = (month: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(month, prev.year) }));
  };

  const handleYearChange = (year: string) => {
    setFilter((prev) => ({ ...prev, ...getDates(prev.month, year) }));
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setActiveIndex(undefined);
      }
    }

    // Bind the event listener
    document.addEventListener("mouseover", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseover", handleClickOutside);
    };
  }, []);

  return (
    <Panel
      label="Summary"
      actions={
        <div className="flex items-center gap-1.5">
          <MonthSelect
            disabled={false}
            value={filter.month}
            onChange={handleMonthChange}
          />
          <YearFilter
            disabled={false}
            value={filter.year}
            onChange={handleYearChange}
          />
        </div>
      }
      className="h-[310px] tall3:h-[360px] tall3XL:h-[410px] qhd1:h-[600px] flex flex-col tall3XL:[&_.header]:min-h-[50px]"
      contentClassName="h-full"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="grow-[1] flex flex-col h-full" ref={containerRef}>
          <div className="text-left w-full px-3 !pl-4 pt-1 text-[#8b8b91] text-2xs font-medium">
            You saved{" "}
            <span
              className={`font-bold ${
                data?.otaCommissionSaved < 0
                  ? "text-[#3984FF]"
                  : "text-[#05c168]"
              }`}
            >
              {commaSeparatedNumber(data?.otaCommissionSaved || 0, false)}
            </span>{" "}
            in OTA comms. vs. last year
          </div>
          <div className="relative w-full px-5 pb-4 h-full items-center flex justify-center">
            <div className="pointer-events-none absolute top-0 left-0 right-0 bottom-0 flex-col justify-center items-center gap-[5px] flex">
              <div className="text-center text-black text-xl font-extrabold leading-none">
                {commaSeparatedNumber(
                  Number(data?.directBookingsPercentage || 0),
                  true
                )}
              </div>
              <div className="text-[#8b8b91] text-xs font-medium">
                Direct Bookings
              </div>
              <div
                className={`px-[5px] pt-1 pb-[5px] text-right text-2xs leading-[11px] tracking-[-0.66px] rounded-[3px] shadow border justify-center items-center flex ${
                  data?.directBookingsPercentageChange < 0
                    ? "text-[#3984FF] border-[#B7D3FF] bg-[#ECF3FF]"
                    : "text-[#05c168] border-[#9deebd] bg-[#edf9f2]"
                }`}
              >
                {(data?.otaBookingsComparePercentage || 0) < 0
                  ? "-"
                  : (data?.otaBookingsComparePercentage || 0) > 0
                  ? "+"
                  : ""}
                {data?.directBookingsPercentageChange || 0}% vs STLY
              </div>
            </div>
            <PieChart
              width={isQHD ? 500 : is2XLDesktop ? 275 : 200}
              height={isQHD ? 500 : is2XLDesktop ? 275 : 200}
            >
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data?.bookingsCountByChannel || []}
                cx={isQHD ? 250 : is2XLDesktop ? 135 : 95}
                cy={isQHD ? 250 : is2XLDesktop ? 135 : 100}
                innerRadius={isQHD ? 150 : is2XLDesktop ? 90 : 60}
                outerRadius={isQHD ? 180 : is2XLDesktop ? 115 : 75}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="count"
                //   startAngle={360}
                //   endAngle={0}
                direction="anticlockwise"
                onMouseEnter={(_, index) => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(undefined)}
              >
                {data?.bookingsCountByChannel?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    strokeWidth={0}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip data={data} />} />
            </PieChart>
          </div>
        </div>
      )}
    </Panel>
  );
}
