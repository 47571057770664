import { useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { useApp } from "../../helpers/app-context";
import { getUser } from "../../helpers/utils";
import { Calendar } from "../calendar/components/Calendar";
import { CalendarView } from "./components/CalendarView";
import { DetailedRevenueSummary } from "./components/DetailedRevenueSummary";
import { Notification } from "./components/Notification";
import { PickupReportChart } from "./components/PickupReport/PickupReportChart";
import { RevParSummary } from "./components/RevParSummary";
import { RevenueChart } from "./components/RevenueSummary/RevenueChart";
import { SummaryChart } from "./components/SummaryChart";
import { TrendsLineChart } from "./components/TrendsLineChart/TrendsLineChart";
import { api } from "../../helpers/api";

export function Dashboard() {
  const user = getUser();
  const { isChatBotOpen } = useApp() as { isChatBotOpen: boolean };
  const [onboardingData, setOnboardingData] = useState<any>(null);

  const fetchOnboardingData = async (userId: string) => {
    const onboardingResponse = await api.getOnboarding(userId);
    const { userMetadata, userPms } = onboardingResponse;
    const otas = userMetadata?.otas?.filter((ota: any) => ota !== "[]");
    setOnboardingData(
      {
        ...userMetadata,
        seasons: userMetadata?.seasons?.map((season, index) => ({
          ...season,
          id: season.id || index + 4,
        })),
        userPms,
        otas,
      } || {}
    );
  };

  useEffect(() => {
    if (user?.id) {
      fetchOnboardingData(user?.id);
    }
  }, [user?.id]);

  console.log(onboardingData, "onboardingData");
  return (
    <Page>
      <div
        className={`py-5 h-[calc(100vh-70px)] overflow-auto lg:h-[100dvh] flex-1 ${
          isChatBotOpen ? "w-[calc(100vw-350px)]" : ""
        } 1xl:w-[unset]`}
      >
        <div className="px-5 flex flex-col 1xl:flex-row 1xl:items-center justify-between gap-0 flex-wrap">
          <div
            className={`flex flex-col gap-1 flex-1 mb-[15px] lg:mb-0 ${
              !isChatBotOpen ? "xl:flex-[3]" : ""
            }`}
          >
            <h1 className="text-2xl font-semibold text-black">
              Good day, {user.firstName}
            </h1>
            <div className="text-sm text-neutral-600 font-medium leading-[22.4px]">
              {onboardingData?.hotelName} is looking good.
              {/* <span className="text-primary">on track</span> to hit your revenue
              goals this month & next month. */}
            </div>
          </div>
          <div
            className={`flex items-center 1xl:min-w-[600px] justify-between 1xl:justify-end gap-3 flex-1 ${
              !isChatBotOpen ? "xl:flex-[2]" : ""
            }`}
          >
            <RevParSummary />
            <div className="hidden lg:block">
              <Notification />
            </div>
          </div>
        </div>
        <div className="hidden 1xl:block mt-4 tall3XL:mt-8 tall3XL:h-[calc(100vh-140px)]">
          <div className="px-5 pb-1 flex items-stretch gap-4  3xl:gap-6 qhd1:!gap-[2rem] tall3XL:h-full">
            <div className="flex flex-col gap-4  3xl:gap-6 qhd1:!gap-[2rem] flex-[3]">
              <div
                className={`flex-[3] 3xl:flex-[4]  max-h-[310px] tall3:max-h-[360px] tall3XL:max-h-[410px] qhd1:max-h-[600px] ${
                  !isChatBotOpen ? "tall3:flex-[4]" : ""
                }`}
              >
                <RevenueChart />
              </div>
              <div className="flex-[4]  max-h-[401px] tall3XL:max-h-[501px] qhd1:max-h-[601px]">
                <DetailedRevenueSummary />
              </div>
            </div>
            <div className="flex flex-col gap-4  3xl:gap-6 qhd1:!gap-[2rem] flex-1 qhd1:flex-[1.5]">
              <div
                className={`flex-[3] 3xl:flex-[4]   max-h-[310px] tall3:max-h-[360px] tall3XL:max-h-[410px] qhd1:max-h-[600px] ${
                  !isChatBotOpen ? "tall3:flex-[4]" : ""
                }`}
              >
                <TrendsLineChart />
              </div>
              <div className="flex-[4]  max-h-[401px] tall3XL:max-h-[501px] qhd1:max-h-[601px]">
                <PickupReportChart />
              </div>
            </div>
            <div className="flex flex-col gap-4  3xl:gap-6 qhd1:!gap-[2rem] flex-1 qhd1:flex-[1.5]">
              <div
                className={`flex-[3] 3xl:flex-[4]  max-h-[310px] tall3:max-h-[360px] tall3XL:max-h-[410px] qhd1:max-h-[600px] ${
                  !isChatBotOpen ? "tall3:flex-[4]" : ""
                }`}
              >
                <SummaryChart />
              </div>
              <div className="flex-[4]  max-h-[401px] tall3XL:max-h-[501px] qhd1:max-h-[601px]">
                <CalendarView />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "hidden md:hidden 1xl:hidden h-[calc(100vh-150px)] overflow-auto lg:flex flex-col gap-4"
          }
        >
          <div className="px-5 pb-1 flex items-stretch mt-4 gap-4">
            <div className="flex-1">
              <RevenueChart />
            </div>
            <TrendsLineChart />
          </div>
          <div className="px-5 pb-1 flex items-stretch mt-4 gap-4">
            <div className="flex-1">
              <DetailedRevenueSummary />
            </div>
          </div>
          <div className="px-5 pb-1 flex items-stretch mt-4 gap-4">
            <div className="flex-1">
              <PickupReportChart />
            </div>
            <div className="flex-1">
              <SummaryChart />
            </div>
            <div className="flex-1">
              <CalendarView />
            </div>
          </div>
        </div>
        <div className={"px-5 lg:hidden flex flex-col gap-4"}>
          <div className="flex-1 mt-4">
            <RevenueChart />
          </div>
          <div className="pb-1 flex md:flex-row flex-col items-stretch gap-4">
            <div className="flex-1">
              <TrendsLineChart />
            </div>
            <div className="flex-1">
              <SummaryChart />
            </div>
          </div>
          <div className="flex-1">
            <DetailedRevenueSummary />
          </div>
          <div className="pb-1 flex md:flex-row flex-col items-stretch gap-4">
            <div className="flex-1">
              <PickupReportChart />
            </div>
            <div className="flex-1">
              <CalendarView />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
