import { useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as CheckMarkIcon } from "../../../assets/icons/check-mark.svg";
import { gradientColors } from "./constant";

export function SeasonSelect({ season, onSeasonChange, list }) {
  const [showFilter, setShowFilter] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedType = list.find((type) => type.value === season?.id);
  const index = list.findIndex((type) => type.value === season?.id);
  const colorIndex = index % 4;
  const color = gradientColors[colorIndex];
  return (
    <div ref={containerRef} className="relative">
      <button
        type="button"
        onClick={() => setShowFilter((p) => !p)}
        className={`justify-start !h-6 items-center gap-1 flex p-1.5 rounded border border-[#E9E9EB]`}
        style={{
          borderColor: color?.stroke,
          backgroundColor: color?.bg,
        }}
      >
        <div className="flex-1 overflow-hidden text-ellipsis text-center text-[#525254] text-xs font-medium">
          {selectedType?.label}
        </div>
        <ChevronDownIcon
          className={`w-3 h-3 ${
            showFilter
              ? "rotate-180 pb-0.5 [&_path]:stroke-black"
              : "pt-0.5 [&_path]:stroke-neutral-600"
          }`}
        />
      </button>
      {showFilter && (
        <div className="absolute z-[1] left-0 mt-1 p-1 bg-white rounded shadow border border-[#e8e8ea] flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="self-stretch flex-col justify-start items-start flex">
            {list.map((type) => (
              <button
                onClick={() =>
                  season?.id !== type.value && onSeasonChange(type.value)
                }
                type="button"
                key={type.value}
                className="self-stretch hover:bg-[#f2f1ff] h-[25px] p-2 rounded-sm justify-between items-center gap-2 flex"
              >
                <div className="text-[#161938] whitespace-nowrap text-xs font-medium leading-3">
                  {type.label}
                </div>
                {season?.id === type.value && (
                  <CheckMarkIcon className="[&_path]:stroke-primary w-3 h-3" />
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
