import Button from "../../../components/Button";
import { Card } from "../../../components/Card";
import { ColorChip } from "../../../components/ColorChip";
import { Panel } from "../../../components/Panel";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { PriceExplanation } from "./PriceExplanation";
import { useState } from "react";
import moment from "moment";
import { commaSeparatedNumber } from "../../../helpers/utils";
import Spinner from "../../../components/Spinners";

export function Summary({
  selectedDate,
  onOpenPriceAdjustmentModal,
  data,
  dayPrice,
  isLoading,
}: {
  selectedDate?: string;
  onOpenPriceAdjustmentModal: () => void;
  data: any;
  dayPrice: any;
  isLoading: boolean;
}) {
  const [showPriceExplanation, setShowPriceExplanation] = useState(false);

  const price = dayPrice?.suggestedPrice || 0;
  const avgPrice = (data?.competitorPrices || [])?.reduce(
    (total, curr) => total + Number(curr?.rate || 0),
    0
  );
  const percentage = (price / avgPrice) * 100;

  return (
    <Panel
      label="Channel Summary"
      actions={
        <div className="text-neutral-600 text-2xs font-medium">
          {moment(selectedDate).format("MMM D, YYYY")}
        </div>
      }
      className="relative h-full w-full md:min-w-[320px] md:max-w-[320px] 1xl:min-w-[420px] 1xl:max-w-[420px] [&_.header>div]:!text-base"
    >
      {isLoading ? (
        <div className="h-[calc(100dvh-150px)] flex justify-center items-center">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <>
          <div>
            <div className="px-2 py-2.5 bg-[#F6F5FF] text-center text-[#655cfe] text-2xs font-medium border-b justify-center items-center flex">
              We recommend leaving pricing as-is for this date.
            </div>
            <div className="h-10 p-3 justify-start items-center gap-2.5 flex">
              <div className="text-black text-[13px] font-semibold">
                Performance Summary
              </div>
            </div>
            <div className="h-[calc(100dvh-346px)] overflow-auto">
              <table className="w-full relative" cellSpacing={12}>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                </colgroup>
                <thead className="w-full">
                  <tr className="sticky top-0 text-neutral-800 bg-neutral-300 [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 ">
                    <td></td>
                    <td className="text-center">Actual</td>
                    <td className="text-center">Budget</td>
                    <td className="text-center whitespace-nowrap">Var. ($)</td>
                    <td className="text-center whitespace-nowrap">Var. (%)</td>
                  </tr>
                </thead>
                <tbody>
                  {/** check for percentage */}
                  <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-3.5  [&_td]:border-b [&_td]:border-b-neutral-300">
                    <td className="tracking-[-0.44px] pl-1.5">
                      Avg. Days before Arrival
                    </td>
                    <td className="tracking-[-0.44px] text-center">
                      {commaSeparatedNumber(
                        data?.avgLengthArrival,
                        false,
                        false
                      )}
                    </td>
                    <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                      -
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[52px]"
                          text={`-`}
                          type="blue"
                        />
                      </div>
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[38px]"
                          text={`-`}
                          type="blue"
                        />
                      </div>
                    </td>
                  </tr>

                  <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-3.5  [&_td]:border-b [&_td]:border-b-neutral-300">
                    <td className="tracking-[-0.44px] pl-1.5">Rooms Sold</td>
                    <td className="tracking-[-0.44px] text-center">
                      {commaSeparatedNumber(
                        data?.actualRoomsSold,
                        false,
                        false
                      )}
                    </td>
                    <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                      {commaSeparatedNumber(
                        data?.soldRoomsBudget,
                        false,
                        false
                      )}
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[52px]"
                          text={commaSeparatedNumber(
                            data?.varSoldRooms,
                            false,
                            false
                          )}
                          type={data?.varSoldRooms < 0 ? "purple" : "green"}
                        />
                      </div>
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[38px]"
                          text={`${commaSeparatedNumber(
                            data?.varSoldRoomsPercentage,
                            true
                          )}`}
                          type={
                            data?.varSoldRoomsPercentage < 0
                              ? "purple"
                              : "green"
                          }
                        />
                      </div>
                    </td>
                  </tr>

                  <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-3.5  [&_td]:border-b [&_td]:border-b-neutral-300">
                    <td className="tracking-[-0.44px] pl-1.5">Rooms Offline</td>
                    <td className="tracking-[-0.44px] text-center">
                      {commaSeparatedNumber(
                        data?.actualAvailableRooms,
                        false,
                        false
                      )}
                    </td>
                    <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                      {commaSeparatedNumber(
                        data?.availableRoomsBudget,
                        false,
                        false
                      )}
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[52px]"
                          text={commaSeparatedNumber(
                            data?.varAvailableRooms,
                            false,
                            false
                          )}
                          type={
                            data?.varAvailableRooms < 0 ? "purple" : "green"
                          }
                        />
                      </div>
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[38px]"
                          text={`${commaSeparatedNumber(
                            data?.varAvailableRoomsPercentage,
                            true
                          )}`}
                          type={
                            data?.varAvailableRoomsPercentage < 0
                              ? "purple"
                              : "green"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-3.5 ">
                    <td className="tracking-[-0.44px] pl-1.5">Occupancy</td>
                    <td className="tracking-[-0.44px] text-center">-</td>
                    <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                      {commaSeparatedNumber(data?.occupancyBudget || 0, true)}
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[52px]"
                          text={`${commaSeparatedNumber(
                            data?.varOccupancy || 0,
                            true
                          )}`}
                          type={data?.varOccupancy < 0 ? "purple" : "green"}
                        />
                      </div>
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[38px]"
                          text={`${commaSeparatedNumber(
                            data?.varOccupancyPercentage || 0,
                            true
                          )}`}
                          type={
                            data?.varOccupancyPercentage < 0
                              ? "purple"
                              : "green"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-3.5  [&_td]:border-t [&_td]:border-t-neutral-600">
                    <td className="tracking-[-0.44px] pl-1.5">ADR</td>
                    <td className="tracking-[-0.44px] text-center">
                      {commaSeparatedNumber(data?.adr || 0, false)}
                    </td>
                    <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                      {commaSeparatedNumber(
                        Number(data?.adrBudget || 0),
                        false
                      )}
                    </td>
                    <td className="tracking-[-0.44px] pl-1">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[52px]"
                          text={`${commaSeparatedNumber(
                            Number(data?.varAdr || 0),
                            false
                          )}`}
                          type={data?.varAdr < 0 ? "purple" : "green"}
                        />
                      </div>
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[38px]"
                          text={`${commaSeparatedNumber(
                            data?.varAdrPercentage || 0,
                            true
                          )}`}
                          type={data?.varAdrPercentage < 0 ? "purple" : "green"}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="text-2xs font-medium [&_td]:text-[#8B8C91] [&_td]:py-3.5  [&_td]:border-y [&_td]:border-y-neutral-300">
                    <td className="tracking-[-0.44px] pl-1.5">RevPAR</td>
                    <td className="tracking-[-0.44px] text-center">
                      {commaSeparatedNumber(data?.actualRevpar || 0, false)}
                    </td>
                    <td className="tracking-[-0.44px] !text-[#635F66] text-center">
                      {commaSeparatedNumber(data?.revparBudget || 0, false)}
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[52px]"
                          text={`${commaSeparatedNumber(
                            data?.varRevpar || 0,
                            false
                          )}`}
                          type={
                            data?.varRevparPercentage < 0 ? "purple" : "green"
                          }
                        />
                      </div>
                    </td>
                    <td className="tracking-[-0.44px]">
                      <div className="w-full flex justify-center">
                        <ColorChip
                          className="w-[38px]"
                          text={`${commaSeparatedNumber(
                            data?.varRevparPercentage || 0,
                            true
                          )}`}
                          type={
                            data?.varRevparPercentage < 0 ? "purple" : "green"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <div className="w-full p-3 border-b border-[#b5b6bd] justify-between items-center inline-flex">
                  <div className="text-black text-[13px] font-semibold font-['Inter']">
                    Competitive Pricing
                  </div>
                  <div className="text-black text-2xs font-medium font-['Inter']">
                    Standard Room
                  </div>
                </div>
                {[
                  {
                    hotelName: "Your Hotel",
                    rate: price,
                    varRatePercentage: percentage,
                  },
                  ...(data?.competitorPrices || []),
                ]?.map((competitor, i) => (
                  <div
                    key={competitor.hotelName}
                    className={`w-full h-[38px] p-3 border-b ${
                      i !== 0 ? "border-b-neutral-300" : "border-[#b5b6bd]"
                    } justify-start items-center flex`}
                  >
                    <div className="text-right text-[#646466] text-2xs font-medium leading-[11px] justify-start items-center gap-2.5 flex">
                      {competitor.hotelName}
                    </div>
                    <div className="flex-1 flex justify-end items-center gap-3">
                      <div className="text-right text-[#646466] text-2xs font-medium leading-[11px] justify-end items-center gap-2.5 flex">
                        {commaSeparatedNumber(
                          Number(competitor?.rate || 0),
                          false
                        )}
                      </div>
                      <ColorChip
                        className="w-[52px]"
                        text={`${commaSeparatedNumber(
                          Number(competitor.varRatePercentage || 0),
                          true
                        )}`}
                        type={"grey"}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="h-10 p-3 justify-start items-center gap-2.5 flex">
              <div>
                <button
                  type="button"
                  className="text-[#563aff] text-[13px] font-normal"
                  onClick={() => setShowPriceExplanation(true)}
                >
                  Click here
                </button>
                <span className="text-black text-[13px] font-normal font-['Inter']">
                  {" "}
                  to learn about your hotel's price
                </span>
              </div>
            </div>
            <div className="p-3">
              <Button
                variant="outline"
                className="w-full"
                onClick={onOpenPriceAdjustmentModal}
              >
                Price Adjustments
                <RightArrow className="[&_path]:stroke-black" />
              </Button>
            </div>
          </div>
          {showPriceExplanation && (
            <div className="absolute lg:-left-[342px] bottom-24 lg:bottom-0 z-[4] shadow-[0px_1px_1px_0px_rgba(20,20,43,0.14)] rounded-[10px]">
              <PriceExplanation
                onClose={() => setShowPriceExplanation(false)}
                data={data}
                dayPrice={dayPrice}
              />
            </div>
          )}
        </>
      )}
    </Panel>
  );
}
