import { useApp } from "../helpers/app-context";
import { Chatbot } from "../pages/chatbot/Chatbot";
import { Sidebar } from "./Sidebar";
import { ReactComponent as ChatButtonIcon } from "../assets/icons/chat-button.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/menu.svg";
import { MobileSidebar } from "./MobileSidebar";
import { Logo } from "./Logo";
import { Notification } from "../pages/dashboard/components/Notification";

const MobileHeader = ({ onMenuClick }) => {
  return (
    <div className="h-[58px] fixed top-0 z-[9] left-0 w-full flex items-center justify-between px-5 shadow-[0px_4px_10px_0px_rgba(134,133,141,0.12)] bg-white border-b border-b-neutral-300">
      <div className="flex items-center gap-2.5">
        <button type="button" onClick={onMenuClick}>
          <MenuIcon />
        </button>
        <Logo className="!text-[25px] !leading-0 !tracking-[-1.033px]" />
      </div>
      <Notification />
    </div>
  );
};

export function Page({ children }: { children: React.ReactNode }) {
  const {
    setSidebarExpanded,
    sidebarExpanded,
    isChatBotOpen,
    setIsChatBotOpen,
    isMobileSidebarOpen,
    setIsMobileSidebarOpen,
  } = useApp() as {
    isMobileSidebarOpen: boolean;
    setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarExpanded: boolean;
    isChatBotOpen: boolean;
    setIsChatBotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  return (
    <div className="flex pt-[58px] lg:pt-0 w-full">
      <div className="lg:hidden">
        <MobileHeader onMenuClick={() => setIsMobileSidebarOpen((p) => !p)} />
      </div>
      <div className="hidden lg:block">
        <Sidebar
          sidebarExpanded={sidebarExpanded}
          setSidebarExpanded={setSidebarExpanded}
        />
      </div>
      {children}
      {isChatBotOpen && <Chatbot />}
      {!isChatBotOpen && (
        <button
          className="fixed bottom-3 right-3 w-fit"
          type="button"
          onClick={() => setIsChatBotOpen((p) => !p)}
        >
          <ChatButtonIcon />
        </button>
      )}
      {isMobileSidebarOpen && <MobileSidebar />}
    </div>
  );
}
