import { Select } from "../../../components/Select";

const getYearOptions = () => {
  const firstYear = 2000;
  const currentYear = new Date().getFullYear();
  const years: {
    value: string;
    label: string;
  }[] = [];
  for (let i = currentYear; i >= firstYear; i--) {
    years.push({ value: i.toString(), label: i.toString() });
  }
  return years;
};

export function YearSelect({ value, onChange, disabled }) {
  return (
    <Select
      data={getYearOptions()}
      value={getYearOptions().find((y) => y.value === value)}
      onChange={(year) => {
        onChange(year.value);
      }}
      className="w-[54px] !min-h-[24px] !h-6 !py-0 [&_input]:!h-5"
      isDisabled={disabled}
      isSearchable={false}
    />
  );
}
