import { useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as CheckMarkIcon } from "../../../assets/icons/check-mark.svg";
import moment from "moment";

const getDates = (value: string) => {
  if (value === "l9n3") {
    // send last 9 months and next 3 months
    const currentYear = new Date().getFullYear();
    const startDate = moment()
      .set("year", currentYear)
      .subtract(9, "months")
      .format("YYYY-MM-DD");
    const endDate = moment()
      .set("year", currentYear)
      .add(3, "months")
      .format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
      year: value,
    };
  }
  // send the year start and end date
  const currentYear = Number(value);
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

const getYearOptions = ({ isSummary }) => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const l9n3 = {
    label:
      "L9, N3 <span class='text-[#b5b6bd]'>(last 9 mos, next 3 mos)</span>",
    selectedLabel: "L9, N3",
    value: "l9n3",
  };
  const options = [
    {
      label: "Last 12 months",
      value: `${currentYear - 1}`,
    },
    {
      label: `${currentYear}`,
      value: `${currentYear}`,
    },
    {
      label: `${nextYear}`,
      value: `${nextYear}`,
    },
  ];

  if (isSummary) {
    options.unshift(l9n3);
  }

  return options;
};

export function YearFilter({
  filter,
  setFilter,
  disabled,
  isSummary = false,
  onChange,
  value,
}: {
  filter?: any;
  setFilter?: any;
  disabled?: boolean;
  isSummary?: boolean;
  onChange?: (value: string) => void;
  value?: string;
}) {
  const [showFilter, setShowFilter] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = getYearOptions({ isSummary });

  const year = value
    ? value
    : filter.year === "l9n3"
    ? "l9n3"
    : moment(filter.startDate).format("YYYY");
  const selectedType = options.find((type) => type.value === year);

  console.log(year, "year", selectedType, "selectedType");
  return (
    <div ref={containerRef} className="relative">
      <button
        type="button"
        onClick={() => setShowFilter((p) => !p)}
        className="justify-start min-w-[54px] max-w-[90px] lg:max-w-[120px] !h-6 items-center gap-1 flex p-1.5 rounded border border-[#E9E9EB]"
      >
        <div className="text-center flex-1 whitespace-nowrap overflow-hidden text-ellipsis w-fit text-[#8B8C91] text-2xs font-medium leading-[11px]">
          {selectedType?.value === "l9n3"
            ? selectedType?.label.split(" <")[0]?.replaceAll(" ", "")
            : selectedType?.label}
        </div>
        <ChevronDownIcon
          className={`w-2.5 h-2.5 ${
            showFilter
              ? "rotate-180 pb-0.5 [&_path]:stroke-black"
              : "pt-0.5 [&_path]:stroke-neutral-600"
          }`}
        />
      </button>
      {showFilter && (
        <div
          className={`dropdown absolute z-[1] right-0 mt-1 ${
            isSummary ? "w-[225px]" : "w-[126px]"
          } p-1 bg-white rounded shadow border border-[#e8e8ea] flex-col justify-start items-start gap-1.5 flex`}
        >
          <div className="self-stretch max-h-[100px] flex-col justify-start items-start flex">
            {options.map((type) => (
              <button
                onClick={() => {
                  if (onChange) {
                    onChange(type.value);
                  } else {
                    setFilter({ ...filter, ...getDates(type.value) });
                  }
                  setShowFilter(false);
                }}
                type="button"
                key={type.value}
                className="self-stretch hover:bg-[#f2f1ff] h-[25px] p-2 rounded-sm justify-between items-center inline-flex"
              >
                <div
                  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                  dangerouslySetInnerHTML={{
                    __html: type.label,
                  }}
                  className="text-[#161938] text-xs font-medium leading-3"
                />
                {year === type.value && (
                  <CheckMarkIcon className="[&_path]:stroke-primary w-3 h-3" />
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
