import React, { useEffect, useState } from "react";
import useResponsive from "./useResponsive";

export const AppContext = React.createContext({});

export function AppProvider({ children }) {
  const { isMobile } = useResponsive();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  // dashboard data
  const [loading, setLoading] = useState(false);

  const onBarOpen = () => {
    // on any bar open, chatbot or sidebar, add a class to the body called "sidebar-open"
    document.body.classList.add("sidebar-open");
  };

  useEffect(() => {
    if (isChatBotOpen) {
      onBarOpen();
      setSidebarExpanded(false);
    }
  }, [isChatBotOpen]);

  useEffect(() => {
    if (sidebarExpanded) {
      onBarOpen();
      setIsChatBotOpen(false);
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    if (!isChatBotOpen && !sidebarExpanded) {
      document.body.classList.remove("sidebar-open");
    } else {
      onBarOpen();
    }
  }, [isChatBotOpen, sidebarExpanded]);

  const values = {
    loading,
    setLoading,
    setSidebarExpanded,
    sidebarExpanded,
    isChatBotOpen,
    setIsChatBotOpen,
    setIsMobileSidebarOpen,
    isMobileSidebarOpen,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export function useApp() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
}
