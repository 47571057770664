import apiClient from "./client";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  register: (data: any) => {
    return apiClient.post("/auth/register", data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  patchPassword: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/password`, data);
  },
  putUserProfile: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  getUserSubscription: (id: string) => {
    return apiClient.get(`/users/${id}/subscriptions`).then((res) => res.data);
  },
  setActiveStatus: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  getStripe: (id: string, tier: "ST" | "PR" | "MX") => {
    return apiClient
      .get(`/users/${id}/stripe-connect-url`, {
        params: {
          redirectUrl: window.location.origin + "/onboarding",
          planType: "M",
          tier: tier,
        },
      })
      .then((res) => res.data);
  },
  patchStripe: (
    id: string,
    tier: "ST" | "PR" | "MX",
    subscriptionId: string
  ) => {
    return apiClient.patch(`/users/${id}/subscriptions/${subscriptionId}`, {
      tier: tier,
      planType: "M",
    });
  },
  customerStripePortal: (id: string) => {
    return apiClient
      .get(`/users/${id}/stripe-customer-portal-url`, {
        params: {
          // redirectUrl: "https://dev.app.stealthseller.co/dashboard",
          // redirectUrl: "http://localhost:3002/dashboard",
          // dynamicRedirectUrl
          redirectUrl: window.location.origin + "/dashboard",
        },
      })
      .then((res) => res.data);
  },
  cancelSubscription: (id: string, subscriptionId: string) => {
    return apiClient
      .delete(`/users/${id}/subscriptions/${subscriptionId}`)
      .then((res) => res.data);
  },
  getUser: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  initiateEmailUpdate: (userId: string, email: string) => {
    return apiClient.post(`/users/${userId}/email/update/initiate`, {
      email,
    });
  },
  updateEmail: (userId: string, otp: string) => {
    return apiClient.post(`/users/${userId}/email/update`, {
      otp,
    });
  },
  deleteUser: (userId: string) => {
    return apiClient.delete(`/users/${userId}`);
  },
  getGraph: (sellerId: string, productId: string) => {
    return apiClient.get(`/sellers/${sellerId}/products/${productId}/graph`, {
      responseType: "blob",
    });
  },
  updateUserActivity: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  saveOnboarding: (userId: string, data: any) => {
    delete data.userPms;
    return apiClient.post(`/users/${userId}/onboarding`, data);
  },
  getOnboarding: (userId: string) => {
    return apiClient.get(`/users/${userId}/onboarding`).then((res) => res.data);
  },
  deleteOnboarding: (userId: string) => {
    return apiClient.delete(`/users/${userId}/onboarding`);
  },
  connectPMS: (
    userId: string,
    data: {
      accessToken: string;
      pms: string;
    }
  ) => {
    const url = `/users/${userId}/pms/connect`;
    return apiClient.post(url, data);
  },
  getMetrics: (
    userId: string,
    data: {
      startDate: string;
      endDate: string;
      targetStartDate?: string;
      targetEndDate?: string;
      type: string;
    }
  ) => {
    let url = `/users/${userId}/metrics?startDate=${data.startDate}&endDate=${data.endDate}&type=${data.type}`;
    if (data.targetStartDate && data.targetEndDate) {
      url += `&targetStartDate=${data.targetStartDate}&targetEndDate=${data.targetEndDate}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getDayMetrics: (userId: string, data: { type: string; date: string }) => {
    const url = `/users/${userId}/day-metrics?type=${data.type}&date=${data.date}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getDayPrice: (userId: string, date: string) => {
    const url = `/users/${userId}/day-price?date=${date}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getHotelData: (userId: string) => {
    const url = `/users/${userId}/hotel-data`;
    return apiClient.get(url).then((res) => res.data);
  },
  saveDayPrice: (userId: string, data: any) => {
    const url = `/users/${userId}/price`;
    return apiClient.post(url, data);
  },
  resetDayPrice: (userId: string, data: any) => {
    const url = `/users/${userId}/price-reset`;
    return apiClient.post(url, data);
  },
  getCalendarData: (userId: string, startDate: string, endDate: string) => {
    const url = `/users/${userId}/calendar-data?startDate=${startDate}&endDate=${endDate}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getMessages: (userId: string) => {
    const url = `/conversations?userId=${userId}`;
    return apiClient.get(url).then((res) => res.data);
  },
  postMessage: (data: any) => {
    const url = `/conversations`;
    return apiClient.post(url, data);
  },
  getNotifications: ({
    page = 0,
    limit = 10,
  }: {
    page: number;
    limit: number;
  }) => {
    const url = `/notifications?page=${page}&limit=${limit}`;
    return apiClient.get(url).then((res) => res.data);
  },
  readNotifications: (id: string) => {
    const url = `/notifications/${id}`;
    return apiClient.patch(url, {
      isRead: true,
    });
  },
};
