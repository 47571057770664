import { useState } from "react";
import { Card } from "../../../../components/Card";
import CustomInput from "../../../../components/Input";
import { Rating } from "../../../../components/Rating";
import { ReactComponent as Shadow } from "../../../assets/images/onboarding-shadow.svg";
import { ActionButtons } from "../ActionButtons";
import { PriceInput } from "../../../../components/PriceInput";
import { Select } from "../../../../components/MultiSelect";
import { ChipInput } from "../../../../components/ChipInput";
import TextArea from "../../../../components/Textarea";
import { useOnboardingSave } from "../../useOnboardingSave";
import { validateCompetitorSetForm2 } from "../../useValidation";

function HotelRating({ value, onChange }) {
  return (
    <Card className="flex items-center gap-2.5 p-3">
      <div className="w-[58px] h-[58px] rounded">
        <img
          className="w-full h-full rounded object-cover"
          src="https://lh5.googleusercontent.com/p/AF1QipP5nLmrgu8odNom9iIiTDerSke1tCBKeVi1MFZ4=w243-h174-n-k-no-nu"
        />
      </div>
      <div className="flex-1 flex flex-col gap-3">
        <span className="text-[13px] font-semibold leading-[9px]">
          Silverlake Suites
        </span>

        <Rating
          labels={["Worse location", "Similar location", "Better location"]}
          value={value}
          onChange={(val) => onChange(val)}
        />
      </div>
    </Card>
  );
}

export function Screen2Form({ setForm, form, isReview = false }) {
  return (
    <>
      <div className="flex flex-col gap-5 [&_label]:!text-neutral-700">
        {!isReview && (
          <div className="text-xl lg:text-2xl font-semibold">
            Competitive set
          </div>
        )}
        <TextArea
          label="What makes your hotel stand out from these competitors?"
          placeholder="Feel free to write in natural language, our AI can understand you"
          onChange={(e) =>
            setForm((prev) => ({ ...prev, usp: e.target.value }))
          }
          value={form.usp}
          rows={5}
        />
        <TextArea
          label="What amenities or offerings do you have that guests really value?"
          placeholder="Feel free to write in natural language, our AI can understand you"
          onChange={(e) =>
            setForm((prev) => ({ ...prev, amenities: e.target.value }))
          }
          value={form.amenities}
          rows={5}
        />
      </div>
    </>
  );
}

export function Screen2({ onBack, onNext, setForm, form }) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const handleNext = async () => {
    onSave(form);
  };
  const { isValid, errors } = validateCompetitorSetForm2(form);
  return (
    <Card className="lg:max-w-[670px] lg:w-[670px] !p-6 lg:!p-8">
      <Screen2Form setForm={setForm} form={form} />

      <ActionButtons
        onBack={onBack}
        onNext={handleNext}
        loading={saving}
        disabled={!isValid}
      />
    </Card>
  );
}
