import Button from "../../../components/Button";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";

export function ActionButtons({
  containerClassName = "",
  onNext,
  onBack,
  loading,
  disabled,
  onSkip,
}: {
  onNext?: () => void;
  onBack?: () => void;
  containerClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  onSkip?: () => void;
}) {
  return (
    <div className={`mt-7 flex gap-3 justify-end ${containerClassName}`}>
      {onSkip && (
        <button type="button" className="text-primary text-sm" onClick={onSkip}>
          Skip
        </button>
      )}
      {onBack && (
        <Button
          disabled={loading}
          variant="outline"
          className="w-fit"
          onClick={onBack}
        >
          <RightArrow className="rotate-180 [&_path]:stroke-black" /> Back
        </Button>
      )}
      <Button
        disabled={disabled}
        loading={loading}
        className="w-fit"
        onClick={onNext}
      >
        Continue <RightArrow />
      </Button>
    </div>
  );
}
