import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";

export function Search({
  onChange,
  value,
  id,
}: {
  onChange: (value: string) => void;
  value: string;
  id?: string;
}) {
  return (
    <div className="flex px-3 py-2.5 border border-neutral-400 rounded items-center gap-2.5">
      <div>
        <SearchIcon />
      </div>
      <input
        type="text"
        placeholder="Search"
        className="w-full outline-none"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        id={id}
      />
    </div>
  );
}
