import { useState } from "react";
import { api } from "../../../helpers/api";
import { ShowToast } from "../../../components/toast";
import { PopupModal } from "../../../components/Modal";
import Button from "../../../components/Button";

export function ConfirmResetModal({
  onClose,
  userId,
  refetch,
  dates,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
  dates: {
    startDate: string;
    endDate?: string;
  };
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleReset = () => {
    setIsLoading(true);
    api
      .resetDayPrice(userId, dates)
      .then(() => {
        onClose();
        ShowToast({
          message: "Day price reset successfully",
          type: "success",
        });
        refetch && refetch();
        setIsLoading(false);
      })
      .catch(() => {
        ShowToast({
          message: "Failed to reset day price",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to reset this day's settings to match your
        seasonal settings?
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="px-8 text-[1rem]"
          onClick={handleReset}
          loading={isLoading}
        >
          Reset
        </Button>
      </div>
    </PopupModal>
  );
}
