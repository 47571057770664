import * as React from "react";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";

const covertStringToTag = (str = "") => {
  return str
    .trim()
    .split(",")
    .filter((e) => e)
    .map((item) => item.trim());
};

/**Type of Input-Props */
export type Props = {
  value: string[];
  onChange: Function;
  readOnly?: boolean;
};

export function ChipInput({ value, onChange, readOnly }: Props) {
  const formControlRef = React.useRef<any>();

  const handleAdd = (newValue) => {
    if (!newValue) return;
    const list = value;
    list.push(newValue);
    onChange(list);
  };

  const handleRemove = (index) => {
    const list = value;
    const newValue = list.slice();
    newValue.splice(index, 1);
    onChange(newValue);
  };

  /**Called when user clicks on remove icon.
   * And in turn, onRemove of props called, wih index passed as argument.
   */
  const removeChip = (index: number) => {
    handleRemove(index);
  };
  const chips = value;
  return (
    /**The main container div */
    <div
      className={`flex items-center border p-[7px] border-neutral-600 rounded`}
    >
      <div className="flex flex-wrap items-center gap-1 w-full">
        {/* Each chip is bootstrap's col */}
        {chips.map((chip, index) => (
          <div
            key={index}
            className="flex items-center !h-6 bg-neutral-200 rounded border border-neutral-400 text-neutral-700 p-1.5 text-2xs font-medium gap-1"
          >
            {chip}
            <button onClick={() => removeChip(index)}>
              <CloseIcon className={"w-3 h-3"} />
            </button>
          </div>
        ))}
        <div className="flex-1">
          {/* The input, from which value is read and chip is added accordingly */}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              handleAdd(formControlRef?.current?.value);
              formControlRef.current.value = "";
            }}
            onBlur={(e: any) => {
              e.preventDefault();
              handleAdd(formControlRef?.current?.value);
              formControlRef.current.value = "";
            }}
            onChange={(e: any) => {
              // on space or comma in chipInput, chip is added
              // get chipInput value
              const value = formControlRef?.current?.value;
              if (value.includes(",") || value.includes(" ")) {
                // cut the last comma or space
                handleAdd(value.slice(0, -1));
                formControlRef.current.value = "";
              }
            }}
            noValidate
            className="flex items-center"
          >
            <input
              ref={formControlRef}
              name="chipInput"
              placeholder="Type something and press enter key"
              aria-label="Chip Input"
              className="text-2xs w-full border-none !outline-none"
              readOnly={readOnly}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
