import React, { useEffect, useState } from "react";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";

export const UserContext = React.createContext({});

export function UserProvider({ children }) {
  const user = getUser();
  const [onboardingData, setOnboardingData] = useState<any>(undefined);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
    // api.deleteOnboarding(user.id).then(() => {
    // });
  };

  const fetchOnboardingData = async () => {
    if (user?.id) {
      const onboardingResponse = await api.getOnboarding(user?.id);
      setOnboardingData(onboardingResponse);
    }
  };

  useEffect(() => {
    fetchOnboardingData();
  }, [user?.id]);

  // Call updateActivity once when the component mounts
  useEffect(() => {
    const updateActivity = async () => {
      try {
        await api.updateUserActivity(user?.id);
      } catch (error: any) {
        if ([400, 401].includes(error?.response?.status)) {
          logout();
        } else {
          console.error("Failed to update user activity", error);
        }
      }
    };

    if (user?.id) {
      updateActivity();
    }
  }, [user?.id]);

  const values = {
    logout,
    onboardingData,
    fetchOnboardingData,
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a UserProvider");
  }
  return context;
}
