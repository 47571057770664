import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import { setUser } from "../utils";
import { DeviceUUID } from "device-uuid";
import toast from "react-hot-toast";
// Adjust the import path to your actual toast component

const useGoogleLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async (response: any) => {
    setLoading(true);
    const names = response.data.name.split(" ") || [];
    const payload = {
      email: response.data.email,
      firstName: names[0] || "",
      lastName: names[1] || "",
      googleId: response.data.id,
      googleIdToken: response.data.id,
      userType: "U",
    };

    try {
      const res = await api.login(payload);
      localStorage.setItem("token", res.data.accessToken);
      localStorage.setItem("user", JSON.stringify(res.data));
      if (res.data?.id) {
        const userResponse = await api.getUserProfile(res.data?.id);
        setUser({ ...res.data, ...userResponse });
        await api.setActiveStatus(res.data?.id);
      }
      navigate("/dashboard");
    } catch (err) {
      toast.error("Something went wrong. Please try again");
    } finally {
      setLoading(false);
    }
  };

  return { handleGoogleLogin, loading };
};

export default useGoogleLogin;
