import { useEffect, useRef, useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import { YearSelect } from "./YearSelect";
import { MonthSelect } from "./MonthSelect";
import { Radio } from "../../../components/Radio";
import moment from "moment";
import CustomButton from "../../../components/Button";
import {
  commaSeparatedNumber,
  formatNumber,
  getPercentage,
  getUser,
} from "../../../helpers/utils";
import { api } from "../../../helpers/api";

const getYTDDates = () => {
  // year to date 8/31/2023 - 8/31/2024
  // last one year from current date
  const startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");
  return { startDate, endDate };
};

const getYearDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return { startDate, endDate };
};

const getMonthDates = (month?: string | number) => {
  const currentYear = new Date().getFullYear();
  // month or current month
  const currentMonth = month ? Number(month) - 1 : new Date().getMonth();
  const startDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .set("month", currentMonth)
    .endOf("month")
    .format("YYYY-MM-DD");
  return { startDate, endDate };
};

const Filters = {
  YTD: "YTD",
  Year: "Year",
  Month: "Month",
};

const FilterLabels = {
  [Filters.YTD]: "Year to date",
  [Filters.Year]: "Any year",
  [Filters.Month]: "Any month",
};

function FilterView({ setFilter, filter, onApply }) {
  const ytdDates = getYTDDates();

  return (
    <div className="absolute z-[1] w-[290px] left-0 p-1 bg-white rounded shadow border border-[#e8e8ea] mt-1">
      <div>
        <div className="self-stretch h-24 flex-col justify-start items-start flex">
          <div
            className={`self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex`}
          >
            <div className="justify-start items-center gap-1.5 flex">
              <Radio
                checked={filter.type === Filters.YTD}
                onChange={() =>
                  setFilter((prev) => ({ type: Filters.YTD, ...getYTDDates() }))
                }
                label="Year to date"
              />
            </div>
            <div className="opacity-60 text-black text-xs font-medium  leading-3">
              {moment(ytdDates.startDate).format("MM/DD/YYYY")} -{" "}
              {moment(ytdDates.endDate).format("MM/DD/YYYY")}
            </div>
          </div>
          <div
            className={`self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex`}
          >
            <Radio
              checked={filter.type === Filters.Year}
              onChange={() =>
                setFilter((prev) => ({ type: Filters.Year, ...getYearDates() }))
              }
              label="Any year"
            />
            <YearSelect
              disabled={filter.type !== Filters.Year}
              value={moment(filter.startDate).format("YYYY")}
              onChange={(year) =>
                setFilter((prev) => ({
                  ...prev,
                  ...getYearDates(year),
                }))
              }
            />
          </div>
          <div
            className={`self-stretch pl-2 pr-1 py-1 rounded-sm border-b border-neutral-400 justify-between items-center flex`}
          >
            <Radio
              checked={filter.type === Filters.Month}
              onChange={() =>
                setFilter((prev) => ({
                  type: Filters.Month,
                  ...getMonthDates(),
                }))
              }
              label="Any month"
            />
            <div className="justify-start items-center gap-1.5 flex">
              <MonthSelect
                disabled={filter.type !== Filters.Month}
                value={moment(filter.startDate).format("M")}
                onChange={(month) =>
                  setFilter((prev) => ({
                    ...prev,
                    ...getMonthDates(month),
                  }))
                }
              />
              <YearSelect
                disabled={filter.type !== Filters.Month}
                value={moment(filter.startDate).format("YYYY")}
                onChange={(year) =>
                  setFilter((prev) => ({
                    ...prev,
                    ...getYearDates(year),
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <CustomButton
            onClick={onApply}
            className="!h-[30px] text-white text-[13px] font-medium"
          >
            Apply
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export function RevParSummary() {
  const user = getUser();
  const [data, setData] = useState<any>({});
  const [filter, setFilter] = useState({ type: Filters.YTD, ...getYTDDates() });
  const [showFilter, setShowFilter] = useState(false);
  const containerRef = useRef(null);

  const fetchMetrics = async () => {
    try {
      const userId = user?.id;
      const filters = {
        type: "TotalRevenue",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics);
    } catch (error) {
      console.log(error);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, []);

  const handleApply = () => {
    // setShowFilter(false);
    fetchMetrics();
  };

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isRevparUp = Number(data?.revpar?.varPercentagePrevYear || 0) > 0;
  const isRevenueUp = Number(data?.revenue?.varPercentagePrevYear || 0) > 0;

  return (
    <div ref={containerRef} className="relative flex-1 flex justify-end">
      <div className="w-fit px-3 py-3.5 rounded-lg border border-[#b5b6bd] justify-between lg:items-center flex lg:flex-row flex-col">
        <div className="justify-start items-center gap-1 flex">
          <CalendarIcon className="w-4 h-4 mt-1" />
          <button
            type="button"
            onClick={() => setShowFilter((p) => !p)}
            className="justify-start items-center gap-1 flex"
          >
            <div>
              <span className="text-[#646466] text-xs font-medium  leading-none">
                Period:
              </span>
              <span className="text-[#525253] text-xs font-medium  leading-none">
                {" "}
              </span>
              <span className="text-black text-xs font-medium  leading-none">
                {FilterLabels[filter.type]}
              </span>
            </div>
            <ChevronDownIcon
              className={`w-3 h-3 ${showFilter ? "rotate-180 pb-1" : "pt-1"}`}
            />
          </button>
        </div>
        <div className="bg-[#B6B6BD] w-full h-[1px] opacity-50 lg:hidden my-3.5" />
        <div className="justify-start items-center gap-2 flex ml-2">
          <div className="justify-start items-center gap-1 flex">
            <div className="text-neutral-600 text-xs font-medium  leading-tight">
              Revenue
            </div>
            <div className="justify-start items-center gap-0.5 flex">
              <div className="text-black text-sm font-semibold">
                {commaSeparatedNumber(
                  Number(data?.revenue?.current || 0),
                  false
                )}
              </div>
              <div className="rounded justify-start items-center gap-px flex">
                <div className="text-[#05c168] text-xs font-semibold  leading-none">
                  {isRevenueUp ? "+" : ""}
                  {commaSeparatedNumber(
                    Number(data?.revenue?.varPercentagePrevYear || 0),
                    true
                  )}
                </div>
                <ArrowIcon className="w-3.5 h-3.5 [&_path]:stroke-green-300 rotate-90" />
              </div>
            </div>
          </div>
          <div className="w-[1px] self-stretch bg-[#b5b6bd]" />
          <div className="justify-start items-center gap-1 flex">
            <div className="text-neutral-600 text-xs font-medium  leading-tight">
              RevPAR
            </div>
            <div className="justify-start items-center gap-0.5 flex">
              <div className="text-black text-sm font-semibold ">
                {commaSeparatedNumber(data?.revpar?.current, false)}
              </div>
              <div className="rounded justify-start items-center gap-px flex">
                <div className="text-[#05c168] text-xs font-semibold  leading-none">
                  {isRevparUp ? "+" : ""}
                  {commaSeparatedNumber(
                    Number(data?.revpar?.varPercentagePrevYear || 0),
                    true
                  )}
                </div>
                <ArrowIcon className="w-3.5 h-3.5 [&_path]:stroke-green-300 rotate-90" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFilter && (
        <FilterView
          onApply={handleApply}
          filter={filter}
          setFilter={setFilter}
        />
      )}
    </div>
  );
}
