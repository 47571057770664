const Trapezoid = ({
  border,
  background,
  text,
  title,
  width,
  height,
  clipPath,
}) => {
  return (
    <button
      className={`relative z-[2] flex justify-center items-center ${border} cursor-pointer group ${clipPath}`}
      style={{ width: width, height: height }}
    >
      <div
        className={`h-[calc(100%-2px)] w-[calc(100%-2px)] flex justify-center items-center px-5 py-3 ${background} ${clipPath}`}
      >
        <span className={`${text} text-xs font-medium leading-3`}>{title}</span>
      </div>
    </button>
  );
};

export default Trapezoid;
