import { Tooltip as ReactTooltip } from "react-tooltip";
import { ReactComponent as InfoIcon } from "../assets/icons/info.svg";

export function Tooltip({ id, children, className = "" }) {
  return (
    <>
      <div className="cursor-pointer" data-tooltip-id={id}>
        <InfoIcon />
      </div>
      <ReactTooltip
        id={id}
        className={`w-[200px] z-[2] !border border-neutral-500 !text-black !rounded-md !p-[11px] !bg-white !shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] ${className}`}
        noArrow
      >
        {children}
      </ReactTooltip>
    </>
  );
}
