import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import ConfirmIcon from "../../../assets/icons/check.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { api } from "../../../helpers/api";
import Button from "../../../components/Button";
import { Layout } from "../components/Layout";
import { ShowToast } from "../../../components/toast";
import FormField from "../../../components/FormField";
import { AutumnInfo } from "../components/AutumnInfo";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/g,
      "Password must contain at least one uppercase and one lowercase letter and one special character"
    ),
  confirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});
export default function ResetPassword() {
  const [success, setSuccess] = useState(false);
  // get query param

  return (
    <Layout>
      <div className="flex w-full items-center">
        <div className="hidden md:flex justify-center flex-1">
          <AutumnInfo />
        </div>
        <div className="flex-1">
          {success ? (
            <ResetPasswordSuccess />
          ) : (
            <ResetPasswordForm toggleSuccess={() => setSuccess(true)} />
          )}
        </div>
      </div>
    </Layout>
  );
}

function ResetPasswordSuccess() {
  return (
    <div className="grid items-start h-full justify-center lg:w-[350px] w-[calc(100vw-2rem)] mx-auto">
      <div className="grid gap-[1.5rem]">
        <img src={ConfirmIcon} alt="confirm" className="w-[80px] h-[80px]" />
        <div className="grid gap-4">
          <h1 className="text-black text-[28px] font-bold tracking-[-1.12px] leading-[40px]">
            You have successfully changed your password
          </h1>
        </div>
        <div className="text-center">
          <Link to="/login">
            <Button>Continue to dashboard</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function ResetPasswordForm({ toggleSuccess }: { toggleSuccess: () => void }) {
  const location = useLocation();
  const rt = new URLSearchParams(location.search).get("rt");
  return (
    <Formik
      initialValues={{ password: "", confirm: "" }}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={(values, actions) => {
        api
          .resetPassword({
            ...values,
            resetPasswordToken: rt,
          })
          .then(() => {
            actions.setSubmitting(false);
            toggleSuccess();
          })
          .catch((err) => {
            actions.setSubmitting(false);
            ShowToast({
              message: "There was an error resetting your password.",
              type: "error",
            });
          });
      }}
    >
      <ResetPasswordFormContent />
    </Formik>
  );
}

function ResetPasswordFormContent() {
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <div className="grid items-center h-full justify-center">
      <div className="grid">
        <Form className="lg:w-[380px] w-[calc(100vw-2rem)] grid gap-[1.75rem] mb-[1.5rem]">
          <div className="grid gap-3">
            <h1 className="text-black text-[28px] font-bold tracking-[-1.12px] leading-[100%]">
              Reset your password
            </h1>
          </div>
          <div className="grid gap-4">
            <FormField
              label="New password"
              name="password"
              type="password"
              placeholder="Enter your password"
            />
            <FormField
              label="Confirm your password"
              name="confirm"
              type="password"
              placeholder="Re-enter password"
            />
          </div>
          <Button
            className="w-full"
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            Continue <RightArrow />
          </Button>
        </Form>
      </div>
    </div>
  );
}
