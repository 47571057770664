import { useEffect, useMemo, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  BarChart,
  Bar,
  Rectangle,
  Label,
} from "recharts";
import { Panel } from "../../../../components/Panel";
import moment from "moment";
import { FilterSelect, types } from "./components/FilterSelect";
import { YearFilter } from "../YearFilter";
import { api } from "../../../../helpers/api";
import {
  commaSeparatedNumber,
  formatNumber,
  getHeightInVh,
  getUser,
} from "../../../../helpers/utils";
import { transformData, transformRevenueByChannelData } from "./utils";
import useResponsive from "../../../../helpers/useResponsive";
import Spinner from "../../../../components/Spinners";

const numberFilters = ["rooms_ooo", "booking_window", "guest_reviews"];
const percentageFilters = ["revenue_by_channel"];

const channelColors = {
  direct: "#563AFF",
  bookingDotCom: "#000",
  expedia: "#b7d3ff",
  other: "#e9e9eb",
};
const channelLabels = {
  bookingDotCom: "Booking.com",
  direct: "Direct",
  expedia: "Expedia",
  other: "Other",
};

const titles = {
  expedia: "Expedia",
  booking_com: "Booking.com",
  makemytrip: "MakeMyTrip",
  tripadvisor: "Tripadvisor",
  google: "Google",
  trivago: "Trivago",
  agoda: "Agoda",
  hotels_com: "Hotels.com",
};

const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

const CustomCursor = (props) => {
  const { x, y, width, height } = props;
  // make width 25px
  const newWidth = 25;
  // set x in center
  const remaining = (width - (newWidth + 1)) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomBarTooltip = () => {
  return null;
};

const CustomTooltip = ({ active, payload, label, field }: any) => {
  const data = payload?.[0]?.payload;
  const isBookingWindow = field === "booking_window";
  const isPercentage = percentageFilters.includes(field) || false;
  const isNumber = numberFilters.includes(field) || false;

  return (
    <div className="p-2.5 bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#f4f4f6] justify-start items-start gap-3 flex-col flex">
      {field === "revenue_by_channel" ? (
        <>
          {Object.keys(channelColors).map((key) => (
            <div className="justify-start items-start gap-1.5 flex">
              <div className="py-px justify-start items-center gap-2.5 flex">
                <div
                  className="w-3 h-3 rounded-full"
                  style={{ backgroundColor: channelColors[key] }}
                />
              </div>
              <div className="flex-col justify-start items-start gap-0.5 flex">
                <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
                  {channelLabels[key]}
                </div>
                <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
                  {commaSeparatedNumber(data?.[key], true)}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="justify-start items-start gap-1.5 flex">
            <div className="py-px justify-start items-center gap-2.5 flex">
              <div className="w-3 h-3 bg-black rounded-full" />
            </div>
            <div className="flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#8b8b91] text-2xs font-normal font-['Inter']">
                {types.find((e) => e.value === field)?.label || field}
              </div>
              <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
                {commaSeparatedNumber(data?.count, isPercentage, !isNumber)}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export function TrendsLineChart() {
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const { isTall2XLDesktop, isQHD, isTall2, isMobile } = useResponsive();
  const [data, setData] = useState<any>({});
  const [filter, setFilter] = useState<any>({
    ...getDates(),
    type: "booking_window",
  });

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "Trends",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const graph = useMemo(() => {
    let transformedData: any[] = [];
    if (filter.type === "adr_index") {
      transformedData = transformData({
        data: data?.adrByMonth || [],
        currentField: "adr",
      });
    } else if (filter.type === "occ_index") {
      transformedData = transformData({
        data: data?.totalOccupancyByMonth || [],
        currentField: "occupancy",
      });
    } else if (filter.type === "ravpar_index") {
      transformedData = transformData({
        data: data?.totalRevparByMonth || [],
        currentField: "revpar",
      });
    } else if (filter.type === "booking_window") {
      transformedData = transformData({
        data: data?.totalAvgLengthOfStayByMonth || [],
        currentField: "count",
      });
    } else if (filter.type === "rooms_ooo") {
      transformedData = transformData({
        data: data?.totalUnAvailableRoomsByMonth || [],
        currentField: "count",
      });
    } else if (filter.type === "revenue_by_channel") {
      transformedData = transformRevenueByChannelData({
        data: data?.revenueByChannel || {},
      });
    } else if (filter.type === "guest_reviews") {
      transformedData = data?.reviews?.map((e) => ({
        name: e?.websiteName,
        sum: e?.scoreHotel,
      }));
    }
    return transformedData;
  }, [data, filter]);

  console.log(graph, data, filter, "trends");
  return (
    <Panel
      label="Trends"
      actions={
        <div className="flex items-center gap-1.5">
          <FilterSelect filter={filter} setFilter={setFilter} />
          <YearFilter filter={filter} setFilter={setFilter} disabled={false} />
        </div>
      }
      className="h-[310px] tall3:h-[360px] tall3XL:h-[410px] qhd1:h-[600px] flex flex-col tall3XL:[&_.header]:h-[50px]"
      contentClassName="h-[calc(100%-50px)]"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div
          className={`lg:w-[218px] relative xl:w-full overflow-auto pt-6 pb-0 pl-2 flex justify-center items-center h-full`}
        >
          {graph.length === 0 && (
            <div className="absolute top-0 text-neutral-700 text-sm font-medium left-0 flex justify-center text-center flex-1 items-center w-full h-full">
              No data available
            </div>
          )}
          <ResponsiveContainer
            height={
              isQHD
                ? 500
                : isTall2 || isMobile
                ? 209
                : isTall2XLDesktop
                ? 275
                : 300
            }
          >
            {filter.type === "guest_reviews" ? (
              <BarChart
                // width={500}
                // height={300}
                data={graph}
                margin={{
                  top: 5,
                  right: 20,
                  left: 5,
                  bottom: 35,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  className="!text-3xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    return titles[value] || value;
                  }}
                  interval={0}
                  minTickGap={-200}
                  angle={45}
                  dx={15}
                  dy={20}
                ></XAxis>
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={30}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    // make value to k denotes thousands
                    const val = Number(value);
                    // If zero then show 0 without dollar sign
                    if (val === 0) {
                      return "0";
                    }
                    if (val > 1000) {
                      return `${formatNumber(Number(val || 0), "")}`;
                    } else {
                      return `${val}`;
                    }
                  }}
                />
                <Tooltip
                  position={{ y: 0 }}
                  content={<CustomBarTooltip />}
                  cursor={graph.length > 0 && <CustomCursor />}
                />
                <defs>
                  <linearGradient
                    id="gradient2"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="#ACACAC" />
                    <stop offset="59%" stop-color="#5E5E5E" />
                    <stop offset="100%" stop-color="black" />
                  </linearGradient>
                </defs>
                <Bar
                  dataKey="sum"
                  fill="url(#gradient2)"
                  barSize={18}
                  radius={[2, 2, 0, 0]}
                />
              </BarChart>
            ) : (
              <LineChart
                width={500}
                height={300}
                data={graph}
                margin={{
                  top: 5,
                  right: 30,
                  left: numberFilters.includes(filter.type) ? 13 : 20,
                  bottom: 5,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  // ticks={["Jan", "Mar", "Jun", "Dec"]}
                  interval="preserveStartEnd"
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  width={20}
                  {...(filter.type === "booking_window" && {
                    ticks: [0, 15, 30, 45, 60, 75, 90],
                  })}
                  tickFormatter={(value) => {
                    // make value to k denotes thousands
                    const val = Number(value);
                    // If zero then show 0 without dollar sign
                    const sign = percentageFilters.includes(filter.type)
                      ? "%"
                      : numberFilters.includes(filter.type)
                      ? ""
                      : "$";
                    if (val === 0) {
                      return "0";
                    }
                    if (val > 1000) {
                      return `${formatNumber(Number(val || 0), sign)}`;
                    } else {
                      return sign === "%" ? `${val}${sign}` : `${sign}${val}`;
                    }
                  }}
                />
                <Tooltip content={<CustomTooltip field={filter.type} />} />
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="rgba(204, 196, 255, 1)" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>

                {filter.type === "revenue_by_channel" && (
                  <>
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="bookingDotCom"
                      stroke="#000"
                    />
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="expedia"
                      stroke="#b7d3ff"
                    />
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="direct"
                      stroke="#563AFF"
                    />
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="other"
                      stroke="#e9e9eb"
                    />
                  </>
                )}

                <Line
                  type="monotone"
                  dot={false}
                  strokeWidth={2}
                  dataKey="count"
                  stroke="#000"
                />
              </LineChart>
            )}
          </ResponsiveContainer>
        </div>
      )}
    </Panel>
  );
}
