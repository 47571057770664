import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUser } from "../helpers/utils";
import { UserProvider, useUser } from "./auth/context/user-context";

function MainApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = getUser();
  const { onboardingData } = useUser() as any;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || !user || !user.id) {
      navigate("/login", {
        state: { from: `${location.pathname}${location.search}` },
      });
      setIsLoggedIn(false);
    } else {
      if (onboardingData !== undefined) {
        setIsLoggedIn(true);
        if (onboardingData.isOnboardingCompleted) {
          if (location.pathname === "/") {
            navigate("/dashboard");
          }
        } else {
          navigate("/onboarding");
        }
      }
    }
  }, [onboardingData]);

  if (!isLoggedIn) return null;

  return (
    <div className="w-full flex flex-col lg:flex-row z-[1]">
      <Outlet />
    </div>
  );
}

export function Main() {
  return (
    <UserProvider>
      <MainApp />

      <img
        src="/images/login-shadow.svg"
        className="lg:hidden fixed bottom-0"
        alt="shadow"
      />
    </UserProvider>
  );
}
