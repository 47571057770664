import { useMemo, useState } from "react";
import { Card } from "../../../../components/Card";
import { Rating } from "../../../../components/Rating";
import { ActionButtons } from "../ActionButtons";
import { PriceInput } from "../../../../components/PriceInput";
import { useOnboardingSave } from "../../useOnboardingSave";
import { validateCompetitorSetForm5 } from "../../useValidation";
import { ImageView } from "../../../../components/Image";

function MobileMonthlyBudget({
  competitorPrices,
  setForm,
  hotel,
  type = "other",
}) {
  return (
    <div
      className={`p-3.5 rounded bg-white border  ${
        type === "self"
          ? "shadow-[0px_2px_6px_0px_rgba(20,20,43,0.06)] border-neutral-600"
          : "shadow-[0px_2px_6px_0px_rgba(20,20,43,0.06)] border-neutral-400"
      }`}
    >
      <div className="flex items-center gap-2">
        <div className="w-[26px] h-[26px] rounded">
          <ImageView
            className="w-full h-full rounded object-cover"
            src={
              type === "self"
                ? "/images/hotel.png"
                : `https://places.googleapis.com/v1/${hotel.image}/media?maxHeightPx=400&maxWidthPx=400&key=AIzaSyDE-xYVEPC8f9MVR11jl7CWO7bFRrNp2KA`
            }
          />
        </div>
        <div className="flex-1 flex flex-col gap-3">
          <span className="text-black text-[13px] font-medium leading-[13px]">
            {hotel.name}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-3.5">
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <div className="text-neutral-800 text-3xs mb-1.5 font-medium leading-[10px]">
              Price (Low)
            </div>
            <PriceInput
              value={
                type === "self"
                  ? hotel.hotelCompetitivePriceLow
                  : competitorPrices[hotel.name]?.low
              }
              onChange={(val) => {
                if (type === "self") {
                  setForm((prev) => {
                    return {
                      ...prev,
                      hotelCompetitivePriceLow: val,
                    };
                  });
                } else {
                  setForm((prev) => {
                    const newCompetitorPrices = {
                      ...prev.competitorPrices,
                      [hotel.name]: {
                        ...(prev.competitorPrices?.[hotel.name] || {}),
                        low: val,
                      },
                    };
                    return {
                      ...prev,
                      competitorPrices: newCompetitorPrices,
                    };
                  });
                }
              }}
              className={`rounded-[3px] w-full !h-5 !px-[5px] !py-1 ${
                type === "self"
                  ? "[&_input]:text-[#0fbefa] bg-[#f2fbff] [&_input]:!bg-[#f2fbff] shadow-[1px_1px_2px_0px_rgba(173,168,255,0.28)] !border !border-[#B2EAFE] justify-center items-center gap-2.5 flex"
                  : ""
              }`}
            />
          </div>
          <div className="flex-1">
            <div className="text-[#525254] text-3xs mb-2 font-medium leading-[10px]">
              Price (High)
            </div>

            <PriceInput
              value={
                type === "self"
                  ? hotel.hotelCompetitivePriceHigh
                  : competitorPrices[hotel.name]?.high
              }
              onChange={(val) => {
                setForm((prev) => {
                  if (type === "self") {
                    return {
                      ...prev,
                      hotelCompetitivePriceHigh: val,
                    };
                  } else {
                    const newCompetitorPrices = {
                      ...prev.competitorPrices,
                      [hotel.name]: {
                        ...(prev.competitorPrices?.[hotel.name] || {}),
                        high: val,
                      },
                    };
                    return {
                      ...prev,
                      competitorPrices: newCompetitorPrices,
                    };
                  }
                });
              }}
              className={`rounded-[3px] w-full !h-5 !px-[5px] !py-1 ${
                type === "self"
                  ? "[&_input]:text-[#0fbefa] bg-[#f2fbff] [&_input]:!bg-[#f2fbff] shadow-[1px_1px_2px_0px_rgba(173,168,255,0.28)] !border !border-[#B2EAFE] justify-center items-center gap-2.5 flex"
                  : ""
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function Screen5Form({ setForm, form, isReview = false }) {
  // competitorPrices
  const competitorPrices = useMemo(() => {
    return form?.competitorPrices || {};
  }, [form.competitorPrices]);

  return (
    <>
      <div className="flex flex-col [&_label]:!text-neutral-700">
        {!isReview && (
          <>
            <div className="text-xl lg:text-2xl font-semibold px-6 lg:px-8">
              Competitive set
            </div>
            <p className=" text-neutral-700 mt-2 mb-5 text-sm leading-[22px] px-6 lg:px-8">
              What would you expect each hotel to be priced at if your hotel’s
              prices were as follows?
            </p>
          </>
        )}
        <div
          className={`flex flex-col gap-3 h-[calc(100dvh-400px)] pb-2 ${
            !isReview ? "px-6 lg:px-8" : ""
          }  overflow-auto`}
        >
          <table className="hidden lg:table w-full relative">
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />
            </colgroup>
            <thead className="w-full">
              <tr className="sticky top-0 z-[1] text-[#8B8C91] bg-neutral-300 rounded [&_td]:text-2xs [&_td]:font-medium [&_td]:py-2.5 [&_td]:px-[14px]">
                <td className="rounded-l">Hotel</td>
                <td>Price (Low)</td>
                <td className="rounded-r">Price (High)</td>
              </tr>
            </thead>
            <tbody>
              <tr className="text-[13px] font-semibold [&_td]:border-b [&_td]:border-b-[#8B8C91]">
                <td>
                  <div className="flex items-center gap-2.5 p-3">
                    <div className="w-[26px] h-[26px] rounded">
                      <img
                        className="w-full h-full rounded object-cover"
                        src={"/images/hotel.png"}
                        alt="my-hotel"
                      />
                    </div>
                    <div className="flex-1 flex flex-col gap-3">
                      <span>{form.hotelName}</span>
                    </div>
                  </div>
                </td>
                <td className="max-h-[50px]">
                  <PriceInput
                    value={form?.hotelCompetitivePriceLow}
                    className="!w-[90px] [&_input]:!w-10 h-5 [&_input]:!h-4 [&_input]:mt-[2px] [&_input]:text-[#0fbefa] pt-1 pb-[5px] bg-[#f2fbff] [&_input]:!bg-[#f2fbff] rounded-[3px] shadow !border !border-[#b2eafe] justify-center items-center gap-2.5 flex"
                    onChange={(val) => {
                      setForm((prev) => {
                        return {
                          ...prev,
                          hotelCompetitivePriceLow: val,
                        };
                      });
                    }}
                  />
                  {/* <div className="w-16 ml-3.5 h-5 px-[5px] pt-1 pb-[5px] bg-[#f2fbff] rounded-[3px] shadow border border-[#b2eafe] justify-center items-center gap-2.5 flex">
                    <div className="text-right text-[#0fbefa] text-2xs font-medium leading-[11px]">
                      $100
                    </div>
                  </div> */}
                </td>
                <td>
                  <PriceInput
                    value={form?.hotelCompetitivePriceHigh}
                    className="!w-[90px] [&_input]:!w-10 h-5 [&_input]:!h-4 [&_input]:mt-[2px] [&_input]:text-[#0fbefa] pt-1 pb-[5px] bg-[#f2fbff] [&_input]:!bg-[#f2fbff] rounded-[3px] shadow !border !border-[#b2eafe] justify-center items-center gap-2.5 flex"
                    onChange={(val) => {
                      setForm((prev) => {
                        return {
                          ...prev,
                          hotelCompetitivePriceHigh: val,
                        };
                      });
                    }}
                  />
                  {/* <div className="w-16 ml-3.5 self-center h-5 px-[5px] pt-1 pb-[5px] bg-[#f2fbff] rounded-[3px] shadow border border-[#b2eafe] justify-center items-center gap-2.5 flex">
                    <div className="text-right text-[#0fbefa] text-2xs font-medium leading-[11px]">
                      $300
                    </div>
                  </div> */}
                </td>
              </tr>
              {form?.competitors?.map((hotel) => (
                <tr
                  key={hotel.name}
                  className="text-[13px] font-semibold [&_td]:border-b [&_td]:border-b-neutral-400"
                >
                  <td>
                    <div className="flex items-center gap-2.5 p-3">
                      <div className="w-[26px] h-[26px] rounded">
                        <ImageView
                          className="w-full h-full rounded object-cover"
                          src={`https://places.googleapis.com/v1/${hotel.image}/media?maxHeightPx=400&maxWidthPx=400&key=AIzaSyDE-xYVEPC8f9MVR11jl7CWO7bFRrNp2KA`}
                        />
                      </div>
                      <div className="flex-1 flex flex-col gap-3">
                        <span>{hotel.name}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <PriceInput
                      value={competitorPrices[hotel.name]?.low}
                      onChange={(val) => {
                        setForm((prev) => {
                          const newCompetitorPrices = {
                            ...prev.competitorPrices,
                            [hotel.name]: {
                              ...(prev.competitorPrices?.[hotel.name] || {}),
                              low: val,
                            },
                          };
                          return {
                            ...prev,
                            competitorPrices: newCompetitorPrices,
                          };
                        });
                      }}
                      className="!h-[28px]"
                    />
                  </td>
                  <td>
                    <PriceInput
                      value={competitorPrices[hotel.name]?.high}
                      onChange={(val) => {
                        setForm((prev) => {
                          const newCompetitorPrices = {
                            ...prev.competitorPrices,
                            [hotel.name]: {
                              ...(prev.competitorPrices?.[hotel.name] || {}),
                              high: val,
                            },
                          };
                          return {
                            ...prev,
                            competitorPrices: newCompetitorPrices,
                          };
                        });
                      }}
                      className="!h-[28px]"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex lg:hidden flex-col gap-3">
            <MobileMonthlyBudget
              hotel={{
                name: form.hotelName,
                image: "/images/hotel.png",
                hotelCompetitivePriceLow: form.hotelCompetitivePriceLow,
                hotelCompetitivePriceHigh: form.hotelCompetitivePriceHigh,
              }}
              competitorPrices={competitorPrices}
              setForm={setForm}
              type="self"
            />
            {form?.competitors?.map((hotel) => (
              <MobileMonthlyBudget
                key={hotel.name}
                hotel={hotel}
                competitorPrices={competitorPrices}
                setForm={setForm}
                type="other"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export function Screen5({ onBack, onNext, setForm, form }) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const handleNext = async () => {
    onSave(form);
  };
  const { isValid, errors } = validateCompetitorSetForm5(form);
  return (
    <Card className="lg:max-w-[670px] lg:w-[670px] !px-0 !py-6 lg:!py-8">
      <Screen5Form setForm={setForm} form={form} />
      <div className="px-6 lg:px-8">
        <ActionButtons
          onBack={onBack}
          onNext={handleNext}
          loading={saving}
          disabled={!isValid}
        />
      </div>
    </Card>
  );
}
