import clsx from "clsx";
import ShowIcon from "../assets/icons/show.svg";
import HideIcon from "../assets/icons/hide.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
export default function CustomInput({
  label,
  type = "text",
  name,
  id,
  value,
  placeholder,
  onChange,
  className,
  error,
  forgotLink,
  disabled,
  inputClassName = "",
  ...props
}: {
  label: string;
  type?: string;
  name?: string;
  id?: string;
  value: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  error?: any;
  forgotLink?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  maxLength?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}) {
  const [showPassword, setShowPassword] = useState(false);
  if (type === "password") {
    return (
      <div className={clsx("relative flex flex-col gap-1.5", className)}>
        <label
          htmlFor={id}
          className="text-[#161616] text-xs justify-between flex w-full"
        >
          {label}
          {forgotLink && (
            <Link to="/forgot-password" className="underline font-medium">
              Forgot your password?
            </Link>
          )}
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={40}
            disabled={disabled}
            {...props}
            className={`pr-10 w-full bg-white outline-neutral-600 py-2.5 px-3 placeholder:text-neutral-600 border ${
              value ? "border-neutral-600" : "border-neutral-400"
            } rounded text-sm leading-[18px] ${inputClassName}`}
          />
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <img src={!showPassword ? HideIcon : ShowIcon} alt="show" />
          </button>
        </div>
        {error && <p className="text-[#EF4444] text-[0.75rem] h-6">{error}</p>}
      </div>
    );
  }

  return (
    <div className={clsx("relative flex flex-col gap-1.5", className)}>
      {label && (
        <label htmlFor={id} className="label text-[#161616] text-xs">
          {label}
        </label>
      )}
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={40}
        disabled={disabled}
        {...props}
        className={`bg-white outline-neutral-600 py-2.5 px-3 placeholder:text-neutral-600 border ${
          value ? "border-neutral-600" : "border-neutral-400"
        } rounded text-sm leading-[18px] ${inputClassName}`}
      />

      {error && (
        <p className="text-[#EF4444] absolute -bottom-[17px] text-[0.75rem]">
          {error}
        </p>
      )}
    </div>
  );
}
