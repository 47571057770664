import { useEffect, useMemo, useState } from "react";
import { Panel } from "../../../components/Panel";
import { PriceInput } from "../../../components/PriceInput";
import { Calendar } from "../../calendar/components/Calendar";
import { MonthSelect } from "../../dashboard/components/MonthSelect";
import { YearFilter } from "../../dashboard/components/YearFilter";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { Select } from "../../../components/Select";
import moment from "moment";
import { api } from "../../../helpers/api";
import { commaSeparatedNumber, getUser } from "../../../helpers/utils";
import Spinner from "../../../components/Spinners";

export function CalendarView({
  season,
  prices,
  setPrices,
  date,
  setDate,
  pricesLoading = false,
  onClose,
}: {
  season: any;
  prices: any;
  setPrices: (prices: any) => void;
  date: string | null;
  setDate: (date: string | null) => void;
  pricesLoading: boolean;
  onClose?: () => void;
}) {
  console.log("pricesLoading", pricesLoading);
  const user = getUser();

  const [selectedYear, setSelectedYear] = useState<number | null>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(0);

  // gets months from startDate & endDate
  const months = useMemo(() => {
    if (season && season?.startDate && season?.endDate) {
      const start = moment(season.startDate);
      const end = moment(season.endDate);
      const months: any[] = [];
      const diff = end.diff(start, "months");
      for (let i = 0; i <= diff; i++) {
        months.push(`${start.month() + i + 1}`);
      }
      return months;
    }
    return [];
  }, [season]);

  const years = useMemo(() => {
    // current & next year dynamically
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    return [
      {
        label: `${currentYear}`,
        value: `${currentYear}`,
      },
      {
        label: `${nextYear}`,
        value: `${nextYear}`,
      },
    ];
  }, []);

  const handleMonthChange = (month: string) => {
    setSelectedMonth(parseInt(month));
  };

  const handleDateSelect = (date) => {
    setDate(date);
  };

  useEffect(() => {
    if (season && season?.startDate) {
      setSelectedMonth(moment(season.startDate).month() + 1);
    }
  }, [season]);

  console.log("start");

  return (
    <Panel
      label="Check by date"
      className={`h-fit lg:h-[calc(100dvh-270px)] overflow-auto tall3XL:h-fit  ${
        onClose
          ? "[&_.header]:!pl-4 shadow-[0px_1px_1px_0px_rgba(20,20,43,0.24)] border-[0.5px] border-[#B6B6BD]"
          : ""
      }`}
      actions={
        <div className="flex items-center gap-1.5">
          <MonthSelect
            disabled={false}
            value={`${selectedMonth}`}
            onChange={handleMonthChange}
          />
          <Select
            data={years}
            value={years.find((m) => m.value === `${selectedYear}`)}
            onChange={(year) => {
              setSelectedYear(Number(year.value));
            }}
            className="w-[54px] !min-h-[24px] !h-6 !py-0 [&_input]:!h-5"
            isSearchable={false}
          />
          {onClose && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              className="p-1"
            >
              <CloseIcon />
            </button>
          )}
        </div>
      }
    >
      <div className="py-4">
        <Calendar
          year={selectedYear as number}
          month={selectedMonth}
          onClick={(date) => handleDateSelect(date)}
          selectedDate={date}
        />
      </div>
      <div className="border-t border-t-neutral-300 flex gap-3.5 flex-col px-4 py-3">
        {pricesLoading ? (
          <div className="flex justify-center items-center">
            <Spinner className="!text-primary" />
          </div>
        ) : (
          <>
            <div className="flex justify-between itemscenter">
              <span className="text-2xs text-neutral-600 font-medium">
                Dynamic Price
              </span>

              <div
                className={`flex w-[90px] relative rounded px-3 py-1.5 text-2xs font-medium border-neutral-400 border !text-[#646466] justify-center items-center`}
              >
                {commaSeparatedNumber(prices?.dynamicPrice, false)}
              </div>
            </div>
            <div className="flex justify-between itemscenter">
              <span className="text-2xs text-neutral-600 font-medium">ADR</span>
              <div
                className={`flex w-[90px] relative rounded px-3 py-1.5 text-2xs font-medium border-neutral-400 border !text-[#646466] justify-center items-center`}
              >
                {commaSeparatedNumber(prices?.adr, false)}
              </div>
            </div>
            <div className="flex justify-between itemscenter">
              <span className="text-2xs text-neutral-600 font-medium">
                Revenue
              </span>
              <div
                className={`flex w-[90px] relative rounded px-3 py-1.5 text-2xs font-medium border-neutral-400 border !text-[#646466] justify-center items-center`}
              >
                {commaSeparatedNumber(prices?.totalRevenue, false)}
              </div>
            </div>
          </>
        )}
      </div>
    </Panel>
  );
}
