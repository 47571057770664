import { Logo } from "../../../components/Logo";

export function AutumnInfo() {
  return (
    <div className="max-w-[468px]">
      <Logo className="ml-8" />
      <div className="mt-9 flex-col justify-start items-start gap-7 inline-flex">
        <div className="self-stretch justify-start items-start gap-2 inline-flex">
          <div className="w-6 h-6 p-2.5 justify-center items-center gap-2.5 flex">
            <div className="w-2 h-2 bg-[#525253] rounded-full border-4 border-white/30" />
          </div>
          <div className="grow shrink basis-0">
            <span className="text-[#525253] text-base font-medium font-['Inter'] leading-normal">
              Automated Revenue Management & Marketing:
            </span>
            <span className="text-[#525253] text-base font-normal font-['Inter'] leading-normal">
              {" "}
            </span>
            <span className="text-[#646466] text-base font-normal font-['Inter'] leading-normal">
              Our advanced system was built by the industry’s best, and handles
              all revenue management and marketing, so you can focus on what
              matters most — your guests.
            </span>
          </div>
        </div>
        <div className="self-stretch justify-start items-start gap-2 inline-flex">
          <div className="w-6 h-6 p-2.5 justify-center items-center gap-2.5 flex">
            <div className="w-2 h-2 bg-[#525253] rounded-full border-4 border-white/30" />
          </div>
          <div className="grow shrink basis-0">
            <span className="text-[#525253] text-base font-medium font-['Inter'] leading-normal">
              User-Friendly Interface:
            </span>
            <span className="text-[#525253] text-base font-normal font-['Inter'] leading-normal">
              {" "}
            </span>
            <span className="text-[#646466] text-base font-normal font-['Inter'] leading-normal">
              Get started in just 10 minutes. Enjoy clean, easy-to-understand
              platform that beautifully displays your performance and how to
              improve it.
            </span>
          </div>
        </div>
        <div className="self-stretch justify-start items-start gap-2 inline-flex">
          <div className="w-6 h-6 p-2.5 justify-center items-center gap-2.5 flex">
            <div className="w-2 h-2 bg-[#525253] rounded-full border-4 border-white/30" />
          </div>
          <div className="grow shrink basis-0">
            <span className="text-[#525253] text-base font-medium font-['Inter'] leading-normal">
              Best-in-Class 24/7 Support:
            </span>
            <span className="text-[#525253] text-base font-normal font-['Inter'] leading-normal">
              {" "}
            </span>
            <span className="text-[#646466] text-base font-normal font-['Inter'] leading-normal">
              Our AI platform has been trained on the top hotel revenue
              management and marketing practices. Whether it's about your data
              or industry insights, just ask Autumn for 24/7 expert assistance.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
