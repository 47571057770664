import { useGoogleLogin } from "@react-oauth/google";
import { ReactComponent as Google } from "../assets/icons/google.svg";
import { useEffect } from "react";
import React from "react";
import axios from "axios";

export function SocialLogin({
  onGoogleLogin,
  onSuccess,
  text,
}: {
  onGoogleLogin: (response: any) => void;
  onSuccess?: (value: boolean) => void;
  text?: string;
}) {
  const [user, setUser] = React.useState<any>();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => {},
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          onGoogleLogin(res);
        })
        .catch((err) => {});
    }
  }, [user]);

  return (
    <div className="flex gap-3">
      <button
        type="button"
        className="flex-1 border rounded-[6px] !border-[#CBD5E1] !gap-2 text-[0.875rem] font-[500] !text-black flex items-center justify-center py-2 font-[Inter]"
        onClick={() => login()}
      >
        <Google className="mt-[2px]" />
        {text || "Login with Google"}
      </button>
    </div>
  );
}
