import { useRef } from "react";
import { ReactComponent as LeftChevronIcon } from "../assets/icons/left-arrow.svg";

export function Panel({
  actions,
  children,
  label,
  className = "",
  contentClassName = "",
  showHeader = true,
  showNavigation = false,
}: {
  actions?: React.ReactNode;
  children: React.ReactNode;
  label: string;
  className?: string;
  contentClassName?: string;
  showHeader?: boolean;
  showNavigation?: boolean;
}) {
  const tableRef = useRef<HTMLTableElement>(null);

  return (
    <div
      className={`rounded-[10px] relative border-[0.5px] border-neutral-400 bg-white shadow-[0px_1px_1px_0px_rgba(20,20,43,0.24)] ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      {showHeader && (
        <div className="header pl-1.5 md:pl-4 pr-2.5 py-2.5 border-b border-[#e8e8ea] justify-between items-center flex">
          <div className="text-black text-sm font-semibold">{label}</div>
          {actions}
        </div>
      )}
      <div ref={tableRef} className={`overflow-x-auto ${contentClassName}`}>
        {children}

        {showNavigation && (
          <div
            className={`flex md:hidden absolute bottom-3 left-0 right-0 items-center gap-2 justify-center`}
          >
            <button
              type="button"
              onClick={() => tableRef.current?.scrollBy(-300, 0)}
              title={"left"}
              className="w-6 h-6 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06)] flex justify-center items-center rounded border border-[#DCDDEB] bg-[#F7F7FC]"
            >
              <LeftChevronIcon className="w-4 h-4" />
            </button>
            <button
              type="button"
              onClick={() => tableRef.current?.scrollBy(300, 0)}
              title={"right"}
              className="w-6 h-6 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06)] flex justify-center items-center rounded border border-[#DCDDEB] bg-[#F7F7FC]"
            >
              <LeftChevronIcon className="w-4 h-4 rotate-180" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
