import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { setToken, setUser } from "../../../helpers/utils";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { api } from "../../../helpers/api";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import { SocialLogin } from "../../../components/SocialLogin";
import useGoogleLogin from "../../../helpers/hooks/useGoogleLogin";
import { Layout } from "../components/Layout";
import { ShowToast } from "../../../components/toast";
import { Checkbox } from "../../../components/Checkbox";
import React, { useEffect } from "react";
import { AutumnInfo } from "../components/AutumnInfo";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    // .matches(/^[^\s+@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .email("Invalid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/g,
      "Password must contain at least one uppercase and at least one lowercase letter and at least one special character"
    ),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const [stayLoggedIn, setStayLoggedIn] = React.useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("staySignedIn") === "true" &&
      localStorage.getItem("user")
    ) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Layout>
      <div className="flex w-full items-center z-[1]">
        <div className="hidden lg:flex justify-center flex-1">
          <AutumnInfo />
        </div>
        <div className="flex-1">
          <Formik
            initialValues={{ email: "", password: "" }}
            isInitialValid={false}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              api
                .login({
                  ...values,
                  userType: "U",
                })
                .then(async (res) => {
                  localStorage.setItem("token", res.data.accessToken);
                  setToken(res.data?.accessToken);
                  localStorage.setItem("staySignedIn", `${stayLoggedIn}`);
                  if (res.data?.id) {
                    const userResponse = await api.getUserProfile(res.data?.id);
                    setUser({ ...res.data, ...userResponse });
                    const onboardingResponse = await api.getOnboarding(
                      res.data?.id
                    );
                    const isOnboardingCompleted =
                      onboardingResponse?.isOnboardingCompleted;
                    actions.setSubmitting(false);
                    api.setActiveStatus(res.data?.id);

                    if (isOnboardingCompleted) {
                      navigate("/dashboard");
                    } else {
                      navigate("/onboarding");
                    }
                  } else {
                    actions.setSubmitting(false);
                  }
                })
                .catch((err) => {
                  actions.setSubmitting(false);

                  ShowToast({
                    message:
                      err.response.data.message || "Invalid email or password",
                    type: "error",
                  });
                });
            }}
          >
            <LoginFormContent
              stayLoggedIn={stayLoggedIn}
              setStayLoggedIn={setStayLoggedIn}
            />
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

const LoginFormContent = ({ stayLoggedIn, setStayLoggedIn }) => {
  const { isValid, isSubmitting } = useFormikContext();
  const { handleGoogleLogin } = useGoogleLogin();

  const googleLogin = async (response: any) => {
    handleGoogleLogin(response);
  };

  return (
    <div className="grid items-center h-full justify-center">
      <div className="grid">
        <Form className="flex lg:min-w-[372px] w-[calc(100vw-2rem)] lg:w-auto flex-col gap-6 mb-[1.5rem]">
          <h1 className="text-black text-[28px] font-bold tracking-[-1.12px] leading-[100%]">
            Sign in
          </h1>
          <div className="grid gap-4">
            <FormField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
            />
            <FormField
              label="Password"
              name="password"
              type="password"
              placeholder="Enter your password"
            />
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-2">
                <Checkbox
                  name="stay"
                  id="stay"
                  onChange={() => setStayLoggedIn((prev) => !prev)}
                  checked={stayLoggedIn}
                />
                <label
                  className="text-[#1D1E1B] text-[13px] tracking-[-0.13px] cursor-pointer"
                  onClick={() => setStayLoggedIn((prev) => !prev)}
                >
                  Stay signed in
                </label>
              </div>
              <Link
                to="/forgot-password"
                className="text-primary text-[13px] font-medium tracking-[-0.13px]"
              >
                Forgot password?
              </Link>
            </div>
          </div>
          <div className="grid gap-7 mt-2">
            <Button
              className="w-full"
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Sign in <RightArrow />
            </Button>

            <div className="relative border-b border-black w-full opacity-20">
              <div className="text-sm flex justify-center text-black font-medium -top-2.5 absolute left-0 right-0">
                <span className="bg-white w-10 text-center">or</span>
              </div>
            </div>
            <SocialLogin onGoogleLogin={googleLogin} />
          </div>
        </Form>
        <div className="text-center text-sm">
          <span>Don’t have an account?</span>{" "}
          <Link
            to="/register"
            className=" text-primary hover:underline font-medium"
          >
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
