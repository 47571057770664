import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Panel } from "../../../../components/Panel";
import moment from "moment";
import { ColorChip } from "../../../../components/ColorChip";
import { FilterSelect } from "./components/FilterSelect";
import {
  commaSeparatedNumber,
  formatNumber,
  getUser,
} from "../../../../helpers/utils";
import { api } from "../../../../helpers/api";
import Spinner from "../../../../components/Spinners";

const lastTypes = [
  { label: "1 day", value: "1_day" },
  { label: "7 days", value: "7_days" },
  { label: "30 days", value: "30_days" },
];
const nextTypes = [
  { label: "7 days", value: "7_days" },
  { label: "30 days", value: "30_days" },
  { label: "3 months", value: "90_days" },
  { label: "All time", value: "all_time" },
];

const getDates = (type: string) => {
  if (type === "7_days") {
    return {
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "30_days") {
    return {
      startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "1_day") {
    return {
      startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type,
    };
  }
  return {};
};

const getTargetDates = (type: string) => {
  if (type === "7_days") {
    return {
      targetStartDate: moment().format("YYYY-MM-DD"),
      targetEndDate: moment().add(7, "days").format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "30_days") {
    return {
      targetStartDate: moment().format("YYYY-MM-DD"),
      targetEndDate: moment().add(30, "days").format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "90_days") {
    return {
      targetStartDate: moment().format("YYYY-MM-DD"),
      targetEndDate: moment().add(90, "days").format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "all_time") {
    return {
      targetStartDate: "",
      targetEndDate: "",
      type,
    };
  }
};

const sortData = (data: any) => {
  return data.sort((a: any, b: any) => {
    return moment(a.date).diff(moment(b.date));
  });
};

const CustomCursor = (props) => {
  const { x, y, width, height, stroke } = props;
  // make width 25px
  const newWidth = 25;
  // set x in center
  const remaining = (width - (newWidth + 1)) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  // return (
  //   <div className="p-2.5 bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#f4f4f6] justify-start items-start gap-5 flex">
  //     test
  //   </div>
  // );

  return null;
};

export function PickupReportChart() {
  const [isLoading, setIsLoading] = useState(false);
  const user = getUser();
  const [data, setData] = useState<any>({});
  const [filter, setFilter] = useState<any>({
    lastType: "7_days",
    nextType: "7_days",
    ...getDates("7_days"),
    ...getTargetDates("7_days"),
  });
  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        // startDate: "2024-08-12",
        ...getDates(filter.lastType || "7_days"),
        ...getTargetDates(filter.nextType || "7_days"),
        type: "PickUp",
      };
      if (filters.startDate && filters.endDate) {
        const metrics = await api.getMetrics(userId, filters);
        setData(metrics);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const chartData = sortData(data?.pickUpReportByDates || []).map((item) => {
    if (["all_time", "90_days"].includes(filter.nextType)) {
      return {
        ...item,
        date: item.label,
      };
    }
    return item;
  });

  return (
    <Panel
      label="Pick-Up Report"
      className="h-full min-w-[230px] tall3XL:[&_.header]:h-[50px]"
      contentClassName="h-[calc(100%-50px)]"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[350px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="h-full">
          {/* <div className="px-2 py-2.5 bg-[#F6F5FF] text-center text-[#655cfe] text-3xs font-medium border-b justify-center items-center flex">
            At this rate, you are expected to hit your budget for Feb
          </div> */}
          <div className="p-3 gap-1 items-center flex flex-wrap text-2xs text-neutral-700 font-medium tracking-[-0.11px]">
            <span>In the last</span>
            <FilterSelect
              filter={filter}
              setFilter={setFilter}
              types={lastTypes}
              fieldName="lastType"
            />
            <span>you've</span>
            <span>picked</span>
            <span>up</span>
            <span>
              {commaSeparatedNumber(data?.totalRevenue || 0, false)}, for the
              next
            </span>
            <FilterSelect
              filter={filter}
              setFilter={setFilter}
              types={nextTypes}
              fieldName="nextType"
            />
          </div>
          <div className="relative min-w-[218px] h-[calc(100%-175px)] 2k:h-[calc(100%-150px)] flex justify-center items-center py-1.5">
            {data?.pickUpReportByDates?.length === 0 && (
              <div className="absolute top-0 text-neutral-700 text-sm font-medium left-0 flex justify-center text-center flex-1 items-center w-full h-full">
                No data available
              </div>
            )}
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: 15,
                  bottom: 5,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickLine={false}
                  className="!text-3xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    if (["all_time", "90_days"].includes(filter.nextType)) {
                      return moment(value, "MMMM YYYY").format("MMM YYYY");
                    }
                    if (["30_days"].includes(filter.nextType)) {
                      return moment(value).format("M/DD");
                    }
                    return moment(value).format("ddd");
                  }}
                  interval={0}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={30}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    // make value to k denotes thousands
                    const val = Number(value);
                    // If zero then show 0 without dollar sign
                    if (val === 0) {
                      return "0";
                    }
                    if (val > 1000) {
                      return `${formatNumber(Number(val || 0), "$")}`;
                    } else {
                      return `$${val}`;
                    }
                  }}
                />
                <Tooltip
                  position={{ y: 0 }}
                  content={<CustomTooltip />}
                  cursor={
                    data?.pickUpReportByDates?.length > 0 && <CustomCursor />
                  }
                />
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="rgba(204, 196, 255, 1)" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>
                <Bar
                  dataKey="sum"
                  fill="#000"
                  barSize={
                    ["all_time", "30_days"].includes(filter.nextType) ? 12 : 18
                  }
                  radius={[2, 2, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="border-t border-t-neutral-300 px-[15px] py-3 flex flex-col gap-1.5">
            <div className="flex gap-1 items-center justify-between">
              <div className="text-2xs text-neutral-600 font-medium">
                Rooms Sold:
              </div>
              <div className="flex gap-3 items-center">
                <span className="text-2xs text-neutral-600 font-medium">
                  {commaSeparatedNumber(data?.totalBookings || 0, false, false)}
                </span>
                <ColorChip
                  text={`${commaSeparatedNumber(
                    data?.totalBookingsPercentage || 0,
                    true
                  )}`}
                  type={data?.totalBookingsPercentage < 0 ? "purple" : "green"}
                  className="min-w-[40px]"
                />
              </div>
            </div>
            <div className="flex gap-1 items-center justify-between">
              <div className="text-2xs text-neutral-600 font-medium">ADR:</div>
              <div className="flex gap-3 items-center">
                <span className="text-2xs text-neutral-600 font-medium">
                  {commaSeparatedNumber(data?.adr, false)}
                </span>
                <ColorChip
                  text={`${commaSeparatedNumber(
                    data?.adrPercentage || 0,
                    true
                  )}`}
                  type={data?.adrPercentage < 0 ? "purple" : "green"}
                  className="min-w-[40px]"
                />
              </div>
            </div>
            <div className="flex gap-1 items-center justify-between">
              <div className="text-2xs text-neutral-600 font-medium">
                Revenue:
              </div>
              <div className="flex gap-3 items-center">
                <span className="text-2xs text-neutral-600 font-medium">
                  {commaSeparatedNumber(data?.totalRevenue || 0, false)}
                </span>
                <ColorChip
                  text={`${commaSeparatedNumber(
                    data?.totalRevenuePercenatge || 0,
                    true
                  )}`}
                  type={data?.totalRevenuePercenatge < 0 ? "purple" : "green"}
                  className="min-w-[40px]"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Panel>
  );
}
