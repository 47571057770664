const types = {
  red: {
    style:
      "shadow-[1px_1px_2px_0px_rgba(209,136,221,0.28)] border border-[#FFC0DF] bg-[#FFEFF7] text-[#FF4FA6]",
  },
  green: {
    style:
      "shadow-[1px_1px_2px_0px_rgba(136,221,181,0.28)] bg-green-100 border border-green-200 text-green-300",
  },
  blue: {
    style:
      "shadow-[1px_1px_2px_0px_rgba(173,168,255,0.28)] bg-light-blue-100 border border-light-blue-200 text-light-blue-300",
  },
  purple: {
    style:
      "shadow-[1px_1px_2px_0px_rgba(209,136,221,0.28)] border border-[#B7D3FF] bg-[#ECF3FF] text-[#3984FF]",
  },
  grey: {
    style:
      "shadow-[1px_1px_2px_0px_rgba(161,161,161,0.28)] border border-neutral-400 bg-neutral-200 text-neutral-700",
  },
};
export function ColorChip({ text, type, className = "", isNegative = false }) {
  const style = types[type]?.style || "";
  return (
    <div
      className={`${style} ${className} rounded-[3px] flex items-center justify-center !h-5 px-[5px] text-center text-2xs`}
    >
      {text}
    </div>
  );
}
