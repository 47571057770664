export const GOOGLE_OAUTH_CLIENT_ID =
  "555870411110-6h9g25v8494l2arl7dmqtckhd5sj2f89.apps.googleusercontent.com";

export const subscriptionList = [
  {
    tier: "ST",
    sellers: 10,
    price: 40,
    title: "Standard",
    desc: "Made for starters.",
    bg: "bg-[#D5EFFA]",
    text: "text-[#139EA7]",
  },
  {
    tier: "PR",
    sellers: 20,
    price: 80,
    title: "Pro",
    desc: "Created for growing starters.",
    bg: "bg-[#DECAFF]",
    text: "text-[#883EC2]",
  },
  {
    tier: "MX",
    sellers: 40,
    price: 135,
    title: "Max",
    desc: "The plan for everyone.",
    bg: "bg-[#FFE5D6]",
    text: "text-[#AA6413]",
  },
  {
    tier: "UL",
    sellers: 100,
    price: 235,
    title: "Ultra",
    desc: "The plan you’ve always needed.",
    bg: "!bg-slate-900",
    text: "text-gradient",
  },
];
