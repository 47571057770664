import { useEffect, useState } from "react";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { HotelForm } from "./HotelForm";
import { ConnectMews } from "./ConnectMews";

const SubSteps = {
  0: HotelForm,
  1: ConnectMews,
};

const determineStep = (form: any) => {
  if (!form) {
    return 0;
  }
  if (!form.pms || !form.hotelName || !form.hotelWebsite || !form.title) {
    return 0;
  }
  if (!form.userPms) {
    return 1;
  }
  return 1;
};

export function PMSConnect({
  onNext,
  onBack,
  step,
  form,
  setForm,
  isDetermined,
  setIsDetermined,
}: {
  onNext: () => void;
  onBack: () => void;
  step: number;
  form: any;
  setForm: (form: any) => void;
  isDetermined: boolean;
  setIsDetermined: any;
}) {
  const [subStep, setSubStep] = useState(0);
  const CurrentStep = SubSteps[subStep];

  const handleNext = () => {
    if (subStep === Object.values(SubSteps).length - 1) {
      onNext();
      return;
    } else {
      setSubStep(subStep + 1);
    }
  };

  const handleBack = () => {
    if (subStep === 0) {
      onBack();
      return;
    } else {
      setSubStep(subStep - 1);
    }
  };

  useEffect(() => {
    setIsDetermined((prev: boolean) => {
      if (!prev) {
        setSubStep(determineStep(form));
        return true;
      }
      return prev;
    });
  }, []);

  return (
    <>
      <OnboardingSidebar
        title="Connect your PMS"
        totalSteps={Object.values(SubSteps).length}
        currentStep={subStep}
      >
        <p className="text-xs leading-[16.8px] text-black">
          We need to connect to your PMS to seamlessly gather & sync real time
          information
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Connect your PMS"
        totalSteps={Object.values(SubSteps).length}
        currentStep={subStep}
        infoView={
          <p className="text-xs leading-[16.8px] text-black">
            We need to connect to your PMS to seamlessly gather & sync real time
            information
          </p>
        }
      >
        <div>
          <CurrentStep
            onNext={handleNext}
            onBack={handleBack}
            form={form}
            setForm={setForm}
          />
        </div>
      </OnboardingView>
    </>
  );
}
