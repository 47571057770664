import { Panel } from "../../../components/Panel";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";

import { ColorChip } from "../../../components/ColorChip";
import { commaSeparatedNumber } from "../../../helpers/utils";
import { Tooltip } from "../../../components/tooltip";

export function PriceExplanation({
  onClose,
  data,
  dayPrice,
}: {
  onClose: () => void;
  data: any;
  dayPrice: any;
}) {
  return (
    <Panel
      label="Price Explanation"
      actions={
        <button
          type="button"
          onClick={onClose}
          className="w-4 h-4 p-[0.22px] justify-center items-center flex"
        >
          <CloseIcon />
        </button>
      }
      className="w-[336px] pb-1.5"
    >
      <div
        className={
          "w-full h-[38px] p-3 border-b border-b-neutral-400 justify-start items-center flex"
        }
      >
        <div className="text-right text-[#646466] text-2xs font-medium leading-[11px] justify-start items-center gap-2.5 flex">
          Your Price
        </div>
        <div className="flex-1 flex justify-end items-center gap-3">
          <ColorChip
            className="w-[89px]"
            text={`${commaSeparatedNumber(dayPrice?.suggestedPrice, false)}`}
            type="red"
          />
        </div>
      </div>
      <div
        className={
          "w-full h-[38px] p-3 border-b border-b-neutral-400 justify-start items-center flex"
        }
      >
        <div className="text-right text-[#646466] text-2xs font-medium leading-[11px] justify-start items-center gap-2.5 flex">
          Competitor Avg. Price
        </div>
        <div className="flex-1 flex justify-end items-center gap-3">
          <ColorChip
            className="w-[89px]"
            text={`${commaSeparatedNumber(
              data?.competitorAvgPrice || 0,
              false
            )}`}
            type="grey"
          />
        </div>
      </div>
      <div className="mt-3 h-8 p-3 justify-start items-center gap-2.5 flex text-right text-black text-xs font-medium leading-3">
        You're at a higher price because:
      </div>
      <div
        className={
          "w-full h-8 p-3 border-b border-b-neutral-300 justify-start items-center flex"
        }
      >
        <div className="flex items-center gap-1">
          <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
            Your <span className="text-primary">occupancy</span> is trending
            higher
          </div>
          <Tooltip id="occupancy-tooltip" className="!w-[300px]">
            Your price of $223 is derived from your primary competitor [Hotel x]
            being at $X and your hotel is pacing ahead of your target occupancy
            of Y for this date. As such, we've increased your price as you're
            performing better than expected for this date at this time.
          </Tooltip>
        </div>
        <div className="flex-1 flex justify-end items-center gap-3">
          <ColorChip className="w-[89px]" text="$223" type="blue" />
        </div>
      </div>
      <div
        className={
          "w-full h-8 p-3 border-b border-b-neutral-300 justify-start items-center flex"
        }
      >
        <div className="flex items-center gap-1">
          <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
            Your <span className="text-primary">guest reviews</span> are
            increasing
          </div>
          <Tooltip id="guest-reviews-tooltip" className="!w-[300px]">
            Your price of $223 is derived from your primary competitor [Hotel x]
            being at $X and your hotel is pacing ahead of your target occupancy
            of Y for this date. As such, we've increased your price as you're
            performing better than expected for this date at this time.
          </Tooltip>
        </div>
        <div className="flex-1 flex justify-end items-center gap-3">
          <ColorChip className="w-[89px]" text="$223" type="blue" />
        </div>
      </div>
      <div
        className={
          "w-full h-8 p-3 border-b-neutral-300 justify-start items-center flex"
        }
      >
        <div className="flex items-center gap-1">
          <div className="text-right text-[#646466] text-2xs font-medium leading-[11px]">
            Your <span className="text-primary">booking pace</span> is high
          </div>
          <Tooltip id="booking-pace-tooltip" className="!w-[300px]">
            Your price of $223 is derived from your primary competitor [Hotel x]
            being at $X and your hotel is pacing ahead of your target occupancy
            of Y for this date. As such, we've increased your price as you're
            performing better than expected for this date at this time.
          </Tooltip>
        </div>
        <div className="flex-1 flex justify-end items-center gap-3">
          <ColorChip className="w-[89px]" text="$223" type="blue" />
        </div>
      </div>
    </Panel>
  );
}
