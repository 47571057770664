import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";
import { Panel } from "../../../../components/Panel";
import moment from "moment";
import { api } from "../../../../helpers/api";
import { formatNumber, getUser } from "../../../../helpers/utils";
import Spinner from "../../../../components/Spinners";

const titles = {
  expedia: "Expedia",
  booking_com: "Booking.com",
  makemytrip: "MakeMyTrip",
  tripadvisor: "Tripadvisor",
  google: "Google",
  trivago: "Trivago",
  agoda: "Agoda",
  hotels_com: "Hotels.com",
};

const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

const CustomCursor = (props) => {
  const { x, y, width, height } = props;
  // make width 25px
  const newWidth = 25;
  // set x in center
  const remaining = (width - (newWidth + 1)) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomBarTooltip = ({ active, payload, label }: any) => {
  // return (
  //   <div className="p-2.5 bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#f4f4f6] justify-start items-start gap-5 flex">
  //     test
  //   </div>
  // );

  return null;
};

export function GuestReviewChart() {
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [filter, setFilter] = useState<any>({
    ...getDates(),
    type: "adr_index",
  });

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "Trends",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
      setData(metrics);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const transformedData = data?.reviews
    ?.filter((e) =>
      ["booking_com", "expedia", "google"].includes(e?.websiteName)
    )
    ?.map((e) => ({
      name: e?.websiteName,
      sum: e?.scoreHotel,
    }));
  return (
    <Panel
      label="Guest Reviews "
      className="h-full flex flex-col"
      contentClassName="h-full"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[350px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="min-w-[218px] py-3 grow-[1] flex flex-col h-full">
          <div className="relative grow-[1] pl-2 h-[25.56vh]">
            {transformedData?.length === 0 && (
              <div className="absolute top-0 text-neutral-700 text-sm font-medium left-0 flex justify-center text-center flex-1 items-center w-full h-full">
                No data available
              </div>
            )}
            <ResponsiveContainer height="100%">
              <BarChart
                // width={500}
                // height={300}
                data={transformedData || []}
                margin={{
                  top: 5,
                  right: 20,
                  left: 5,
                  bottom: 35,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  className="!text-3xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    return titles[value] || value;
                  }}
                  interval={0}
                  // minTickGap={-200}
                  // angle={45}
                  // dx={15}
                  // dy={20}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={30}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    // make value to k denotes thousands
                    const val = Number(value);
                    // If zero then show 0 without dollar sign
                    if (val === 0) {
                      return "0";
                    }
                    if (val > 1000) {
                      return `${formatNumber(Number(val || 0), "$")}`;
                    } else {
                      return `$${val}`;
                    }
                  }}
                />
                <Tooltip
                  position={{ y: 0 }}
                  content={<CustomBarTooltip />}
                  cursor={transformedData?.length > 0 && <CustomCursor />}
                />
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="rgba(204, 196, 255, 1)" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>
                <Bar
                  dataKey="sum"
                  fill="#000"
                  barSize={18}
                  radius={[2, 2, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="flex items-centers px-[15px] pt-[18px] pb-2 gap-5 border-t border-[#F4F4F6]">
            <div className="flex items-center gap-1">
              <div className="bg-black w-2 h-2 rounded-full" />
              <span className="text-[#161938] text-2xs font-medium leading-[11px]">
                Booking.com
              </span>
            </div>
            <div className="flex items-center gap-1">
              <div className="bg-[#b7d3ff] w-2 h-2 rounded-full" />
              <span className="text-[#161938] text-2xs font-medium leading-[11px]">
                Expedia
              </span>
            </div>
            <div className="flex items-center gap-1">
              <div className="bg-[#e9e9eb] w-2 h-2 rounded-full" />
              <span className="text-[#161938] text-2xs font-medium leading-[11px]">
                Google
              </span>
            </div>
          </div>
        </div>
      )}
    </Panel>
  );
}
