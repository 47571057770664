import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import UIPopover from "../../../components/Popover";
import { DailySummary } from "./DailySummaryView";
import { Panel } from "../../../components/Panel";
import { MonthSelect } from "./MonthSelect";
import { YearFilter } from "./YearFilter";
import { useApp } from "../../../helpers/app-context";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";
import moment from "moment";
import { Calendar } from "../../calendar/components/Calendar";
import Spinner from "../../../components/Spinners";

const DayView = ({ buttonProps, date }) => {
  // onClick of day it will open popover with relative position to the day
  // create a popover component and pass the buttonProps to it
  const user = getUser();
  const userId = user?.id;
  const [selected, setSelected] = useState<Date>();
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleDayClick = async (day: Date) => {
    try {
      setIsLoading(true);
      setSelected(day);
      const dayMetrics = await api.getDayMetrics(userId, {
        type: "Summary",
        date: moment(day).format("YYYY-MM-DD"),
      });
      setData(dayMetrics);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative">
      <UIPopover
        onClose={() => setSelected(undefined)}
        trigger={
          <button
            {...buttonProps}
            className={`${buttonProps.className} ${
              selected ? "selected" : ""
            } pointer-events-none`}
            // Prevent the default click event

            // Handle the double click event and reset the selection
            //   onDoubleClick={() => setSelected(day.date)}
          />
        }
        onClick={() => handleDayClick(date)}
      >
        {(close) => (
          <DailySummary date={date} data={data} isLoading={isLoading} />
        )}
      </UIPopover>
    </div>
  );
};

export function CalendarView() {
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [calendarData, setCalendarData] = useState<any[]>([]);
  const { isChatBotOpen, sidebarExpanded } = useApp() as {
    isChatBotOpen: boolean;
    sidebarExpanded: boolean;
  };

  const [selectedMonth, setSelectedMonth] = useState<any>(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState<any>(
    new Date().getFullYear()
  );

  const handleMonthChange = (month: string) => {
    setSelectedMonth(Number(month) - 1);
  };

  const handleYearChange = (year: string) => {
    setSelectedYear(Number(year));
  };

  useEffect(() => {
    if (user?.id) {
      setIsLoading(true);
      const date = moment(
        `${selectedMonth}-${selectedYear}`,
        "MM-YYYY"
      ).format();
      console.log(date);
      const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
      api
        .getCalendarData(user.id, startDate, endDate)
        .then((res) => {
          setCalendarData([...res]);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [selectedMonth, selectedYear, user?.id]);

  console.log(calendarData);
  return (
    <Panel
      label="Calendar"
      actions={
        <div className="flex items-center gap-1.5">
          <MonthSelect
            disabled={false}
            value={`${selectedMonth}`}
            onChange={handleMonthChange}
          />
          <YearFilter
            disabled={false}
            value={`${selectedYear}`}
            onChange={handleYearChange}
          />
        </div>
      }
      className={`h-full flex [&_.header]:!pl-4 flex-col tall3XL:[&_.header]:h-[50px] ${
        isChatBotOpen || sidebarExpanded ? "tall3:pb-[5.75rem]" : ""
      }`}
      contentClassName="!h-[calc(100%-50px)]"
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="px-3 h-full pt-5 !pb-0">
          <Calendar
            year={selectedYear}
            month={selectedMonth}
            calendarData={calendarData}
          />
        </div>
      )}
    </Panel>
  );
}
