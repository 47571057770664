import { useEffect, useState } from "react";
import { ActionButtons } from "./ActionButtons";
import { Card } from "../../../components/Card";
import { OnboardingSidebar } from "./OnboardingSidebar";
import { OnboardingView } from "./OnboardingView";
import { Accordion } from "../../../components/Accordion";
import { GeneralSettingsForm } from "./GeneralSettings";
import { SeasonsAndEventsForm } from "./SeasonsAndEvents";
import { AnnualBudgetForm } from "./AnnualBudget";
import { Screen1Form } from "./CompetitorSet/Screen1";
import { Screen2Form } from "./CompetitorSet/Screen2";
import { Screen3Form } from "./CompetitorSet/Screen3";
import { Screen4Form } from "./CompetitorSet/Screen4";
import { Screen5Form } from "./CompetitorSet/Screen5";
import { validateReviewForm } from "../useValidation";
import { useOnboardingSave } from "../useOnboardingSave";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";

function ReviewGeneralSettingItem({ form, setForm, roomTypes }) {
  return (
    <Accordion title="General questions & settings" hasCheckMark={true}>
      <GeneralSettingsForm
        isReview
        form={form}
        setForm={setForm}
        roomTypes={roomTypes}
      />
    </Accordion>
  );
}

export function ReviewCompetitorSet1Item({
  form,
  setForm,
  showCheckmark = true,
}) {
  return (
    <Accordion
      title="Competitors"
      titleClassName="!text-sm"
      hasCheckMark={showCheckmark}
    >
      <Screen1Form isReview form={form} setForm={setForm} />
    </Accordion>
  );
}

export function ReviewCompetitorSet2Item({
  form,
  setForm,
  showCheckmark = true,
}) {
  return (
    <Accordion
      title="What makes your hotel stand out from these competitors?"
      hasCheckMark={showCheckmark}
      titleClassName="!text-sm"
    >
      <Screen2Form isReview form={form} setForm={setForm} />
    </Accordion>
  );
}

export function ReviewCompetitorSet3Item({
  form,
  setForm,
  showCheckmark = true,
}) {
  return (
    <Accordion
      title="How does each hotel's physical quality compare to your hotel?"
      hasCheckMark={showCheckmark}
      titleClassName="!text-sm"
    >
      <Screen3Form isReview form={form} setForm={setForm} />
    </Accordion>
  );
}

export function ReviewCompetitorSet4Item({
  form,
  setForm,
  showCheckmark = true,
}) {
  return (
    <Accordion
      title="How does each hotel’s location compare to your hotel?"
      hasCheckMark={showCheckmark}
      titleClassName="!text-sm"
    >
      <Screen4Form isReview form={form} setForm={setForm} />
    </Accordion>
  );
}
export function ReviewCompetitorSet5Item({
  form,
  setForm,
  showCheckmark = true,
}) {
  return (
    <Accordion
      title="What would you expect each hotel to be priced at if your hotel’s prices were as follows?"
      hasCheckMark={showCheckmark}
      titleClassName="!text-sm"
    >
      <Screen5Form isReview form={form} setForm={setForm} />
    </Accordion>
  );
}

function ReviewCompetitorSetItem({ form, setForm }) {
  return (
    <Accordion title="Competitor Set" hasCheckMark={true}>
      <ReviewCompetitorSet1Item form={form} setForm={setForm} />
      <ReviewCompetitorSet2Item form={form} setForm={setForm} />
      <ReviewCompetitorSet3Item form={form} setForm={setForm} />
      <ReviewCompetitorSet4Item form={form} setForm={setForm} />
      <ReviewCompetitorSet5Item form={form} setForm={setForm} />
    </Accordion>
  );
}

function ReviewSeasonAndEventsItem({ form, setForm }) {
  return (
    <Accordion title="Seasons & Events" hasCheckMark={true}>
      <SeasonsAndEventsForm isReview form={form} setForm={setForm} />
    </Accordion>
  );
}

function ReviewBudgetItem({ form, setForm }) {
  return (
    <Accordion title="Budget" hasCheckMark={true}>
      <AnnualBudgetForm isReview form={form} setForm={setForm} />
    </Accordion>
  );
}

export function ReviewForm({ onBack, onNext, setForm, form }) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const [roomTypes, setRoomTypes] = useState<any>([]);
  const user = getUser();

  useEffect(() => {
    api
      .getHotelData(user?.id)
      .then((res) => {
        setRoomTypes(
          (res?.roomTypes || []).map((roomType) => ({
            label: roomType.name,
            value: roomType.pmsId,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [user?.id]);

  const handleNext = async () => {
    onSave(form);
  };
  const { isValid } = validateReviewForm(form, roomTypes);

  return (
    <>
      <div className="flex flex-col [&_label]:!text-neutral-700">
        <div className="text-2xl font-semibold px-8">Review your settings</div>
        <div className="flex flex-col h-[calc(100dvh-400px)] pb-2 mt-6 px-8 overflow-auto">
          <ReviewGeneralSettingItem
            form={form}
            setForm={setForm}
            roomTypes={roomTypes || []}
          />
          <ReviewCompetitorSetItem form={form} setForm={setForm} />
          <ReviewSeasonAndEventsItem form={form} setForm={setForm} />
          <ReviewBudgetItem form={form} setForm={setForm} />
        </div>
      </div>
      <div className="px-8">
        <ActionButtons
          onBack={onBack}
          onNext={handleNext}
          loading={saving}
          disabled={!isValid}
        />
      </div>
    </>
  );
}

export function Review({
  onNext,
  onBack,
  step,
  form,
  setForm,
}: {
  onNext: () => void;
  onBack: () => void;
  step: number;
  form: any;
  setForm: (data: any) => void;
}) {
  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <>
      <OnboardingSidebar title="Review">
        <p className="text-xs leading-[16.8px] text-black">
          You’re nearly there!
        </p>

        <p className="text-xs leading-[16.8px] text-black mt-2">
          Please review your selections & settings to ensure things look good.
        </p>
      </OnboardingSidebar>
      <OnboardingView
        step={step}
        title="Review"
        infoView={
          <>
            <p className="text-xs leading-[16.8px] text-black">
              You’re nearly there!
            </p>

            <p className="text-xs leading-[16.8px] text-black mt-2">
              Please review your selections & settings to ensure things look
              good.
            </p>
          </>
        }
      >
        <Card className="lg:max-w-[690px] lg:w-[690px] px-0">
          <ReviewForm
            onBack={handleBack}
            onNext={handleNext}
            setForm={setForm}
            form={form}
          />
        </Card>
      </OnboardingView>
    </>
  );
}
