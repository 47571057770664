import { Logo } from "../../../components/Logo";
import useResponsive from "../../../helpers/useResponsive";

export function Layout({ children }: { children: React.ReactNode }) {
  const { isSmallDevice } = useResponsive();
  return (
    <div
      className="px-4 auth-layout flex flex-col items-center justify-center h-screen bg-white"
      style={{
        backgroundImage: !isSmallDevice
          ? "url('/images/auth-layout.png')"
          : undefined,
      }}
    >
      <div className="lg:hidden bg-white z-[2] h-[58px] fixed top-0 left-0 w-full flex items-center px-5 bg-[rgba(230,218,255,0.11)] border border-neutral-300">
        <Logo className="!text-[25px] !leading-0 !tracking-[-1.033px]" />
      </div>
      {children}
      <img
        src="/images/login-shadow.svg"
        className="fixed bottom-0"
        alt="shadow"
      />
    </div>
  );
}
