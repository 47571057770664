import { useState } from "react";
import { Card } from "../../../components/Card";
import CustomInput from "../../../components/Input";
import { Select } from "../../../components/MultiSelect";
import { ActionButtons } from "./ActionButtons";
import { useOnboardingSave } from "../useOnboardingSave";
import { validateHotelForm } from "../useValidation";

const titles = [
  { label: "Owner", value: "Owner" },
  { label: "General Manager", value: "General Manager" },
  { label: "Revenue Manager", value: "Revenue Manager" },
  { label: "Other", value: "Other" },
];

const platforms = [
  { label: "Mews", value: "MEWS" },
  { label: "Cloudbeds", value: "CLOUDBEDS" },
  // { label: "StayNTouch", value: "StayNTouch" },
  // { label: "Opera", value: "Opera" },
  { label: "Other", value: "Other" },
];

export function HotelForm({ onBack, onNext, form, setForm }) {
  const { saving, onSave } = useOnboardingSave({ onNext });
  const [error, setError] = useState<any>(null);
  const handleNext = async () => {
    if (form.pms === "Other") {
      setError(
        "Sorry, we don't support this PMS yet. Please select another PMS."
      );
    } else {
      onSave(form);
    }
  };
  const { isValid, errors } = validateHotelForm(form);
  return (
    <Card className="lg:max-w-[670px] lg:w-[670px]">
      <div className="flex flex-col gap-5 [&_label]:!text-neutral-700">
        <div className="text-2xl font-semibold">Tell us about your hotel</div>
        <CustomInput
          label="Hotel Name"
          placeholder="Enter your hotel name"
          onChange={(e) => setForm({ ...form, hotelName: e.target.value })}
          value={form.hotelName}
          maxLength={256}
        />
        <CustomInput
          label="Hotel Website"
          placeholder="Website URL"
          onChange={(e) => setForm({ ...form, hotelWebsite: e.target.value })}
          value={form.hotelWebsite}
          error={errors.validHotelWebsite}
          maxLength={256}
        />
        <div className={"flex flex-col gap-1.5"}>
          <label className="label text-[#161616] text-xs justify-between flex w-full">
            What is your title?
          </label>
          <Select
            data={titles}
            isMulti={false}
            placeholder="Select"
            onChange={(value) => setForm({ ...form, title: value.value })}
            value={titles.find((item) => item.value === form.title)}
          />
        </div>
        <div className={"flex flex-col gap-1.5"}>
          <label className="label text-[#161616] text-xs justify-between flex w-full">
            What Property Management System (PMS) do you use?
          </label>
          <Select
            data={platforms}
            isMulti={false}
            placeholder="Select"
            onChange={(value) => setForm({ ...form, pms: value.value })}
            value={platforms.find((item) => item.value === form.pms)}
          />
        </div>
        {error && <div className="bg-red-600">{error}</div>}
      </div>

      <ActionButtons
        onBack={onBack}
        onNext={handleNext}
        loading={saving}
        disabled={!isValid}
      />
    </Card>
  );
}
