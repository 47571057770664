import { Card } from "../../../../../components/Card";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/x.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ImageView } from "../../../../../components/Image";

export function HotelCard({
  onAdd,
  onRemove,
  hotel,
}: {
  hotel: any;
  onAdd?: () => void;
  onRemove?: () => void;
}) {
  return (
    <Card
      onClick={onAdd ? onAdd : undefined}
      className="flex items-center gap-2.5 !p-2.5"
    >
      <div className="w-[35px] h-[35px] rounded">
        <ImageView
          className="w-full h-full rounded object-cover"
          src={`https://places.googleapis.com/v1/${hotel.imageUrl}/media?maxHeightPx=400&maxWidthPx=400&key=AIzaSyDE-xYVEPC8f9MVR11jl7CWO7bFRrNp2KA`}
        />
      </div>
      <div className="flex-1 flex flex-col gap-[2px]">
        <span className="text-[13px] font-medium max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis">
          {hotel.name}
        </span>
        <span className="text-2xs text-neutral-600 font-medium">
          {hotel.distance} miles away
        </span>
      </div>
      {onAdd ? (
        <button>
          <PlusIcon onClick={onAdd} />
        </button>
      ) : (
        <button>
          <CloseIcon onClick={onRemove} />
        </button>
      )}
    </Card>
  );
}
