import { ReactComponent as RadioCheckedIcon } from "../assets/icons/radio-checked.svg";
import { ReactComponent as RadioIcon } from "../assets/icons/radio.svg";

export function Radio({ checked, onChange, label }) {
  return (
    <button onClick={onChange} className="flex items-center gap-1.5">
      {checked ? <RadioCheckedIcon /> : <RadioIcon />}
      {label && (
        <span className="text-neutral-800 font-medium text-xs ">{label}</span>
      )}
    </button>
  );
}
