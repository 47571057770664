import { Logo } from "./Logo";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import { ReactComponent as MarketingIcon } from "../assets/icons/marketing.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/setting.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";
import { Link, useLocation } from "react-router-dom";
import { getUser } from "../helpers/utils";

export function MenuItem({
  Icon,
  title,
  isActive,
  sidebarExpanded = false,
  to,
  disabled = false,
  onClick,
}: {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  isActive: boolean;
  sidebarExpanded?: boolean;
  to: string;
  disabled?: boolean;
  onClick?: () => void;
}) {
  if (disabled) {
    return (
      <div className="self-stretch p-3 rounded justify-start items-center gap-[7px] flex">
        <Icon className={"w-4 h-4 [&_path]:!stroke-[#8A8B91]"} />
        {sidebarExpanded && (
          <div className="text-center text-[#8A8B91] text-sm font-medium leading-[14px]">
            {title}
          </div>
        )}
      </div>
    );
  }
  return (
    <Link
      to={to}
      className={`self-stretch p-3 hover:bg-[#eeedff]/70 ${
        isActive ? "!bg-[#eeedff]" : ""
      } rounded justify-start items-center gap-[7px] flex`}
      onClick={onClick}
    >
      <Icon
        className={`w-4 h-4 ${
          isActive ? "[&_path]:!stroke-black" : "[&_path]:!stroke-[#8A8B91]"
        }`}
      />
      {sidebarExpanded && (
        <div
          className={`text-center ${
            isActive ? "text-black" : "text-[#8A8B91]"
          } text-sm font-medium leading-[14px]`}
        >
          {title}
        </div>
      )}
    </Link>
  );
}

export function Sidebar({
  sidebarExpanded,
  setSidebarExpanded,
  isMobile = false,
  onClose,
}: {
  sidebarExpanded: boolean;
  setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
  onClose?: () => void;
}) {
  const user = getUser();
  const location = useLocation();
  const path = location.pathname;

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
    // api.deleteOnboarding(user.id).then(() => {
    // });
  };

  return (
    <div
      className={`${
        sidebarExpanded
          ? isMobile
            ? "w-full"
            : "min-w-[214px] w-[214px]"
          : "w-[72px]"
      } h-[100dvh] bg-white shadow-[3px_10px_8px_0px_rgba(134,133,141,0.12)] flex-col justify-start items-center flex`}
    >
      <div
        className={`pl-4 w-full pr-3 py-4 border-b border-[#f4f4f6] ${
          sidebarExpanded ? "justify-between" : "justify-center"
        } items-center flex`}
      >
        {sidebarExpanded && (
          <Logo className="!text-[28px] !tracking-[-1.13px] !leading-[28.26px]" />
        )}
        <button
          type="button"
          onClick={() =>
            isMobile ? onClose?.() : setSidebarExpanded((prev) => !prev)
          }
          className="opacity-70 p-[3px] bg-[#f7f7fc] rounded shadow-[0px_2px_15px_0px_rgba(33,31,84,0.06)] border border-[#DCDDEB] justify-center items-center gap-2.5 flex"
        >
          {isMobile ? (
            <CloseIcon />
          ) : (
            <ArrowDownIcon
              className={`w-4 h-4 ${
                sidebarExpanded ? "rotate-90" : "-rotate-90"
              }`}
            />
          )}
        </button>
      </div>
      <div className="w-full flex flex-col justify-between h-[calc(100dvh-62px)]">
        <div className="px-4 pt-4 pb-5 flex-col justify-start items-start flex">
          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <MenuItem
              Icon={DashboardIcon}
              title="Dashboard"
              isActive={path === "/dashboard"}
              sidebarExpanded={sidebarExpanded}
              to="/dashboard"
              onClick={onClose}
            />
            <MenuItem
              Icon={CalendarIcon}
              title="Calendar view"
              isActive={path === "/calendar"}
              sidebarExpanded={sidebarExpanded}
              to="/calendar"
              onClick={onClose}
            />
            <MenuItem
              Icon={MarketingIcon}
              title="Marketing"
              isActive={path === "/marketing"}
              sidebarExpanded={sidebarExpanded}
              to="/marketing"
              onClick={onClose}
            />
            <MenuItem
              Icon={SettingIcon}
              title="Settings"
              isActive={path === "/settings"}
              sidebarExpanded={sidebarExpanded}
              to="/settings"
              onClick={onClose}
            />
          </div>
        </div>
        <div
          className={`self-stretch px-4 py-5 ${
            sidebarExpanded ? "justify-between" : "justify-center"
          } items-center gap-[15px] flex`}
        >
          <div className={"h-6 justify-start items-center gap-2.5 flex"}>
            <UserIcon className="w-6 h-6" />
            {sidebarExpanded && (
              <div className="flex-col justify-start items-start gap-1.5 flex">
                <div className="text-black text-base font-semibold">
                  {user?.firstName}
                </div>
              </div>
            )}
          </div>
          {sidebarExpanded && (
            <button
              type="button"
              className="hover:opacity-70"
              onClick={handleLogout}
            >
              <LogoutIcon className="w-4 h-4" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
