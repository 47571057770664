import { Accordion } from "../../../components/Accordion";
import TextArea from "../../../components/Textarea";

export function CompetitorQuestion1({ form, setForm, showCheckmark = true }) {
  return (
    <Accordion
      title="What makes your hotel stand out from these competitors?"
      hasCheckMark={showCheckmark}
      titleClassName="!text-sm"
    >
      <TextArea
        label=""
        placeholder="Feel free to write in natural language, our AI can understand you"
        onChange={(e) => setForm((prev) => ({ ...prev, usp: e.target.value }))}
        value={form.usp}
        rows={5}
      />
    </Accordion>
  );
}

export function CompetitorQuestion2({ form, setForm, showCheckmark = true }) {
  return (
    <Accordion
      title="What amenities or offerings do you have that guests really value?"
      hasCheckMark={showCheckmark}
      titleClassName="!text-sm"
    >
      <TextArea
        label=""
        placeholder="Feel free to write in natural language, our AI can understand you"
        onChange={(e) =>
          setForm((prev) => ({ ...prev, amenities: e.target.value }))
        }
        value={form.amenities}
        rows={5}
      />
    </Accordion>
  );
}
