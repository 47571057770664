import Spinner from "./Spinners";

export default function CustomButton({
  children,
  onClick,
  className,
  disabled,
  type = "button",
  loading,
  variant = "default",
  size = "lg",
  ...props
}: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  variant?: "default" | "secondary" | "link" | "ghost" | "outline";
  size?: "default" | "sm" | "lg" | "icon";
}) {
  return (
    <button
      className={`h-10 transition duration-75 rounded-[4px] text-sm font-medium leading-[20px] py-2.5 px-4 flex gap-1.5 items-center justify-center
    ${
      variant === "outline"
        ? "border border-[#B6B6BD] !text-neutralBlack shadow-[0px_4px_10px_0px_rgba(74,58,255,0.06)]"
        : ""
    }
    ${variant === "default" ? "bg-neutralBlack" : ""}
    ${
      variant === "link"
        ? `!bg-transparent text-primary ${
            !disabled ? "hover:!text-primary" : "opacity-50"
          } !p-0`
        : "text-white"
    }
    ${
      disabled || loading
        ? "cursor-not-allowed bg-neutral-500"
        : "hover:opacity-70"
    }
    ${className || ""}
  `}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      {...props}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
}
