import { ReactComponent as CheckIcon } from "../../../assets/icons/check-mark.svg";

export function PlanCard({
  isRecommended = false,
  features,
  description,
  price,
  planName,
  planStyle,
  onSelect,
  subscription,
  type,
}) {
  return (
    <div className="flex-col justify-start items-end flex">
      {isRecommended && (
        <div className="self-stretch px-3 py-2.5 bg-[#ECEAFF] rounded-tl-[10px] rounded-tr-[10px] border-2 border-[#CBC8FF] justify-center items-center gap-2.5 flex text-right text-primary text-sm font-medium leading-[14px]">
          Recommended for you
        </div>
      )}
      <div
        className={`self-stretch p-5 bg-white ${
          isRecommended ? "rounded-b-[10px]" : "rounded-[10px]"
        } border border-[#e8e8ea] flex-col justify-start items-start gap-5 flex`}
      >
        <div className="self-stretch flex-col justify-start items-start gap-[15px] flex">
          <div className="flex-col justify-start items-start gap-5 flex">
            <div
              className={`w-fit px-2 py-1.5 rounded border justify-center items-center gap-2.5 flex text-right  text-sm font-medium leading-[14px] ${planStyle}`}
            >
              {planName}
            </div>
            <div className="self-stretch justify-start items-end gap-1 flex">
              <div className="text-black text-2xl font-extrabold leading-none">
                ${price}
              </div>
              <div className="text-right text-[#8b8b91] text-sm font-medium">
                /month
              </div>
            </div>
          </div>
          <div className="self-stretch text-[#8b8b91] text-[13px] font-medium  leading-[20.80px]">
            {description}
          </div>
          <div className="self-stretch h-[0px] border border-[#e8e8ea]"></div>
          <div className="self-stretch h-[116px] flex-col justify-start items-start gap-3 flex">
            {features?.map((feature, i) => (
              <div
                key={i}
                className="self-stretch justify-start items-start gap-1.5 flex"
              >
                <div className="w-[18.33px] h-[18.33px] p-[8.33px] bg-[#ecf3ff] rounded-full flex-col justify-center items-center gap-[8.33px] flex">
                  <CheckIcon className="min-w-2.5 min-h-2.5 [&_path]:stroke-primary" />
                </div>
                <div className="grow shrink basis-0 text-[#525253] text-sm font-normal  leading-[19px]">
                  {feature}
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={onSelect}
          disabled={!!subscription && subscription?.tier === type}
          className={`text-black text-sm font-medium  leading-tight self-stretch px-4 py-2.5 ${
            !!subscription && subscription?.tier === type
              ? "bg-primary text-white"
              : "bg-white"
          } rounded shadow border border-[#b5b6bd] hover:border-black justify-center items-center gap-2 inline-flex`}
        >
          {!!subscription && subscription?.tier === type && (
            <CheckIcon className="w-[18.33px] h-[18.33px] [&_path]:stroke-white" />
          )}
          {!!subscription && subscription?.tier === type
            ? "Selected"
            : "Select"}
        </button>
      </div>
    </div>
  );
}
