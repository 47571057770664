import { useState } from "react";
import { getUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import { ShowToast } from "../../components/toast";
import { useUser } from "../auth/context/user-context";

export function useOnboardingSave({ onNext }) {
  const [saving, setSaving] = useState(false);
  const user = getUser();
  const { fetchOnboardingData } = useUser() as any;

  const onSave = async (form) => {
    try {
      // save the form data
      const userId = user.id;
      setSaving(true);
      const payload = {
        ...form,
        userPms: undefined,
      };
      delete payload.userPms;
      const response = await api.saveOnboarding(userId, payload);
      setSaving(false);
      if ([200, 201].includes(response.status)) {
        onNext();
        fetchOnboardingData();
      }
    } catch (error: any) {
      setSaving(false);
      ShowToast({
        type: "error",
        message:
          error?.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };
  return {
    saving,
    onSave,
  };
}
