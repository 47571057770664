import React, { useState } from "react";

const CustomCheckbox = ({ checked, onChange, id }) => {
  const toggleCheckbox = (e) => {
    e.stopPropagation();
    console.log("checked", id);
    onChange(!checked);
  };

  return (
    <div className="flex lg:justify-center items-center">
      <input type="checkbox" checked={checked} className="hidden" id={id} />
      <label
        htmlFor={id}
        onClick={toggleCheckbox}
        className="flex relative rounded px-3  py-1.5 text-2xs font-medium border-neutral-400 border items-center justify-center w-full lg:w-[66px] !h-6 !text-black"
      >
        {checked ? (
          <span className="text-black text-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
            >
              <g clip-path="url(#clip0_6890_18197)">
                <path
                  d="M2.35547 7.3125L5.41797 10.1562L12.418 3.65625"
                  stroke="black"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6890_18197">
                  <rect
                    width="14"
                    height="13"
                    fill="white"
                    transform="translate(0.167969)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
        ) : (
          <span className="text-black text-base">-</span>
        )}
      </label>
    </div>
  );
};

export default CustomCheckbox;
